import { get } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const GET_LIST_SITE_REQUEST = 'GET_LIST_SITE_REQUEST'
export const GET_LIST_SITE_SUCCESS = 'GET_LIST_SITE_SUCCESS'
export const GET_LIST_SITE_FAILURE = 'GET_LIST_SITE_FAILURE'
export const GET_LIST_SITE_IDLE = 'GET_LIST_SITE_IDLE'

const getListSiteRequest = () => {
  return {
    type: GET_LIST_SITE_REQUEST
  }
}

const getListSiteSuccess = (listProject) => {
  return {
    type: GET_LIST_SITE_SUCCESS,
    payload: {
      listProject
    }
  }
}

const getListSiteFailure = (err) => {
  return {
    type: GET_LIST_SITE_FAILURE,
    payload: {
      error: err
    }
  }
}

const getListSiteIdle = () => {
  return {
    type: GET_LIST_SITE_IDLE
  }
}

export const getListSite = () => {
  return async (dispatch) => {
    dispatch(getListSiteRequest())
    try {
      const response = await get({
        url: `/${API_VERSION}/projects`
      })
      dispatch(getListSiteSuccess(response))
    } catch (err) {
      dispatch(getListSiteFailure(err))
    } finally {
      dispatch(getListSiteIdle())
    }
  }
}
