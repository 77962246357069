import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import browserHistory from './history'
import ApplicationLayout from '../containers/ApplicationLayout/Loadable'
import LoginPage from '../containers/LoginPage/Loadable'
import PageNotFoundPage from '../containers/PageNotFoundPage/Loadable'
import SetPasswordPage from '../containers/SetPasswordPage/Loadable'
import { useAuth0 } from '@auth0/auth0-react'
import { FRONTEND_ROUTES } from '../utils'
const handleRouteChanged = (arg) => {
  window.scrollTo(0, 0)
}

browserHistory.listen((arg) => {
  handleRouteChanged(arg)
})

const Routes = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route path="/home" component={ApplicationLayout} />
        <Route path="/callback" component={ApplicationLayout} />
        <Route exact path="/login" component={LoginPage} />
        <Route
          path="/login/cpffarmsolution"
          render={() => {
            if (isAuthenticated) {
              return <Redirect to="/home" />
            } else {
              loginWithRedirect({ appState: { returnTo: FRONTEND_ROUTES.loginRoute } })
            }
          }}
        />
        <Route path="/set-password/:tokenKey" component={SetPasswordPage} />
        <Route component={PageNotFoundPage} />
      </Switch>
    </Router>
  )
}

export default Routes
