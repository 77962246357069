/* eslint-disable no-unused-vars */
import { get, patch } from '../../utils'

const API_VERSION = process.env.API_VERSION

export const GET_DAILY_NOTIFICATION_REQUEST = 'GET_DAILY_NOTIFICATION_REQUEST'
export const GET_DAILY_NOTIFICATION_SUCCESS = 'GET_DAILY_NOTIFICATION_SUCCESS'
export const GET_DAILY_NOTIFICATION_FAILURE = 'GET_DAILY_NOTIFICATION_FAILURE'
export const GET_DAILY_NOTIFICATION_IDLE = 'GET_DAILY_NOTIFICATION_IDLE'

const getListNotificationRequest = () => {
  return {
    type: GET_DAILY_NOTIFICATION_REQUEST
  }
}

const getListNotificationSuccess = (listNotification) => {
  return {
    type: GET_DAILY_NOTIFICATION_SUCCESS,
    payload: {
      listNotification
    }
  }
}

const getListNotificationFailure = (error) => {
  return {
    type: GET_DAILY_NOTIFICATION_FAILURE,
    payload: {
      error: error.detail
    }
  }
}

const getListNotificationIdle = () => {
  return {
    type: GET_DAILY_NOTIFICATION_IDLE
  }
}

export const getListNotification = (siteId) => {
  return async (dispatch) => {
    dispatch(getListNotificationRequest())
    try {
      const response = await get({
        url: `/${API_VERSION}/notifications/sites/${siteId}/daily`
      })
      dispatch(getListNotificationSuccess(response))
    } catch (err) {
      dispatch(getListNotificationFailure(err))
    } finally {
      dispatch(getListNotificationIdle())
    }
  }
}

export const GET_NEW_NOTIFICATION_IDLE = 'GET_NEW_NOTIFICATION_IDLE'
export const getNewNotificationSocketIdle = () => {
  return {
    type: GET_NEW_NOTIFICATION_IDLE
  }
}

export const expandSidebartoHideBadge = () => {
  return (dispatch) => {
    dispatch(getNewNotificationSocketIdle())
  }
}

export const UPDATE_NEW_NOTIFICATION_SOCKET = 'UPDATE_NEW_NOTIFICATION_SOCKET'
export const updateNewNotificationSocket = (notificationObj) => {
  return {
    type: UPDATE_NEW_NOTIFICATION_SOCKET,
    payload: {
      notificationObj
    }
  }
}

export const UPDATE_CAMERA_SOCKET = 'UPDATE_CAMERA_SOCKET'
export const updateCameraSocket = (upComingCameraData) => {
  return {
    type: UPDATE_CAMERA_SOCKET,
    payload: {
      upComingCameraData
    }
  }
}

export const READ_NOTIFICATION_REQUEST = 'READ_NOTIFICATION_REQUEST'
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS'
export const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE'
export const READ_NOTIFICATION_IDLE = 'READ_NOTIFICATION_IDLE'

const readNotificationRequest = () => {
  return {
    type: READ_NOTIFICATION_REQUEST
  }
}

const readNotificationSuccess = (notificationId) => {
  return {
    type: READ_NOTIFICATION_SUCCESS,
    payload: {
      notificationId
    }
  }
}

const readNotificationFailure = (error) => {
  return {
    type: READ_NOTIFICATION_FAILURE,
    payload: {
      error
    }
  }
}

const readNotificationIdle = () => {
  return {
    type: READ_NOTIFICATION_IDLE
  }
}

export const readNotification = (notificationId) => {
  return async (dispatch) => {
    dispatch(readNotificationRequest())
    try {
      const response = await patch({
        url: `/${API_VERSION}/notifications/${notificationId}`
      })
      dispatch(readNotificationSuccess(notificationId))
    } catch (err) {
      dispatch(readNotificationFailure(err))
    } finally {
      dispatch(readNotificationIdle())
    }
  }
}
