import { get, post, patch, storage, del } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_IDLE = 'LOGIN_IDLE'

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  }
}

export const loginSuccess = (userData) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      userData
    }
  }
}

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: {
      error
    }
  }
}

export const loginIdle = () => {
  return {
    type: LOGIN_IDLE
  }
}

export const login = (data) => {
  return async (dispatch) => {
    dispatch(loginRequest())
    try {
      const response = await post({
        url: `/${API_VERSION}/login`,
        payload: data
      })
      dispatch(loginSuccess(response))
      storage.set('currentUserToken', response.token)
    } catch (err) {
      dispatch(loginFailure(err))
    } finally {
      dispatch(loginIdle())
    }
  }
}

export const isLoggedIn = () => {
  return async (dispatch) => {
    dispatch(loginRequest())
    try {
      const currentUser = await get({
        url: `/${API_VERSION}/users`
      })
      dispatch(loginSuccess(currentUser))
      storage.set('currentUserToken', currentUser.token)
    } catch (err) {
      dispatch(loginFailure(err))
    } finally {
      dispatch(loginIdle())
    }
  }
}

export const FETCH_USER_SSO_REQUEST = 'FETCH_USER_SSO_REQUEST'
export const FETCH_USER_SSO_SUCCESS = 'FETCH_USER_SSO_SUCCESS'
export const FETCH_USER_SSO_FAILURE = 'FETCH_USER_SSO_FAILURE'
export const FETCH_USER_SSO_IDLE = 'FETCH_USER_SSO_IDLE'

export const fetchUserSSORequest = () => {
  return {
    type: FETCH_USER_SSO_REQUEST
  }
}

export const fetchUserSSOSuccess = (userData) => {
  return {
    type: FETCH_USER_SSO_SUCCESS,
    payload: {
      userData
    }
  }
}

export const fetchUserSSOFailure = (error) => {
  return {
    type: FETCH_USER_SSO_FAILURE,
    payload: {
      error
    }
  }
}

export const fetchUserSSOIdle = () => {
  return {
    type: FETCH_USER_SSO_IDLE
  }
}

export const fetchUserSSO = (access_token, id_token, ttl) => {
  return async (dispatch) => {
    dispatch(fetchUserSSORequest())
    try {
      const currentUser = await post({
        url: `/${process.env.API_VERSION}/users/fetch/`,
        payload: { access_token, id_token, ttl }
      })
      dispatch(fetchUserSSOSuccess(currentUser))
      storage.set('currentUserToken', currentUser.token)
    } catch (err) {
      dispatch(fetchUserSSOFailure(err))
    } finally {
      dispatch(fetchUserSSOIdle())
    }
  }
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const LOGOUT_IDLE = 'LOGOUT_IDLE'

const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  }
}

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  }
}

const logoutFailure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    payload: {
      error
    }
  }
}

const logoutIdle = () => {
  return {
    type: LOGOUT_IDLE
  }
}

export const logout = () => {
  return async (dispatch) => {
    dispatch(logoutRequest())
    try {
      await del({
        url: `/${API_VERSION}/logout`
      })
      dispatch(logoutSuccess())
    } catch (err) {
      dispatch(logoutFailure(err))
    } finally {
      dispatch(logoutIdle())
    }
  }
}

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'
export const EDIT_USER_IDLE = 'EDIT_USER_IDLE'

export const editUserRequest = () => {
  return {
    type: EDIT_USER_REQUEST
  }
}

export const editUserSuccess = (userData) => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: {
      userData
    }
  }
}

export const editUserFailure = (error) => {
  return {
    type: EDIT_USER_FAILURE,
    payload: {
      error
    }
  }
}

export const editUserIdle = () => {
  return {
    type: EDIT_USER_IDLE
  }
}

export const editUser = (userData) => {
  return async (dispatch) => {
    dispatch(editUserRequest())
    try {
      const updatedUserData = await patch({
        url: `/${API_VERSION}/users`,
        payload: userData
      })
      dispatch(editUserSuccess(updatedUserData))
    } catch (err) {
      dispatch(editUserFailure(err))
    } finally {
      dispatch(editUserIdle())
    }
  }
}
