import queryString from 'query-string'
import { get, ABORT_ERROR_NAME } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const SYNC_DASHBOARD_REQUEST = 'SYNC_DASHBOARD_REQUEST'
export const SYNC_DASHBOARD_SUCCESS = 'SYNC_DASHBOARD_SUCCESS'
export const SYNC_DASHBOARD_FAILURE = 'SYNC_DASHBOARD_FAILURE'
export const SYNC_DASHBOARD_IDLE = 'SYNC_DASHBOARD_IDLE'

const syncDashboardRequest = () => {
  return {
    type: SYNC_DASHBOARD_REQUEST
  }
}

const syncDashboardSuccess = (siteDetail, siteSummaryData, areaDetailData) => {
  return {
    type: SYNC_DASHBOARD_SUCCESS,
    payload: {
      siteDetail,
      siteSummaryData,
      areaDetailData
    }
  }
}

const syncDashboardError = (error) => {
  return {
    type: SYNC_DASHBOARD_FAILURE,
    payload: {
      error
    }
  }
}

const syncDashboardIdle = (error) => {
  return {
    type: SYNC_DASHBOARD_IDLE
  }
}

export const syncDashboard = (day, type, siteId) => {
  const query = queryString.stringify({
    day,
    type
  })
  return async (dispatch) => {
    dispatch(syncDashboardRequest())
    try {
      const siteDetail = await get({
        url: `/${API_VERSION}/sites/${siteId}`
      })
      const siteSummaryResponse = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}?${query}`
      })
      const areaDetailResponse = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}/areas?${query}`
      })
      dispatch(syncDashboardSuccess(siteDetail, siteSummaryResponse, areaDetailResponse))
    } catch (err) {
      dispatch(syncDashboardError(err))
    } finally {
      dispatch(syncDashboardIdle())
    }
  }
}

export const LOAD_DASHBOARD_REQUEST = 'LOAD_DASHBOARD_REQUEST'
export const LOAD_DASHBOARD_SUCCESS = 'LOAD_DASHBOARD_SUCCESS'
export const LOAD_DASHBOARD_FAILURE = 'LOAD_DASHBOARD_FAILURE'
export const LOAD_DASHBOARD_IDLE = 'LOAD_DASHBOARD_IDLE'

const loadDashboardRequest = () => {
  return {
    type: LOAD_DASHBOARD_REQUEST
  }
}

const loadDashboardSuccess = (siteDetail, siteSummaryData, areaDetailData) => {
  return {
    type: LOAD_DASHBOARD_SUCCESS,
    payload: {
      siteDetail,
      siteSummaryData,
      areaDetailData
    }
  }
}

const loadDashboardError = (error) => {
  return {
    type: LOAD_DASHBOARD_FAILURE,
    payload: {
      error
    }
  }
}

const loadDashboardIdle = (error) => {
  return {
    type: LOAD_DASHBOARD_IDLE
  }
}

export const loadDashboard = (day, type, siteId, signal) => {
  const query = queryString.stringify({
    day,
    type
  })
  return async (dispatch) => {
    dispatch(loadDashboardRequest())
    try {
      const siteDetail = await get({
        url: `/${API_VERSION}/sites/${siteId}`,
        signal
      })
      const siteSummaryResponse = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}?${query}`,
        signal
      })
      const areaDetailResponse = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}/areas?${query}`,
        signal
      })
      dispatch(loadDashboardSuccess(siteDetail, siteSummaryResponse, areaDetailResponse))
      dispatch(loadDashboardIdle())
    } catch (err) {
      if (err.name === ABORT_ERROR_NAME) {
        dispatch(loadDashboardRequest())
      } else {
        dispatch(loadDashboardError(err))
        dispatch(loadDashboardIdle())
      }
    }
  }
}
