import { get } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const LOAD_CAMERA_LIST_REQUEST = 'LOAD_CAMERA_LIST_REQUEST'
export const LOAD_CAMERA_LIST_SUCCESS = 'LOAD_CAMERA_LIST_SUCCESS'
export const LOAD_CAMERA_LIST_FAILURE = 'LOAD_CAMERA_LIST_FAILURE'
export const LOAD_CAMERA_LIST_IDLE = 'LOAD_CAMERA_LIST_IDLE'

const loadCameraListRequest = () => {
  return {
    type: LOAD_CAMERA_LIST_REQUEST
  }
}

const loadCameraListSuccess = (cameraList) => {
  return {
    type: LOAD_CAMERA_LIST_SUCCESS,
    payload: {
      cameraList
    }
  }
}

const loadCameraListFailure = (error) => {
  return {
    type: LOAD_CAMERA_LIST_FAILURE,
    payload: {
      error
    }
  }
}

const loadCameraListIdle = () => {
  return {
    type: LOAD_CAMERA_LIST_IDLE
  }
}

export const loadCameraList = (siteId) => {
  return async (dispatch) => {
    dispatch(loadCameraListRequest())
    try {
      const cameraListResponse = await get({
        url: `/${API_VERSION}/sites/${siteId}/cameras`
      })
      dispatch(loadCameraListSuccess(cameraListResponse))
    } catch (err) {
      dispatch(loadCameraListFailure(err))
    } finally {
      dispatch(loadCameraListIdle())
    }
  }
}

export const SYNC_CAMERA_LIST_REQUEST = 'SYNC_CAMERA_LIST_REQUEST'
export const SYNC_CAMERA_LIST_SUCCESS = 'SYNC_CAMERA_LIST_SUCCESS'
export const SYNC_CAMERA_LIST_FAILURE = 'SYNC_CAMERA_LIST_FAILURE'
export const SYNC_CAMERA_LIST_IDLE = 'SYNC_CAMERA_LIST_IDLE'

const syncCameraListRequest = () => {
  return {
    type: SYNC_CAMERA_LIST_REQUEST
  }
}

const syncCameraListSuccess = (cameraList) => {
  return {
    type: SYNC_CAMERA_LIST_SUCCESS,
    payload: {
      cameraList
    }
  }
}

const syncCameraListFailure = (error) => {
  return {
    type: SYNC_CAMERA_LIST_FAILURE,
    payload: {
      error
    }
  }
}

const syncCameraListIdle = () => {
  return {
    type: SYNC_CAMERA_LIST_IDLE
  }
}

export const syncCameraList = (siteId) => {
  return async (dispatch) => {
    dispatch(syncCameraListRequest())
    try {
      const cameraListResponse = await get({
        url: `/${API_VERSION}/sites/${siteId}/cameras`
      })
      dispatch(syncCameraListSuccess(cameraListResponse))
    } catch (err) {
      dispatch(syncCameraListFailure(err))
    } finally {
      dispatch(syncCameraListIdle())
    }
  }
}
