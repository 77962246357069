import styled from 'styled-components'

export default styled.div`
  .src-zone-wrapper {
    display: flex;
    flex-direction: column;
    width: 610px;
    height: 500px;
  }
  .back-btn-wrapper {
    height: 29px;
    margin-bottom: 20px;
    .text-style {
      width: 80px;
      font-size: 18px;
      font-weight: bold;
      color: ${(props) => props.theme.greenFont};
      &:hover {
        cursor: pointer;
      }
    }
  }
  .rule-warn-wrapper {
    margin-top: 30px;
    display: flex;
  }
`
