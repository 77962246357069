import { get, post, del, patch } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const LOAD_LIST_API_KEY_REQUEST = 'LOAD_LIST_API_KEY_REQUEST'
export const LOAD_LIST_API_KEY_SUCCESS = 'LOAD_LIST_API_KEY_SUCCESS'
export const LOAD_LIST_API_KEY_FAILURE = 'LOAD_LIST_API_KEY_FAILURE'
export const LOAD_LIST_API_KEY_IDLE = 'LOAD_LIST_API_KEY_IDLE'

const loadListAPIKeyRequest = () => {
  return {
    type: LOAD_LIST_API_KEY_REQUEST
  }
}

const loadListAPIKeySuccess = (listAPIKey) => {
  return {
    type: LOAD_LIST_API_KEY_SUCCESS,
    payload: {
      listAPIKey
    }
  }
}

const loadListAPIKeyFailure = (error) => {
  return {
    type: LOAD_LIST_API_KEY_FAILURE,
    payload: {
      error
    }
  }
}

const loadListAPIKeyIdle = () => {
  return {
    type: LOAD_LIST_API_KEY_IDLE
  }
}

export const loadListAPIKey = (projectID) => {
  return async (dispatch) => {
    dispatch(loadListAPIKeyRequest())
    try {
      const response = await get({
        url: `/${API_VERSION}/apikeys`
      })
      dispatch(loadListAPIKeySuccess(response))
    } catch (err) {
      dispatch(loadListAPIKeyFailure(err))
    } finally {
      dispatch(loadListAPIKeyIdle())
    }
  }
}

export const CREATE_NEW_API_KEY_REQUEST = 'CREATE_NEW_API_KEY_REQUEST'
export const CREATE_NEW_API_KEY_SUCCESS = 'CREATE_NEW_API_KEY_SUCCESS'
export const CREATE_NEW_API_KEY_FAILURE = 'CREATE_NEW_API_KEY_FAILURE'
export const CREATE_NEW_API_KEY_IDLE = 'CREATE_NEW_API_KEY_IDLE'

const createNewAPIKeyRequest = () => {
  return {
    type: CREATE_NEW_API_KEY_REQUEST
  }
}

const createNewAPIKeySuccess = (apiKeyData) => {
  return {
    type: CREATE_NEW_API_KEY_SUCCESS,
    payload: {
      apiKeyData
    }
  }
}

const createNewAPIKeyFailure = (error) => {
  return {
    type: CREATE_NEW_API_KEY_FAILURE,
    payload: {
      error
    }
  }
}

const createNewAPIKeyIdle = () => {
  return {
    type: CREATE_NEW_API_KEY_IDLE
  }
}

export const createNewAPIKey = (data) => {
  return async (dispatch) => {
    dispatch(createNewAPIKeyRequest())
    try {
      const response = await post({
        url: `/${API_VERSION}/apikeys`,
        payload: data
      })
      dispatch(createNewAPIKeySuccess(response))
    } catch (err) {
      dispatch(createNewAPIKeyFailure(err))
    } finally {
      dispatch(createNewAPIKeyIdle())
    }
  }
}

export const UPDATE_API_KEY_REQUEST = 'UPDATE_API_KEY_REQUEST'
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS'
export const UPDATE_API_KEY_FAILURE = 'UPDATE_API_KEY_FAILURE'
export const UPDATE_API_KEY_IDLE = 'UPDATE_API_KEY_IDLE'

const updateAPIKeyRequest = () => {
  return {
    type: UPDATE_API_KEY_REQUEST
  }
}

const updateAPIKeySuccess = (apiKeyData, apiKeyID) => {
  return {
    type: UPDATE_API_KEY_SUCCESS,
    payload: {
      apiKeyData,
      apiKeyID
    }
  }
}

const updateAPIKeyFailure = (error) => {
  return {
    type: UPDATE_API_KEY_FAILURE,
    payload: {
      error
    }
  }
}

const updateAPIKeyIdle = () => {
  return {
    type: UPDATE_API_KEY_IDLE
  }
}

export const updateAPIKey = (data, apiKeyID) => {
  return async (dispatch) => {
    dispatch(updateAPIKeyRequest())
    try {
      const response = await patch({
        url: `/${API_VERSION}/apikeys/${apiKeyID}`,
        payload: data
      })
      dispatch(updateAPIKeySuccess(response, apiKeyID))
    } catch (err) {
      dispatch(updateAPIKeyFailure(err))
    } finally {
      dispatch(updateAPIKeyIdle())
    }
  }
}

export const DELETE_API_KEY_REQUEST = 'DELETE_API_KEY_REQUEST'
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS'
export const DELETE_API_KEY_FAILURE = 'DELETE_API_KEY_FAILURE'
export const DELETE_API_KEY_IDLE = 'DELETE_API_KEY_IDLE'

const deleteAPIKeyRequest = () => {
  return {
    type: DELETE_API_KEY_REQUEST
  }
}

const deleteAPIKeySuccess = (apiKeyData) => {
  return {
    type: DELETE_API_KEY_SUCCESS,
    payload: {
      apiKeyData
    }
  }
}

const deleteAPIKeyFailure = (error) => {
  return {
    type: DELETE_API_KEY_FAILURE,
    payload: {
      error
    }
  }
}

const deleteAPIKeyIdle = () => {
  return {
    type: DELETE_API_KEY_IDLE
  }
}

export const deleteAPIKey = (data) => {
  return async (dispatch) => {
    dispatch(deleteAPIKeyRequest())
    try {
      await del({
        url: `/${API_VERSION}/apikeys/${data.id}`
      })
      dispatch(deleteAPIKeySuccess(data))
    } catch (err) {
      dispatch(deleteAPIKeyFailure(err))
    } finally {
      dispatch(deleteAPIKeyIdle())
    }
  }
}

export const SIGN_API_KEY_REQUEST = 'SIGN_API_KEY_REQUEST'
export const SIGN_API_KEY_SUCCESS = 'SIGN_API_KEY_SUCCESS'
export const SIGN_API_KEY_FAILURE = 'SIGN_API_KEY_FAILURE'
export const SIGN_API_KEY_IDLE = 'SIGN_API_KEY_IDLE'

const signAPIKeyRequest = () => {
  return {
    type: SIGN_API_KEY_REQUEST
  }
}

const signAPIKeySuccess = (token, apiKeyID) => {
  return {
    type: SIGN_API_KEY_SUCCESS,
    payload: {
      token,
      apiKeyID
    }
  }
}

const signAPIKeyFailure = (error) => {
  return {
    type: SIGN_API_KEY_FAILURE,
    payload: {
      error
    }
  }
}

const signAPIKeyIdle = () => {
  return {
    type: SIGN_API_KEY_IDLE
  }
}

export const signAPIKey = (id) => {
  return async (dispatch) => {
    dispatch(signAPIKeyRequest())
    try {
      const response = await post({
        url: `/${API_VERSION}/apikeys/${id}/sign`
      })
      dispatch(signAPIKeySuccess(response.token, id))
    } catch (err) {
      dispatch(signAPIKeyFailure(err))
    } finally {
      dispatch(signAPIKeyIdle())
    }
  }
}
