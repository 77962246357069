/* eslint-disable no-unused-vars */
import { get, post } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const GET_USER_BY_TOKEN_REQUEST = 'GET_USER_BY_TOKEN_REQUEST'
export const GET_USER_BY_TOKEN_SUCCESS = 'GET_USER_BY_TOKEN_SUCCESS'
export const GET_USER_BY_TOKEN_FAILURE = 'GET_USER_BY_TOKEN_FAILURE'
export const GET_USER_BY_TOKEN_IDLE = 'GET_USER_BY_TOKEN_IDLE'

const getUserByTokenRequest = () => {
  return {
    type: GET_USER_BY_TOKEN_REQUEST
  }
}

const getUserByTokenSuccess = (userEmail) => {
  return {
    type: GET_USER_BY_TOKEN_SUCCESS,
    payload: {
      userEmail
    }
  }
}

const getUserByTokenFailure = (errorCode) => {
  return {
    type: GET_USER_BY_TOKEN_FAILURE,
    payload: {
      errorCode: errorCode
    }
  }
}

const getUserByTokenIdle = () => {
  return {
    type: GET_USER_BY_TOKEN_IDLE
  }
}

export const getUserByToken = (regToken) => {
  return async (dispatch) => {
    dispatch(getUserByTokenRequest())
    try {
      const userDataResp = await get({
        url: `/${API_VERSION}/register/${regToken}`
      })
      dispatch(getUserByTokenSuccess(userDataResp.email))
    } catch (error) {
      dispatch(getUserByTokenFailure(error.status))
    } finally {
      dispatch(getUserByTokenIdle())
    }
  }
}

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE'
export const SET_PASSWORD_IDLE = 'SET_PASSWORD_IDLE'

const setPasswordRequest = () => {
  return {
    type: SET_PASSWORD_REQUEST
  }
}

const setPasswordSuccess = () => {
  return {
    type: SET_PASSWORD_SUCCESS
  }
}

const setPasswordFailure = () => {
  return {
    type: SET_PASSWORD_FAILURE
  }
}

const setPassWordIdle = () => {
  return {
    type: SET_PASSWORD_IDLE
  }
}

export const setPassword = (regToken, userPassword) => {
  return async (dispatch) => {
    dispatch(setPasswordRequest())
    try {
      const userResp = await post({
        url: `/${API_VERSION}/register/${regToken}`,
        payload: { password: userPassword }
      })
      dispatch(setPasswordSuccess())
    } catch (err) {
      dispatch(setPasswordFailure())
    } finally {
      dispatch(setPassWordIdle())
    }
  }
}
