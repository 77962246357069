import styled from 'styled-components'

export default styled.div`
  width: 100%;
  position: relative;
  .screenshot-wrapper {
    position: relative;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    .canvas-na {
      background: ${(props) => props.theme.darkBackground};
      width: 610px;
      height: 418px;
      border-radius: 5px;
      canvas {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .draw-zone-instruction-container {
      position: absolute;
      left: calc(50% - 80px);
      ${'' /* 80px  from width 160px  */}
      top: calc(50% - 50px);
      ${'' /* 50px  from width 100px  */}
      width: 160px;
      height: 100px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 19px;
      cursor: pointer;
      .draw-zone-instruction-image-container {
        text-align: center;
        margin-bottom: 10px;
      }
      .draw-zone-instruction-message-container {
        font-family: 'Prompt';
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: white;
        text-transform: uppercase;
      }
    }
  }
`
