import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import {
  LOAD_DASHBOARD_REQUEST,
  LOAD_DASHBOARD_SUCCESS,
  LOAD_DASHBOARD_FAILURE,
  LOAD_DASHBOARD_IDLE,
  SYNC_DASHBOARD_REQUEST,
  SYNC_DASHBOARD_SUCCESS,
  SYNC_DASHBOARD_FAILURE,
  SYNC_DASHBOARD_IDLE
} from '../actions'

import { UPDATE_CAMERA_SOCKET } from '../../NotificationSidebar/actions'

const initialState = {
  siteDetailData: undefined,
  dashboardData: {
    dashboardTopGraphData: [],
    violationCountRankData: [],
    listAreaDetail: []
  },
  loadDashboard: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  syncDashboard: {
    fetchStatus: FETCH_STATUS_IDLE
  }
}

const prepareViolationCountRankData = (violationRanking) => {
  const preparedData = violationRanking.map((data) => {
    return {
      area: data.area_name,
      count: data.violation_count
    }
  })
  const sortedData = preparedData.sort((a, b) => a.count > b.count)
  return sortedData
}

const prepareDashboardTopGraphData = (overviewGraphData) => {
  const preparedData = overviewGraphData.map((data) => {
    return {
      label: data.label,
      violation: data.Violation
    }
  })
  const sortedData = preparedData.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    } else {
      return 1
    }
  })
  return sortedData
}

const preparedListAreaDetailData = (listAreaDetail) => {
  const preparedData = listAreaDetail.map((areaData) => {
    const cameraData = prepareCameraData(areaData.camera)
    const initialMostValue = {
      camera_name: '',
      violation_count: 0
    }
    const mostViolationCamera = areaData.camera_violation_ranking.reduce((prev, current) => {
      return current.violation_count > prev.violation_count ? current : prev
    }, initialMostValue)

    areaData.camera_violation_ranking.sort((cam1, cam2) => {
      if (cam1.violation_count < cam2.violation_count) {
        return 1
      }
      if (cam1.violation_count > cam2.violation_count) {
        return -1
      }
      return 0
    })

    const areaDetail = {
      id: areaData.area_id,
      areaName: areaData.area_name,
      totalViolationCount: areaData.violation_count,
      mostViolationCameraName: mostViolationCamera.camera_name,
      mostViolationCount: mostViolationCamera.violation_count,
      averageViolationCount: areaData.average_violation_count,
      // eslint-disable-next-line array-callback-return
      cameraViolationRanking: areaData.camera_violation_ranking.filter((cam, index) => {
        if (index <= 2) {
          return cam
        }
      })
    }
    const graphData = prepareAreaGraphData(areaData.camera_violation_graph)
    return {
      cameraData,
      areaDetail,
      graphData
    }
  })
  return preparedData
}

const prepareCameraData = (listCamera) => {
  const prepared = listCamera.map((data, index) => {
    const lat = data.gps.split(',')[0]
    const lng = data.gps.split(',')[1]
    return Object.assign({}, data, {
      camera_status: data.camera_status.toUpperCase(),
      location: {
        lat,
        lng
      }
    })
  })
  return prepared
}

const prepareAreaGraphData = (listCameraViolationGraph) => {
  const allLabel = listCameraViolationGraph.map((data) => data.label)
  const uniqueLabel = new Set(allLabel)
  const listLabel = [...uniqueLabel]
  const graphData = listLabel.map((label) => {
    const listDataInSameLabel = listCameraViolationGraph.filter((graphData) => graphData.label === label)
    const initialGraphSpotData = {
      label: label
    }
    const graphSpotData = listDataInSameLabel.reduce((prev, current) => {
      return Object.assign({}, prev, {
        [current.camera_name]: current.Violation
      })
    }, initialGraphSpotData)
    return graphSpotData
  })

  const sortedGraphData = graphData.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    } else {
      return 1
    }
  })
  return sortedGraphData
}

const dashboardPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DASHBOARD_REQUEST: {
      return Object.assign({}, state, {
        loadDashboard: Object.assign({}, state.loadDashboard, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_DASHBOARD_SUCCESS: {
      const preparedViolationRank = prepareViolationCountRankData(action.payload.siteSummaryData.violation_ranking)
      const preparedDashboardTopGraph = prepareDashboardTopGraphData(action.payload.siteSummaryData.activity_graph)
      const preparedListAreaDetail = preparedListAreaDetailData(action.payload.areaDetailData)
      return Object.assign({}, state, {
        loadDashboard: Object.assign({}, state.loadDashboard, {
          fetchStatus: FETCH_STATUS_SUCCESS
        }),
        siteDetailData: action.payload.siteDetail,
        dashboardData: Object.assign({}, state.dashboardData, {
          violationCountRankData: preparedViolationRank,
          dashboardTopGraphData: preparedDashboardTopGraph,
          listAreaDetail: preparedListAreaDetail
        })
      })
    }
    case LOAD_DASHBOARD_FAILURE: {
      return Object.assign({}, state, {
        loadDashboard: Object.assign({}, state.loadDashboard, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case LOAD_DASHBOARD_IDLE: {
      return Object.assign({}, state, {
        loadDashboard: Object.assign({}, state.loadDashboard, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case SYNC_DASHBOARD_REQUEST: {
      return Object.assign({}, state, {
        syncDashboard: Object.assign({}, state.syncDashboard, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case SYNC_DASHBOARD_SUCCESS: {
      const preparedViolationRank = prepareViolationCountRankData(action.payload.siteSummaryData.violation_ranking)
      const preparedDashboardTopGraph = prepareDashboardTopGraphData(action.payload.siteSummaryData.activity_graph)
      const preparedListAreaDetail = preparedListAreaDetailData(action.payload.areaDetailData)
      return Object.assign({}, state, {
        syncDashboard: Object.assign({}, state.syncDashboard, {
          fetchStatus: FETCH_STATUS_SUCCESS
        }),
        siteDetailData: action.payload.siteDetail,
        dashboardData: Object.assign({}, state.dashboardData, {
          violationCountRankData: preparedViolationRank,
          dashboardTopGraphData: preparedDashboardTopGraph,
          listAreaDetail: preparedListAreaDetail
        })
      })
    }
    case SYNC_DASHBOARD_FAILURE: {
      return Object.assign({}, state, {
        syncDashboard: Object.assign({}, state.syncDashboard, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case SYNC_DASHBOARD_IDLE: {
      return Object.assign({}, state, {
        syncDashboard: Object.assign({}, state.syncDashboard, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_CAMERA_SOCKET: {
      const { upComingCameraData } = action.payload
      const updatedListAreaDetail = [...state.dashboardData.listAreaDetail].map((areaDetailData) => {
        const updatedCameraData = areaDetailData.cameraData.map((camera) => {
          if (camera.camera_id === upComingCameraData.notification_object.camera_id) {
            return Object.assign({}, camera, {
              camera_status: upComingCameraData.notification_object.camera_status,
              image: upComingCameraData.notification_object.camera_image ? upComingCameraData.notification_object.camera_image : camera.image
            })
          }
          return camera
        })
        return Object.assign({}, areaDetailData, {
          cameraData: updatedCameraData
        })
      })
      return Object.assign({}, state, {
        dashboardData: Object.assign({}, state.dashboardData, {
          listAreaDetail: updatedListAreaDetail
        })
      })
    }
    default: {
      return state
    }
  }
}

export default dashboardPage
