import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import {
  LOAD_PROJECT_DETAIL_REQUEST,
  LOAD_PROJECT_DETAIL_SUCCESS,
  LOAD_PROJECT_DETAIL_FAILURE,
  LOAD_PROJECT_DETAIL_IDLE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_IDLE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_IDLE,
  LOAD_LIST_SITE_REQUEST,
  LOAD_LIST_SITE_SUCCESS,
  LOAD_LIST_SITE_FAILURE,
  LOAD_LIST_SITE_IDLE,
  CREATE_SITE_REQUEST,
  CREATE_SITE_SUCCESS,
  CREATE_SITE_FAILURE,
  CREATE_SITE_IDLE,
  DELETE_SITE_REQUEST,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_FAILURE,
  DELETE_SITE_IDLE,
  UPDATE_SITE_REQUEST,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAILURE,
  UPDATE_SITE_IDLE,
  CREATE_AREA_REQUEST,
  CREATE_AREA_SUCCESS,
  CREATE_AREA_FAILURE,
  CREATE_AREA_IDLE,
  UPDATE_AREA_REQUEST,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_FAILURE,
  UPDATE_AREA_IDLE,
  DELETE_AREA_REQUEST,
  DELETE_AREA_SUCCESS,
  DELETE_AREA_FAILURE,
  DELETE_AREA_IDLE,
  CREATE_CAMERA_REQUEST,
  CREATE_CAMERA_SUCCESS,
  CREATE_CAMERA_FAILURE,
  CREATE_CAMERA_IDLE,
  UPDATE_CAMERA_REQUEST,
  UPDATE_CAMERA_SUCCESS,
  UPDATE_CAMERA_FAILURE,
  UPDATE_CAMERA_IDLE,
  DELETE_CAMERA_REQUEST,
  DELETE_CAMERA_SUCCESS,
  DELETE_CAMERA_FAILURE,
  DELETE_CAMERA_IDLE,
  MOVE_CAMERA_BETWEEN_AREA_REQUEST,
  MOVE_CAMERA_BETWEEN_AREA_SUCCESS,
  MOVE_CAMERA_BETWEEN_AREA_FAILURE,
  MOVE_CAMERA_BETWEEN_AREA_IDLE,
  CREATE_SPEAKER_REQUEST,
  CREATE_SPEAKER_SUCCESS,
  CREATE_SPEAKER_FAILURE,
  CREATE_SPEAKER_IDLE,
  UPDATE_SPEAKER_REQUEST,
  UPDATE_SPEAKER_SUCCESS,
  UPDATE_SPEAKER_FAILURE,
  UPDATE_SPEAKER_IDLE,
  DELETE_SPEAKER_REQUEST,
  DELETE_SPEAKER_SUCCESS,
  DELETE_SPEAKER_FAILURE,
  DELETE_SPEAKER_IDLE
} from '../actions'

import { UPDATE_CAMERA_SOCKET } from '../../NotificationSidebar/actions'

const initialState = {
  projectDetail: undefined,
  listSite: [],
  loadProjectDetail: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  updateProjectDetail: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  deleteProjectDetail: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  loadListSite: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  createSite: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  deleteSite: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  updateSite: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  createArea: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  updateArea: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  deleteArea: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  createCamera: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  updateCamera: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  deleteCamera: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  moveCameraBetweenArea: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  createSpeaker: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  updateSpeaker: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  deleteSpeaker: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  isCalculating: false
}

const projectDetailPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROJECT_DETAIL_REQUEST: {
      return Object.assign({}, state, {
        loadProjectDetail: Object.assign({}, state.loadProjectDetail, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_PROJECT_DETAIL_SUCCESS: {
      return Object.assign({}, state, {
        projectDetail: action.payload.projectDetail,
        loadProjectDetail: Object.assign({}, state.loadProjectDetail, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case LOAD_PROJECT_DETAIL_FAILURE: {
      return Object.assign({}, state, {
        loadProjectDetail: Object.assign({}, state.loadProjectDetail, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case LOAD_PROJECT_DETAIL_IDLE: {
      return Object.assign({}, state, {
        loadProjectDetail: Object.assign({}, state.loadProjectDetail, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_PROJECT_REQUEST: {
      return Object.assign({}, state, {
        updateProjectDetail: Object.assign({}, state.updateProjectDetail, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case UPDATE_PROJECT_SUCCESS: {
      return Object.assign({}, state, {
        projectDetail: action.payload.projectDetail,
        updateProjectDetail: Object.assign({}, state.updateProjectDetail, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case UPDATE_PROJECT_FAILURE: {
      return Object.assign({}, state, {
        updateProjectDetail: Object.assign({}, state.updateProjectDetail, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case UPDATE_PROJECT_IDLE: {
      return Object.assign({}, state, {
        updateProjectDetail: Object.assign({}, state.updateProjectDetail, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case DELETE_PROJECT_REQUEST: {
      return Object.assign({}, state, {
        deleteProjectDetail: Object.assign({}, state.deleteProjectDetail, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case DELETE_PROJECT_SUCCESS: {
      return Object.assign({}, state, {
        deleteProjectDetail: Object.assign({}, state.deleteProjectDetail, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case DELETE_PROJECT_FAILURE: {
      return Object.assign({}, state, {
        deleteProjectDetail: Object.assign({}, state.deleteProjectDetail, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case DELETE_PROJECT_IDLE: {
      return Object.assign({}, state, {
        deleteProjectDetail: Object.assign({}, state.deleteProjectDetail, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case LOAD_LIST_SITE_REQUEST: {
      return Object.assign({}, state, {
        loadListSite: Object.assign({}, state.loadListSite, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_LIST_SITE_SUCCESS: {
      return Object.assign({}, state, {
        listSite: action.payload.listSite,
        loadListSite: Object.assign({}, state.loadListSite, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case LOAD_LIST_SITE_FAILURE: {
      return Object.assign({}, state, {
        loadListSite: Object.assign({}, state.loadListSite, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case LOAD_LIST_SITE_IDLE: {
      return Object.assign({}, state, {
        loadListSite: Object.assign({}, state.loadListSite, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_SITE_REQUEST: {
      return Object.assign({}, state, {
        createSite: Object.assign({}, state.createSite, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case CREATE_SITE_SUCCESS: {
      const preparedSiteData = Object.assign({}, action.payload.siteData, {
        areas: []
      })
      return Object.assign({}, state, {
        listSite: [...state.listSite, preparedSiteData],
        createSite: Object.assign({}, state.createSite, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          creatingSite: action.payload.siteData
        })
      })
    }
    case CREATE_SITE_FAILURE: {
      return Object.assign({}, state, {
        createSite: Object.assign({}, state.createSite, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case CREATE_SITE_IDLE: {
      return Object.assign({}, state, {
        createSite: Object.assign({}, state.createSite, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case DELETE_SITE_REQUEST: {
      return Object.assign({}, state, {
        deleteSite: Object.assign({}, state.deleteSite, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case DELETE_SITE_SUCCESS: {
      const deletedList = state.listSite.filter((siteData) => siteData.site_id !== action.payload.siteID)
      return Object.assign({}, state, {
        listSite: deletedList,
        deleteSite: Object.assign({}, state.deleteSite, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case DELETE_SITE_FAILURE: {
      return Object.assign({}, state, {
        deleteSite: Object.assign({}, state.deleteSite, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case DELETE_SITE_IDLE: {
      return Object.assign({}, state, {
        deleteSite: Object.assign({}, state.deleteSite, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_SITE_REQUEST: {
      return Object.assign({}, state, {
        updateSite: Object.assign({}, state.updateSite, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case UPDATE_SITE_SUCCESS: {
      const updatedSiteList = state.listSite.map((siteData) => {
        if (siteData.site_id === action.payload.siteData.site_id) {
          return Object.assign({}, siteData, action.payload.siteData)
        }
        return siteData
      })
      return Object.assign({}, state, {
        listSite: updatedSiteList,
        updateSite: Object.assign({}, state.updateSite, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case UPDATE_SITE_FAILURE: {
      return Object.assign({}, state, {
        updateSite: Object.assign({}, state.updateSite, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case UPDATE_SITE_IDLE: {
      return Object.assign({}, state, {
        updateSite: Object.assign({}, state.updateSite, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_AREA_REQUEST: {
      return Object.assign({}, state, {
        createArea: Object.assign({}, state.createArea, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case CREATE_AREA_SUCCESS: {
      const prepareNewAreaData = Object.assign({}, action.payload.areaData, {
        cameras: []
      })
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === action.payload.siteID)
      targetSite.areas = [...targetSite.areas, prepareNewAreaData]
      return Object.assign({}, state, {
        listSite: tempListSite,
        createArea: Object.assign({}, state.createArea, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case CREATE_AREA_FAILURE: {
      return Object.assign({}, state, {
        createArea: Object.assign({}, state.createArea, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case CREATE_AREA_IDLE: {
      return Object.assign({}, state, {
        createArea: Object.assign({}, state.createArea, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_AREA_REQUEST: {
      return Object.assign({}, state, {
        updateArea: Object.assign({}, state.updateArea, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case UPDATE_AREA_SUCCESS: {
      const updatedAreaData = action.payload.areaData
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === action.payload.siteID)
      targetSite.areas = targetSite.areas.map((areaData) => {
        if (areaData.area_id === updatedAreaData.area_id) {
          return updatedAreaData
        }
        return areaData
      })
      return Object.assign({}, state, {
        listSite: tempListSite,
        updateArea: Object.assign({}, state.updateArea, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case UPDATE_AREA_FAILURE: {
      return Object.assign({}, state, {
        updateArea: Object.assign({}, state.updateArea, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case UPDATE_AREA_IDLE: {
      return Object.assign({}, state, {
        updateArea: Object.assign({}, state.updateArea, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case DELETE_AREA_REQUEST: {
      return Object.assign({}, state, {
        deleteArea: Object.assign({}, state.deleteArea, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case DELETE_AREA_SUCCESS: {
      const { siteID, areaID } = action.payload
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === siteID)
      targetSite.areas = targetSite.areas.filter((areaData) => areaData.area_id !== areaID)
      return Object.assign({}, state, {
        listSite: tempListSite,
        deleteArea: Object.assign({}, state.deleteArea, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case DELETE_AREA_FAILURE: {
      return Object.assign({}, state, {
        deleteArea: Object.assign({}, state.deleteArea, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case DELETE_AREA_IDLE: {
      return Object.assign({}, state, {
        deleteArea: Object.assign({}, state.deleteArea, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_CAMERA_REQUEST: {
      return Object.assign({}, state, {
        createCamera: Object.assign({}, state.createCamera, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case CREATE_CAMERA_SUCCESS: {
      const cameraData = action.payload.cameraData
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === cameraData.site_id)
      targetSite.areas = [...targetSite.areas]
      const targetArea = targetSite.areas.find((areaData) => areaData.area_id === cameraData.area_id)
      targetArea.cameras = [...targetArea.cameras, cameraData]
      return Object.assign({}, state, {
        listSite: tempListSite,
        createCamera: Object.assign({}, state.createCamera, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case CREATE_CAMERA_FAILURE: {
      return Object.assign({}, state, {
        createCamera: Object.assign({}, state.createCamera, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case CREATE_CAMERA_IDLE: {
      return Object.assign({}, state, {
        createCamera: Object.assign({}, state.createCamera, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_CAMERA_REQUEST: {
      return Object.assign({}, state, {
        updateCamera: Object.assign({}, state.updateCamera, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case UPDATE_CAMERA_SUCCESS: {
      const updatedCameraData = action.payload.cameraData
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === updatedCameraData.site_id)
      targetSite.areas = [...targetSite.areas]
      const targetArea = targetSite.areas.find((areaData) => areaData.area_id === updatedCameraData.area_id)
      targetArea.cameras = targetArea.cameras.map((cameraData) => {
        if (cameraData.camera_id === updatedCameraData.camera_id) {
          return Object.assign({}, updatedCameraData, { speaker: cameraData.speaker })
        }
        return cameraData
      })
      return Object.assign({}, state, {
        listSite: tempListSite,
        updateCamera: Object.assign({}, state.updateCamera, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case UPDATE_CAMERA_FAILURE: {
      return Object.assign({}, state, {
        updateCamera: Object.assign({}, state.updateCamera, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case UPDATE_CAMERA_IDLE: {
      return Object.assign({}, state, {
        updateCamera: Object.assign({}, state.updateCamera, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case DELETE_CAMERA_REQUEST: {
      return Object.assign({}, state, {
        deleteCamera: Object.assign({}, state.deleteCamera, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case DELETE_CAMERA_SUCCESS: {
      const { cameraID, areaID, siteID } = action.payload
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === siteID)
      targetSite.areas = [...targetSite.areas]
      const targetArea = targetSite.areas.find((areaData) => areaData.area_id === areaID)
      targetArea.cameras = targetArea.cameras.filter((cameraData) => cameraData.camera_id !== cameraID)
      return Object.assign({}, state, {
        listSite: tempListSite,
        deleteCamera: Object.assign({}, state.deleteCamera, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case DELETE_CAMERA_FAILURE: {
      return Object.assign({}, state, {
        deleteCamera: Object.assign({}, state.deleteCamera, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case DELETE_CAMERA_IDLE: {
      return Object.assign({}, state, {
        deleteCamera: Object.assign({}, state.deleteCamera, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case MOVE_CAMERA_BETWEEN_AREA_REQUEST: {
      return Object.assign({}, state, {
        moveCameraBetweenArea: Object.assign({}, state.moveCameraBetweenArea, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case MOVE_CAMERA_BETWEEN_AREA_SUCCESS: {
      const { cameraData, sourceAreaID } = action.payload
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === cameraData.site_id)
      targetSite.areas = [...targetSite.areas]
      const targetArea = targetSite.areas.find((areaData) => areaData.area_id === cameraData.area_id)
      const sourceArea = targetSite.areas.find((areaData) => areaData.area_id === sourceAreaID)
      sourceArea.cameras = sourceArea.cameras.filter((sourceCameraData) => sourceCameraData.camera_id !== cameraData.camera_id)
      targetArea.cameras = [...targetArea.cameras, cameraData]
      return Object.assign({}, state, {
        listSite: tempListSite,
        moveCameraBetweenArea: Object.assign({}, state.moveCameraBetweenArea, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case MOVE_CAMERA_BETWEEN_AREA_FAILURE: {
      return Object.assign({}, state, {
        moveCameraBetweenArea: Object.assign({}, state.moveCameraBetweenArea, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case MOVE_CAMERA_BETWEEN_AREA_IDLE: {
      return Object.assign({}, state, {
        moveCameraBetweenArea: Object.assign({}, state.moveCameraBetweenArea, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_SPEAKER_REQUEST: {
      return Object.assign({}, state, {
        createSpeaker: Object.assign({}, state.createSpeaker, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case CREATE_SPEAKER_SUCCESS: {
      const { speakerData, cameraData, areaID, siteID } = action.payload
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === siteID)
      targetSite.areas = [...targetSite.areas]
      const targetArea = targetSite.areas.find((areaData) => areaData.area_id === areaID)
      targetArea.cameras = [...targetArea.cameras]
      const targetCamera = targetArea.cameras.find((data) => data.camera_id === cameraData.camera_id)
      targetCamera.speaker = speakerData
      return Object.assign({}, state, {
        listSite: tempListSite,
        createSpeaker: Object.assign({}, state.createSpeaker, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case CREATE_SPEAKER_FAILURE: {
      return Object.assign({}, state, {
        createSpeaker: Object.assign({}, state.createSpeaker, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case CREATE_SPEAKER_IDLE: {
      return Object.assign({}, state, {
        createSpeaker: Object.assign({}, state.createSpeaker, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_SPEAKER_REQUEST: {
      return Object.assign({}, state, {
        updateSpeaker: Object.assign({}, state.updateSpeaker, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case UPDATE_SPEAKER_SUCCESS: {
      const { speakerData, cameraData, areaID, siteID } = action.payload
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === siteID)
      targetSite.areas = [...targetSite.areas]
      const targetArea = targetSite.areas.find((areaData) => areaData.area_id === areaID)
      targetArea.cameras = [...targetArea.cameras]
      const targetCamera = targetArea.cameras.find((data) => data.camera_id === cameraData.camera_id)
      targetCamera.speaker = speakerData
      return Object.assign({}, state, {
        listSite: tempListSite,
        updateSpeaker: Object.assign({}, state.updateSpeaker, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case UPDATE_SPEAKER_FAILURE: {
      return Object.assign({}, state, {
        updateSpeaker: Object.assign({}, state.updateSpeaker, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case UPDATE_SPEAKER_IDLE: {
      return Object.assign({}, state, {
        updateSpeaker: Object.assign({}, state.updateSpeaker, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case DELETE_SPEAKER_REQUEST: {
      return Object.assign({}, state, {
        updateSpeaker: Object.assign({}, state.updateSpeaker, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case DELETE_SPEAKER_SUCCESS: {
      const { cameraData, areaID, siteID } = action.payload
      const tempListSite = [...state.listSite]
      const targetSite = tempListSite.find((siteData) => siteData.site_id === siteID)
      targetSite.areas = [...targetSite.areas]
      const targetArea = targetSite.areas.find((areaData) => areaData.area_id === areaID)
      targetArea.cameras = [...targetArea.cameras]
      const targetCamera = targetArea.cameras.find((data) => data.camera_id === cameraData.camera_id)
      targetCamera.speaker = undefined
      return Object.assign({}, state, {
        listSite: tempListSite,
        updateSpeaker: Object.assign({}, state.updateSpeaker, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case DELETE_SPEAKER_FAILURE: {
      return Object.assign({}, state, {
        updateSpeaker: Object.assign({}, state.updateSpeaker, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case DELETE_SPEAKER_IDLE: {
      return Object.assign({}, state, {
        updateSpeaker: Object.assign({}, state.updateSpeaker, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_CAMERA_SOCKET: {
      const { notification_object } = action.payload.upComingCameraData
      const tempListSite = [...state.listSite]
      if (tempListSite.length > 0) {
        const targetSite = tempListSite.find((siteData) => siteData.site_id === notification_object.site_id)
        targetSite.areas = [...targetSite.areas]
        const targetArea = targetSite.areas.find((areaData) => areaData.area_id === notification_object.area_id)
        targetArea.cameras = targetArea.cameras.map((cameraData) => {
          if (cameraData.camera_id === notification_object.camera_id) {
            return Object.assign({}, cameraData, {
              resolution_width: notification_object.camera_res_width,
              resolution_height: notification_object.camera_res_height
            })
          }
          return cameraData
        })
      }
      return Object.assign({}, state, {
        listSite: tempListSite,
        isCalculating: notification_object.calculating
      })
    }
    default: {
      return state
    }
  }
}

export default projectDetailPage
