import {
  LOAD_EACH_VIOLATION_REQUEST,
  LOAD_EACH_VIOLATION_SUCCESS,
  LOAD_EACH_VIOLATION_FAILURE,
  LOAD_EACH_VIOLATION_IDLE,
  SYNC_EACH_VIOLATION_REQUEST,
  SYNC_EACH_VIOLATION_SUCCESS,
  SYNC_EACH_VIOLATION_FAILURE,
  SYNC_EACH_VIOLATION_IDLE,
  DELETE_VIOLATION_REQUEST,
  DELETE_VIOLATION_SUCCESS,
  DELETE_VIOLATION_FAILURE,
  DELETE_VIOLATION_IDLE,
  REPORT_VIOLATION_REQUEST,
  REPORT_VIOLATION_SUCCESS,
  REPORT_VIOLATION_FAILURE,
  REPORT_VIOLATION_IDLE
} from '../actions'

import { UPDATE_CAMERA_SOCKET } from '../../NotificationSidebar/actions'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

const initialState = {
  cameraDetail: undefined,
  allPage: 1,
  cameraViolation: [],
  loadCameraData: {
    fetchStatus: FETCH_STATUS_IDLE,
    syncStatus: FETCH_STATUS_IDLE
  },
  deleteViolation: {
    fetchStatus: FETCH_STATUS_IDLE,
    violationId: 0
  },
  reportViolation: {
    fetchStatus: FETCH_STATUS_IDLE,
    violationId: 0
  },
  siteDetail: undefined
}

const preparedCameraDetailsGraph = (cameraDetail) => {
  const preparedGraph = cameraDetail.activity_graph.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    } else {
      return 1
    }
  })
  const preparedCameraDetail = Object.assign({}, cameraDetail, {
    activity_graph: preparedGraph
  })
  return preparedCameraDetail
}

const eachViolationDetailPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EACH_VIOLATION_REQUEST: {
      return Object.assign({}, state, {
        loadCameraData: Object.assign({}, state.loadCameraData, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_EACH_VIOLATION_SUCCESS: {
      const preparedCameraDetail = preparedCameraDetailsGraph(action.payload.cameraDetail)
      return Object.assign({}, state, {
        cameraDetail: preparedCameraDetail,
        cameraViolation: action.payload.cameraViolationCases,
        allPage: action.payload.allPaginationNum,
        loadCameraData: Object.assign({}, state.loadCameraData, {
          fetchStatus: FETCH_STATUS_SUCCESS
        }),
        areaCount: action.payload.areaCount,
        siteDetail: action.payload.siteDetail
      })
    }
    case LOAD_EACH_VIOLATION_FAILURE: {
      return Object.assign({}, state, {
        loadCameraData: Object.assign({}, state.loadCameraData, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case LOAD_EACH_VIOLATION_IDLE: {
      return Object.assign({}, state, {
        loadCameraData: Object.assign({}, state.loadCameraData, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case SYNC_EACH_VIOLATION_REQUEST: {
      return Object.assign({}, state, {
        loadCameraData: Object.assign({}, state.loadCameraData, {
          syncStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case SYNC_EACH_VIOLATION_SUCCESS: {
      return Object.assign({}, state, {
        cameraDetail: preparedCameraDetailsGraph(action.payload.cameraDetail),
        cameraViolation: action.payload.cameraViolationCases,
        allPage: action.payload.allPaginationNum,
        loadCameraData: Object.assign({}, state.loadCameraData, {
          syncStatus: FETCH_STATUS_SUCCESS
        }),
        areaCount: action.payload.areaCount,
        siteDetail: action.payload.siteDetail
      })
    }
    case SYNC_EACH_VIOLATION_FAILURE: {
      return Object.assign({}, state, {
        loadCameraData: Object.assign({}, state.loadCameraData, {
          syncStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case SYNC_EACH_VIOLATION_IDLE: {
      return Object.assign({}, state, {
        loadCameraData: Object.assign({}, state.loadCameraData, {
          syncStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_CAMERA_SOCKET: {
      const { upComingCameraData } = action.payload
      let updatedCameraDetail = state.cameraDetail
      if (updatedCameraDetail && updatedCameraDetail.camera.camera_id === upComingCameraData.notification_object.camera_id) {
        updatedCameraDetail = Object.assign({}, state.cameraDetail, {
          camera: Object.assign({}, state.cameraDetail.camera, {
            camera_status: upComingCameraData.notification_object.camera_status,
            image: upComingCameraData.notification_object.camera_image
              ? upComingCameraData.notification_object.camera_image
              : state.cameraDetail.camera.image
          })
        })
      }
      return Object.assign({}, state, {
        cameraDetail: updatedCameraDetail
      })
    }
    case DELETE_VIOLATION_REQUEST: {
      return Object.assign({}, state, {
        deleteViolation: {
          fetchStatus: FETCH_STATUS_REQUEST,
          violationId: action.payload.violationId
        }
      })
    }
    case DELETE_VIOLATION_SUCCESS: {
      const tempListCameraViolation = state.cameraViolation.filter((violation) => violation.id !== action.payload.violationId)
      return Object.assign({}, state, {
        cameraViolation: tempListCameraViolation,
        deleteViolation: {
          fetchStatus: FETCH_STATUS_SUCCESS,
          violationId: action.payload.violationId
        }
      })
    }
    case DELETE_VIOLATION_FAILURE: {
      return Object.assign({}, state, {
        deleteViolation: {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        }
      })
    }
    case DELETE_VIOLATION_IDLE: {
      return Object.assign({}, state, {
        deleteViolation: {
          fetchStatus: FETCH_STATUS_IDLE
        }
      })
    }
    case REPORT_VIOLATION_REQUEST: {
      return Object.assign({}, state, {
        reportViolation: {
          fetchStatus: FETCH_STATUS_REQUEST,
          violationId: action.payload.violationId
        }
      })
    }
    case REPORT_VIOLATION_SUCCESS: {
      const tempListCameraViolation = state.cameraViolation.map((violation) => {
        if (violation.id === action.payload.violationData.id) {
          return action.payload.violationData
        }
        return violation
      })
      return Object.assign({}, state, {
        cameraViolation: tempListCameraViolation,
        reportViolation: {
          fetchStatus: FETCH_STATUS_SUCCESS,
          violationId: action.payload.violationData.id
        }
      })
    }
    case REPORT_VIOLATION_FAILURE: {
      return Object.assign({}, state, {
        reportViolation: {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error,
          reportAction: action.payload.reported
        }
      })
    }
    case REPORT_VIOLATION_IDLE: {
      return Object.assign({}, state, {
        reportViolation: {
          fetchStatus: FETCH_STATUS_IDLE,
          violationId: 0
        }
      })
    }
    default: {
      return state
    }
  }
}

export default eachViolationDetailPage
