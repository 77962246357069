import { combineReducers } from 'redux'

import applicationPage from '../containers/ApplicationLayout/reducers'
import notification from '../containers/NotificationSidebar/reducers'
import dashboardPage from '../containers/DashboardPage/reducers'
import violationDetailPage from '../containers/ViolationDetailPage/reducers'
import allViolationDetailPage from '../containers/AllViolationDetailPage/reducers'
import eachViolationDetailPage from '../containers/EachViolationDetailPage/reducers'
import loginPage from '../containers/LoginPage/reducers'
import selectSitePage from '../containers/SelectSitePage/reducers'
import allCameraZonePage from '../containers/AllCameraZonePage/reducers'
import eachCameraZonePage from '../containers/EachCameraZonePage/reducers'
import projectPage from '../containers/ProjectPageLayout/reducers'
import projectDetailPage from '../containers/ProjectDetailPage/reducers'
import setPasswordPage from '../containers/SetPasswordPage/reducers'
import userManagementPage from '../containers/UserManagementPage/reducers'
import apiKeyPage from '../containers/APIKeyPage/reducers'

const rootReducer = combineReducers({
  applicationPage,
  notification,
  dashboardPage,
  violationDetailPage,
  allViolationDetailPage,
  eachViolationDetailPage,
  loginPage,
  selectSitePage,
  allCameraZonePage,
  eachCameraZonePage,
  projectPage,
  projectDetailPage,
  setPasswordPage,
  userManagementPage,
  apiKeyPage
})

export default rootReducer
