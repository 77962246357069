import moment from 'moment'

import { UPDATE_DATE_PICKER, UPDATE_DATE_FILTER, USE_CURRENT_DATE_PICKER_AND_FILTER_SUCCESS } from '../actions'

const initialState = {
  date: moment(),
  dateFilterType: 'day'
}

const application = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATE_PICKER: {
      const isSelectedDateAfterToday = moment(action.payload.date).isAfter(moment())
      return Object.assign({}, state, {
        date: isSelectedDateAfterToday ? moment() : action.payload.date
      })
    }
    case UPDATE_DATE_FILTER: {
      return Object.assign({}, state, {
        dateFilterType: action.payload.dateFilterType
      })
    }
    case USE_CURRENT_DATE_PICKER_AND_FILTER_SUCCESS: {
      return Object.assign({}, state, {
        date: action.payload.useCurrentDate ? moment() : state.date,
        dateFilterType: action.payload.useCurrentDate ? 'day' : state.dateFilterType
      })
    }
    default: {
      return state
    }
  }
}

export default application
