import { get, post, patch, del } from '../../../utils'
const API_VERSION = process.env.API_VERSION

export const LOAD_CAMERA_ZONE_REQUEST = 'LOAD_CAMERA_ZONE_REQUEST'
export const LOAD_CAMERA_ZONE_SUCCESS = 'LOAD_CAMERA_ZONE_SUCCESS'
export const LOAD_CAMERA_ZONE_FAILURE = 'LOAD_CAMERA_ZONE_FAILURE'
export const LOAD_CAMERA_ZONE_IDLE = 'LOAD_CAMERA_ZONE_IDLE'

const loadCameraZoneRequest = () => {
  return {
    type: LOAD_CAMERA_ZONE_REQUEST
  }
}

const loadCameraZoneSuccess = (cameraData, listViolations, listClasses, listColorRanges) => {
  return {
    type: LOAD_CAMERA_ZONE_SUCCESS,
    payload: {
      cameraData,
      listViolations,
      listClasses,
      listColorRanges
    }
  }
}

const loadCameraZoneFailure = (error) => {
  return {
    type: LOAD_CAMERA_ZONE_FAILURE,
    payload: {
      error
    }
  }
}

const loadCameraZoneIdle = () => {
  return {
    type: LOAD_CAMERA_ZONE_IDLE
  }
}

export const loadCameraZone = (cameraId) => {
  return async (dispatch) => {
    dispatch(loadCameraZoneRequest())
    try {
      const cameraDataResponse = await get({
        url: `/${API_VERSION}/cameras/${cameraId}`
      })
      const listViolationResponse = await get({
        url: `/${API_VERSION}/violations`
      })
      const listClassesResponse = await get({
        url: `/${API_VERSION}/classes`
      })

      const listColorRangeResponse = await get({
        url: `/${API_VERSION}/color_ranges`
      })

      dispatch(loadCameraZoneSuccess(cameraDataResponse, listViolationResponse, listClassesResponse, listColorRangeResponse))
    } catch (err) {
      dispatch(loadCameraZoneFailure(err))
    } finally {
      dispatch(loadCameraZoneIdle())
    }
  }
}

export const ADD_ZONE_REQUEST = 'ADD_ZONE_REQUEST'
export const ADD_ZONE_SUCCESS = 'ADD_ZONE_SUCCESS'
export const ADD_ZONE_FAILURE = 'ADD_ZONE_FAILURE'
export const ADD_ZONE_IDLE = 'ADD_ZONE_IDLE'

const addZoneRequest = () => {
  return {
    type: ADD_ZONE_REQUEST
  }
}

const addZoneSuccess = (zoneData) => {
  return {
    type: ADD_ZONE_SUCCESS,
    payload: {
      zoneData
    }
  }
}

const addZoneFailure = (error) => {
  return {
    type: ADD_ZONE_FAILURE,
    payload: {
      error
    }
  }
}

const addZoneIdle = () => {
  return {
    type: ADD_ZONE_IDLE
  }
}

export const addZone = (zoneData) => {
  /* TODO mock object_size in production right now */

  const zone = {
    allowed: zoneData.allowed,
    features: zoneData.features,
    points: zoneData.points,
    time_range: zoneData.time_range,
    violation_id: zoneData.violation_id,
    camera_id: zoneData.camera_id,
    violation_name: zoneData.violation_name,
    grid_color: zoneData.grid_color,
    conditions: zoneData.conditions,
    object_size: zoneData.object_size,
    color_range: zoneData.color_range
  }

  return async (dispatch) => {
    dispatch(addZoneRequest())
    try {
      const addZoneResponse = await post({
        url: `/${API_VERSION}/zones`,
        payload: { zone: zone }
      })
      dispatch(addZoneSuccess(addZoneResponse))
    } catch (err) {
      dispatch(addZoneFailure(err))
    } finally {
      dispatch(addZoneIdle())
    }
  }
}

export const EDIT_ZONE_REQUEST = 'EDIT_ZONE_REQUEST'
export const EDIT_ZONE_SUCCESS = 'EDIT_ZONE_SUCCESS'
export const EDIT_ZONE_FAILURE = 'EDIT_ZONE_FAILURE'
export const EDIT_ZONE_IDLE = 'EDIT_ZONE_IDLE'

const editZoneRequest = () => {
  return {
    type: EDIT_ZONE_REQUEST
  }
}

const editZoneSuccess = (updatedZoneData) => {
  return {
    type: EDIT_ZONE_SUCCESS,
    payload: {
      zoneData: updatedZoneData
    }
  }
}

const editZoneFailure = (error) => {
  return {
    type: EDIT_ZONE_FAILURE,
    payload: {
      error
    }
  }
}

const editZoneIdle = () => {
  return {
    type: EDIT_ZONE_IDLE
  }
}

export const editZone = (zoneData) => {
  /* TODO mock object_size in production right now */
  return async (dispatch) => {
    dispatch(editZoneRequest())
    try {
      const zone = {
        allowed: zoneData.allowed,
        features: zoneData.features,
        points: zoneData.points,
        time_range: zoneData.time_range,
        violation_id: zoneData.violation_id,
        camera_id: zoneData.camera_id,
        violation_name: zoneData.violation_name,
        grid_color: zoneData.grid_color,
        conditions: zoneData.conditions,
        object_size: zoneData.object_size,
        color_range: zoneData.color_range
      }
      const updatedZoneResponse = await patch({
        url: `/${API_VERSION}/zones/${zoneData.zone_id}`,
        payload: { zone: zone }
      })
      dispatch(editZoneSuccess(updatedZoneResponse))
    } catch (err) {
      dispatch(editZoneFailure(err))
    } finally {
      dispatch(editZoneIdle())
    }
  }
}

export const DELETE_ZONE_REQUEST = 'DELETE_ZONE_REQUEST'
export const DELETE_ZONE_SUCCESS = 'DELETE_ZONE_SUCCESS'
export const DELETE_ZONE_FAILURE = 'DELETE_ZONE_FAILURE'
export const DELETE_ZONE_IDLE = 'DELETE_ZONE_IDLE'

const deleteZoneRequest = () => {
  return {
    type: DELETE_ZONE_REQUEST
  }
}

const deleteZoneSuccess = (zoneId) => {
  return {
    type: DELETE_ZONE_SUCCESS,
    payload: {
      zoneId
    }
  }
}

const deleteZoneFailure = (error) => {
  return {
    type: DELETE_ZONE_FAILURE,
    payload: {
      error
    }
  }
}

const deleteZoneIdle = () => {
  return {
    type: DELETE_ZONE_IDLE
  }
}

export const deleteZone = (zoneId) => {
  return async (dispatch) => {
    dispatch(deleteZoneRequest())
    try {
      await del({
        url: `/${API_VERSION}/zones/${zoneId}`
      })
      dispatch(deleteZoneSuccess(zoneId))
    } catch (err) {
      dispatch(deleteZoneFailure(err))
    } finally {
      dispatch(deleteZoneIdle())
    }
  }
}
