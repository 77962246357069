import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import {
  LOAD_PROJECT_LIST_REQUEST,
  LOAD_PROJECT_LIST_SUCCESS,
  LOAD_PROJECT_LIST_FAILURE,
  LOAD_PROJECT_LIST_IDLE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_IDLE
} from '../actions'

const initialState = {
  listProject: [],
  loadProjectList: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  createProject: {
    fetchStatus: FETCH_STATUS_IDLE
  }
}

const projectPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROJECT_LIST_REQUEST: {
      return Object.assign({}, state, {
        loadProjectList: Object.assign({}, state.loadProjectList, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_PROJECT_LIST_SUCCESS: {
      return Object.assign({}, state, {
        listProject: action.payload.listProject,
        loadProjectList: Object.assign({}, state.loadProjectList, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }

    case LOAD_PROJECT_LIST_FAILURE: {
      return Object.assign({}, state, {
        loadProjectList: Object.assign({}, state.loadProjectList, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }

    case LOAD_PROJECT_LIST_IDLE: {
      return Object.assign({}, state, {
        loadProjectList: Object.assign({}, state.loadProjectList, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_PROJECT_REQUEST: {
      return Object.assign({}, state, {
        createProject: Object.assign({}, state.createProject, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case CREATE_PROJECT_SUCCESS: {
      const prepareNewProjectData = Object.assign({}, action.payload.projectDetail, {
        area_count: 0,
        camera_count: 0,
        site_count: 0,
        sites: []
      })
      return Object.assign({}, state, {
        listProject: [...state.listProject, prepareNewProjectData],
        createProject: Object.assign({}, state.createProject, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          createdProject: action.payload.projectDetail
        })
      })
    }

    case CREATE_PROJECT_FAILURE: {
      return Object.assign({}, state, {
        createProject: Object.assign({}, state.createProject, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }

    case CREATE_PROJECT_IDLE: {
      return Object.assign({}, state, {
        createProject: Object.assign({}, state.createProject, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    default: {
      return state
    }
  }
}

export default projectPageReducer
