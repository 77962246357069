/* eslint-disable no-undef */
import { get, ABORT_ERROR_NAME } from '../../../utils'
import queryString from 'query-string'

const API_VERSION = process.env.API_VERSION

export const LOAD_VIOLATION_REQUEST = 'LOAD_VIOLATION_REQUEST'
export const LOAD_VIOLATION_SUCCESS = 'LOAD_VIOLATION_SUCCESS'
export const LOAD_VIOLATION_FAILURE = 'LOAD_VIOLATION_FAILURE'
export const LOAD_VIOLATION_IDLE = 'LOAD_VIOLATION_IDLE'

const loadViolationRequest = () => {
  return {
    type: LOAD_VIOLATION_REQUEST
  }
}

const loadViolationSuccess = (cameraDatas, areaCount) => {
  return {
    type: LOAD_VIOLATION_SUCCESS,
    payload: {
      allCameraData: cameraDatas,
      areaCount
    }
  }
}

const loadViolationFailure = (error) => {
  return {
    type: LOAD_VIOLATION_FAILURE,
    payload: {
      error
    }
  }
}

const loadViolationIdle = () => {
  return {
    type: LOAD_VIOLATION_IDLE
  }
}

export const loadAllViolationDetail = (day, type, siteId, signal) => {
  return async (dispatch) => {
    dispatch(loadViolationRequest())
    try {
      const queryStr = queryString.stringify({ day, type })
      const areaDetailResp = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}/areas?${queryStr}`,
        signal
      })
      const allCameraDetailResponse = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}/cameras?${queryStr}`,
        signal
      })
      dispatch(loadViolationSuccess(allCameraDetailResponse, areaDetailResp.length))
      dispatch(loadViolationIdle())
    } catch (err) {
      if (err.name === ABORT_ERROR_NAME) {
        dispatch(loadViolationRequest())
      } else {
        dispatch(loadViolationFailure(err))
        dispatch(loadViolationIdle())
      }
    }
  }
}

export const SYNC_VIOLATION_REQUEST = 'SYNC_VIOLATION_REQUEST'
export const SYNC_VIOLATION_SUCCESS = 'SYNC_VIOLATION_SUCCESS'
export const SYNC_VIOLATION_FAILURE = 'SYNC_VIOLATION_FAILURE'
export const SYNC_VIOLATION_IDLE = 'SYNC_VIOLATION_IDLE'

const syncViolationRequest = () => {
  return {
    type: SYNC_VIOLATION_REQUEST
  }
}

const syncViolationSuccess = (cameraDatas, areaCount) => {
  return {
    type: SYNC_VIOLATION_SUCCESS,
    payload: {
      allCameraData: cameraDatas,
      areaCount
    }
  }
}

const syncViolationFailure = (error) => {
  return {
    type: SYNC_VIOLATION_FAILURE,
    payload: {
      error
    }
  }
}

const syncViolationIdle = () => {
  return {
    type: SYNC_VIOLATION_IDLE
  }
}

export const syncAllViolationDetail = (day, type, siteId) => {
  return async (dispatch) => {
    dispatch(syncViolationRequest())
    try {
      const queryStr = queryString.stringify({ day, type })
      const allCameraDetailResponse = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}/cameras?${queryStr}`
      })
      const areaDetailResp = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}/areas?${queryStr}`
      })
      dispatch(syncViolationSuccess(allCameraDetailResponse, areaDetailResp.length))
    } catch (err) {
      dispatch(syncViolationFailure(err))
    } finally {
      dispatch(syncViolationIdle())
    }
  }
}
