import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_IDLE,
  FETCH_USER_SSO_IDLE,
  FETCH_USER_SSO_REQUEST,
  FETCH_USER_SSO_SUCCESS,
  FETCH_USER_SSO_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_IDLE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  EDIT_USER_IDLE
} from '../actions'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

const initialState = {
  loginAPI: {
    fetchStatus: FETCH_STATUS_IDLE,
    userData: undefined
  },
  ssoLoginAPI: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  logoutAPI: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  loadEditUser: {
    fetchStatus: FETCH_STATUS_IDLE
  }
}

const loginPage = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return Object.assign({}, state, {
        loginAPI: Object.assign({}, state.loginAPI, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        loginAPI: Object.assign({}, state.loginAPI, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          userData: action.payload.userData
        })
      })
    }
    case LOGIN_FAILURE: {
      return Object.assign({}, state, {
        loginAPI: Object.assign({}, state.loginAPI, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case LOGIN_IDLE: {
      return Object.assign({}, state, {
        loginAPI: Object.assign({}, state.loginAPI, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }

    case FETCH_USER_SSO_REQUEST: {
      return Object.assign({}, state, {
        ssoLoginAPI: Object.assign({}, state.ssoLoginAPI, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case FETCH_USER_SSO_SUCCESS: {
      return Object.assign({}, state, {
        ssoLoginAPI: Object.assign({}, state.ssoLoginAPI, {
          fetchStatus: FETCH_STATUS_SUCCESS
        }),
        loginAPI: Object.assign({}, state.loginAPI, {
          userData: action.payload.userData
        })
      })
    }
    case FETCH_USER_SSO_FAILURE: {
      return Object.assign({}, state, {
        ssoLoginAPI: Object.assign({}, state.ssoLoginAPI, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case FETCH_USER_SSO_IDLE: {
      return Object.assign({}, state, {
        ssoLoginAPI: Object.assign({}, state.ssoLoginAPI, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }

    case LOGOUT_REQUEST: {
      return Object.assign({}, state, {
        logoutAPI: Object.assign({}, state.logoutAPI, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOGOUT_SUCCESS: {
      return Object.assign({}, state, {
        loginAPI: Object.assign({}, state.loginAPI, {
          userData: undefined
        }),
        logoutAPI: Object.assign({}, state.logoutAPI, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case LOGOUT_FAILURE: {
      return Object.assign({}, state, {
        logoutAPI: Object.assign({}, state.logoutAPI, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case LOGOUT_IDLE: {
      return Object.assign({}, state, {
        logoutAPI: Object.assign({}, state.logoutAPI, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case EDIT_USER_REQUEST: {
      return Object.assign({}, state, {
        loadEditUser: Object.assign({}, state.loadEditUser, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case EDIT_USER_SUCCESS: {
      return Object.assign({}, state, {
        loginAPI: Object.assign({}, state.loginAPI, {
          userData: action.payload.userData
        }),
        loadEditUser: Object.assign({}, state.loadEditUser, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case EDIT_USER_FAILURE: {
      return Object.assign({}, state, {
        loadEditUser: Object.assign({}, state.loadEditUser, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case EDIT_USER_IDLE: {
      return Object.assign({}, state, {
        loadEditUser: Object.assign({}, state.loadEditUser, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    default:
      return state
  }
}

export default loginPage
