/* eslint-disable no-case-declarations */
import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE, LIST_ALL_CLASS } from '../../../utils'

import {
  LOAD_CAMERA_ZONE_REQUEST,
  LOAD_CAMERA_ZONE_SUCCESS,
  LOAD_CAMERA_ZONE_FAILURE,
  LOAD_CAMERA_ZONE_IDLE,
  ADD_ZONE_REQUEST,
  ADD_ZONE_SUCCESS,
  ADD_ZONE_FAILURE,
  ADD_ZONE_IDLE,
  EDIT_ZONE_REQUEST,
  EDIT_ZONE_SUCCESS,
  EDIT_ZONE_FAILURE,
  EDIT_ZONE_IDLE,
  DELETE_ZONE_REQUEST,
  DELETE_ZONE_SUCCESS,
  DELETE_ZONE_FAILURE,
  DELETE_ZONE_IDLE
} from '../actions'

import { UPDATE_CAMERA_SOCKET } from '../../NotificationSidebar/actions'

import { DRAWING_ZONE_CONTAINER_HEIGHT, DRAWING_ZONE_CONTAINER_WIDTH } from '../index'

const initialState = {
  cameraZoneData: {
    area_id: 1,
    area_name: '',
    camera_id: 1,
    camera_status: 'OFFLINE',
    health_check_time: '',
    image: '',
    is_active: true,
    name: '',
    project_id: 1,
    resolution_width: 1920,
    resolution_height: 1080,
    site_id: 1,
    zones: []
  },
  listViolations: [],
  listClasses: [],
  listColorRanges: [],
  editZoneStatus: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  loadCameraZoneData: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  deleteCameraZoneData: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  loadZoneData: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  isRecalculatingZone: false
}

const sortZoneById = (zoneLists) => {
  const compare = (zone1, zone2) => {
    if (zone1.zone_id < zone2.zone_id) {
      return -1
    }
    if (zone1.zone_id > zone2.zone_id) {
      return 1
    }
    return 0
  }
  zoneLists.sort(compare)
}

const prepareObjectMinHeight = (zoneList) => {
  zoneList.forEach((zone) => {
    if (zone.object_size.length === 0) {
      const tempObjSize = LIST_ALL_CLASS.map((classObj) => {
        return {
          class_type: classObj,
          min_value: 0,
          position: { x: DRAWING_ZONE_CONTAINER_WIDTH / 2, y: DRAWING_ZONE_CONTAINER_HEIGHT / 2 }
        }
      })
      zone.object_size = tempObjSize
    } else {
      const tempObjSize2 = [...zone.object_size].map((eachObj) => {
        return { ...eachObj, min_value: eachObj.min_value / 0.8 }
      })
      zone.object_size = tempObjSize2
    }
  })
}

const eachCameraZonePage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMERA_ZONE_REQUEST:
      return Object.assign({}, state, {
        loadCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    case LOAD_CAMERA_ZONE_SUCCESS:
      sortZoneById(action.payload.cameraData.zones)
      prepareObjectMinHeight(action.payload.cameraData.zones)
      return Object.assign({}, state, {
        cameraZoneData: action.payload.cameraData,
        loadCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_SUCCESS
        }),
        listViolations: action.payload.listViolations,
        listClasses: action.payload.listClasses,
        listColorRanges: action.payload.listColorRanges
      })
    case LOAD_CAMERA_ZONE_FAILURE:
      return Object.assign({}, state, {
        loadCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    case LOAD_CAMERA_ZONE_IDLE:
      return Object.assign({}, state, {
        loadCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    case ADD_ZONE_REQUEST:
      return Object.assign({}, state, {
        loadCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    case ADD_ZONE_SUCCESS:
      let tempZones = [...state.cameraZoneData.zones]
      const tempAddedZone = Object.assign({}, action.payload.zoneData, {
        object_size: [...action.payload.zoneData.object_size].map((eachObj) => {
          return { ...eachObj, min_value: eachObj.min_value / 0.8 }
        })
      })
      tempZones.push(tempAddedZone)
      return Object.assign({}, state, {
        cameraZoneData: Object.assign({}, state.cameraZoneData, {
          zones: tempZones
        }),
        loadCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    case ADD_ZONE_FAILURE:
      return Object.assign({}, state, {
        loadCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    case ADD_ZONE_IDLE:
      return Object.assign({}, state, {
        loadCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })

    case EDIT_ZONE_REQUEST:
      return Object.assign({}, state, {
        editZoneStatus: Object.assign({}, state.editZoneStatus, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    case EDIT_ZONE_SUCCESS:
      let tempZones2 = [...state.cameraZoneData.zones]
      const editZoneId = tempZones2.findIndex((zone) => zone.zone_id === action.payload.zoneData.zone_id)
      const tempEditedZone = Object.assign({}, action.payload.zoneData, {
        object_size: [...action.payload.zoneData.object_size].map((eachObj) => {
          return { ...eachObj, min_value: eachObj.min_value / 0.8 }
        })
      })
      tempZones2[editZoneId] = tempEditedZone
      return Object.assign({}, state, {
        cameraZoneData: Object.assign({}, state.cameraZoneData, {
          zones: tempZones2
        }),
        editZoneStatus: Object.assign({}, state.editZoneStatus, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    case EDIT_ZONE_FAILURE:
      return Object.assign({}, state, {
        editZoneStatus: Object.assign({}, state.editZoneStatus, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    case EDIT_ZONE_IDLE:
      return Object.assign({}, state, {
        editZoneStatus: Object.assign({}, state.editZoneStatus, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    case DELETE_ZONE_REQUEST:
      return Object.assign({}, state, {
        deleteCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    case DELETE_ZONE_SUCCESS:
      const tempZone = state.cameraZoneData.zones.filter((zone) => zone.zone_id !== action.payload.zoneId)
      return Object.assign({}, state, {
        cameraZoneData: Object.assign({}, state.cameraZoneData, {
          zones: tempZone
        }),
        deleteCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    case DELETE_ZONE_FAILURE:
      return Object.assign({}, state, {
        deleteCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    case DELETE_ZONE_IDLE:
      return Object.assign({}, state, {
        deleteCameraZoneData: Object.assign({}, state.loadCameraZoneData, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    case UPDATE_CAMERA_SOCKET: {
      const { upComingCameraData } = action.payload
      return Object.assign({}, state, {
        cameraZoneData: Object.assign({}, state.cameraZoneData, {
          image: upComingCameraData.notification_object.camera_image
            ? upComingCameraData.notification_object.camera_image
            : state.cameraZoneData.image
        }),
        isRecalculatingZone: upComingCameraData.notification_object.calculating
      })
    }
    default:
      return state
  }
}

export default eachCameraZonePage
