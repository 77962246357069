import { get, del, ABORT_ERROR_NAME, patch } from '../../../utils'
import queryString from 'query-string'
const API_VERSION = process.env.API_VERSION

export const LOAD_EACH_VIOLATION_REQUEST = 'LOAD_EACH_VIOLATION_REQUEST'
export const LOAD_EACH_VIOLATION_SUCCESS = 'LOAD_EACH_VIOLATION_SUCCESS'
export const LOAD_EACH_VIOLATION_FAILURE = 'LOAD_EACH_VIOLATION_FAILURE'
export const LOAD_EACH_VIOLATION_IDLE = 'LOAD_EACH_VIOLATION_IDLE'
const loadEachViolationRequest = () => {
  return {
    type: LOAD_EACH_VIOLATION_REQUEST
  }
}

const loadEachViolationSuccess = (cameraDetail, cameraViolationCases, allPaginationNum, areaCount, siteDetail) => {
  return {
    type: LOAD_EACH_VIOLATION_SUCCESS,
    payload: {
      cameraDetail,
      cameraViolationCases,
      allPaginationNum,
      areaCount,
      siteDetail
    }
  }
}

const loadEachViolationFailure = (error) => {
  return {
    type: LOAD_EACH_VIOLATION_FAILURE,
    payload: {
      error
    }
  }
}

const LoadEachViolationIdle = () => {
  return {
    type: LOAD_EACH_VIOLATION_IDLE
  }
}

export const loadEachViolation = (cameraId, siteId, date, dateFilterType, order, pageNumber, signal, reported) => {
  return async (dispatch) => {
    dispatch(loadEachViolationRequest())
    try {
      const queryStrForCamera = queryString.stringify({
        day: date,
        type: dateFilterType,
        reported: reported
      })
      const queryStrForViolationCase = queryString.stringify({
        order: order,
        type: dateFilterType,
        day: date,
        page: pageNumber,
        reported: reported
      })
      const cameraDataResponse = await get({
        url: `/${API_VERSION}/graphs/cameras/${cameraId}?${queryStrForCamera}`,
        signal
      })
      const cameraViolationCaseResponse = await get({
        url: `/${API_VERSION}/graphs/violations/${cameraId}?${queryStrForViolationCase}`,
        signal
      })
      const allViolationPaginates = await get({
        url: `/${API_VERSION}/graphs/pages/${cameraId}?${queryStrForCamera}`,
        signal
      })
      const areaDetailResponse = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}/areas?${queryStrForCamera}`,
        signal
      })
      const siteDetail = await get({
        url: `/${API_VERSION}/sites/${siteId}`,
        signal
      })
      dispatch(
        loadEachViolationSuccess(
          cameraDataResponse,
          cameraViolationCaseResponse,
          allViolationPaginates.number_of_pages,
          areaDetailResponse.length,
          siteDetail
        )
      )
      dispatch(LoadEachViolationIdle())
    } catch (err) {
      if (err.name === ABORT_ERROR_NAME) {
        dispatch(loadEachViolationRequest())
      } else {
        dispatch(loadEachViolationFailure(err))
        dispatch(LoadEachViolationIdle())
      }
    }
  }
}

export const SYNC_EACH_VIOLATION_REQUEST = 'SYNC_EACH_VIOLATION_REQUEST'
export const SYNC_EACH_VIOLATION_SUCCESS = 'SYNC_EACH_VIOLATION_SUCCESS'
export const SYNC_EACH_VIOLATION_FAILURE = 'SYNC_EACH_VIOLATION_FAILURE'
export const SYNC_EACH_VIOLATION_IDLE = 'SYNC_EACH_VIOLATION_IDLE'

const syncEachViolationRequest = () => {
  return {
    type: SYNC_EACH_VIOLATION_REQUEST
  }
}

const syncEachViolationSuccess = (cameraDetail, cameraViolationCases, allPaginationNum, areaCount, siteDetail) => {
  return {
    type: SYNC_EACH_VIOLATION_SUCCESS,
    payload: {
      cameraDetail,
      cameraViolationCases,
      allPaginationNum,
      areaCount,
      siteDetail
    }
  }
}

const syncEachViolationFailure = (error) => {
  return {
    type: SYNC_EACH_VIOLATION_FAILURE,
    payload: {
      error
    }
  }
}

const syncEachViolationIdle = () => {
  return {
    type: SYNC_EACH_VIOLATION_IDLE
  }
}

export const syncEachViolation = (cameraId, siteId, date, dateFilterType, order, pageNumber, reported) => {
  return async (dispatch) => {
    dispatch(syncEachViolationRequest())
    try {
      const queryStrForCamera = queryString.stringify({
        day: date,
        type: dateFilterType,
        reported: reported
      })

      const queryStrForViolationCase = queryString.stringify({
        order: order,
        type: dateFilterType,
        day: date,
        page: pageNumber,
        reported: reported
      })
      const cameraDataResponse = await get({
        url: `/${API_VERSION}/graphs/cameras/${cameraId}?${queryStrForCamera}`
      })
      const cameraViolationCaseResponse = await get({
        url: `/${API_VERSION}/graphs/violations/${cameraId}?${queryStrForViolationCase}`
      })

      const allViolationPaginates = await get({
        url: `/${API_VERSION}/graphs/pages/${cameraId}?${queryStrForCamera}`
      })

      const areaDetailResponse = await get({
        url: `/${API_VERSION}/graphs/sites/${siteId}/areas?${queryStrForCamera}`
      })
      const siteDetail = await get({
        url: `/${API_VERSION}/sites/${siteId}`
      })
      dispatch(
        syncEachViolationSuccess(
          cameraDataResponse,
          cameraViolationCaseResponse,
          allViolationPaginates.number_of_pages,
          areaDetailResponse.length,
          siteDetail
        )
      )
    } catch (err) {
      dispatch(syncEachViolationFailure(err))
    } finally {
      dispatch(syncEachViolationIdle())
    }
  }
}

export const DELETE_VIOLATION_REQUEST = 'DELETE_VIOLATION_REQUEST'
export const DELETE_VIOLATION_SUCCESS = 'DELETE_VIOLATION_SUCCESS'
export const DELETE_VIOLATION_FAILURE = 'DELETE_VIOLATION_FAILURE'
export const DELETE_VIOLATION_IDLE = 'DELETE_VIOLATION_IDLE'

const deleteViolationRequest = (violationId) => {
  return {
    type: DELETE_VIOLATION_REQUEST,
    payload: {
      violationId
    }
  }
}

const deleteViolationSuccess = (violationId) => {
  return {
    type: DELETE_VIOLATION_SUCCESS,
    payload: {
      violationId
    }
  }
}

const deleteViolationFailure = (error) => {
  return {
    type: DELETE_VIOLATION_FAILURE,
    payload: {
      error
    }
  }
}

const deleteViolationIdle = () => {
  return {
    type: DELETE_VIOLATION_IDLE
  }
}

export const deleteViolation = (violationId) => {
  return async (dispatch) => {
    dispatch(deleteViolationRequest(violationId))
    try {
      await del({
        url: `/${API_VERSION}/zone_violations/${violationId}`
      })
      dispatch(deleteViolationSuccess(violationId))
    } catch (err) {
      dispatch(deleteViolationFailure(err))
    } finally {
      dispatch(deleteViolationIdle())
    }
  }
}

export const REPORT_VIOLATION_REQUEST = 'REPORT_VIOLATION_REQUEST'
export const REPORT_VIOLATION_SUCCESS = 'REPORT_VIOLATION_SUCCESS'
export const REPORT_VIOLATION_FAILURE = 'REPORT_VIOLATION_FAILURE'
export const REPORT_VIOLATION_IDLE = 'REPORT_VIOLATION_IDLE'

const reportViolationRequest = (violationId) => {
  return {
    type: REPORT_VIOLATION_REQUEST,
    payload: {
      violationId
    }
  }
}

const reportViolationSuccess = (violationData) => {
  return {
    type: REPORT_VIOLATION_SUCCESS,
    payload: {
      violationData
    }
  }
}

const reportViolationFailure = (error, reported) => {
  return {
    type: REPORT_VIOLATION_FAILURE,
    payload: {
      error,
      reported
    }
  }
}

const reportViolationIdle = () => {
  return {
    type: REPORT_VIOLATION_IDLE
  }
}
export const reportViolation = (violationId, reported) => {
  return async (dispatch) => {
    dispatch(reportViolationRequest(violationId))
    try {
      const data = {
        zone_violation: {
          reported: reported
        }
      }
      const response = await patch({
        url: `/${API_VERSION}/zone_violations/${violationId}`,
        payload: data
      })
      dispatch(reportViolationSuccess(response))
    } catch (err) {
      dispatch(reportViolationFailure(err, reported))
    } finally {
      dispatch(reportViolationIdle())
    }
  }
}
