import { get, post } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const LOAD_PROJECT_LIST_REQUEST = 'LOAD_PROJECT_LIST_REQUEST'
export const LOAD_PROJECT_LIST_SUCCESS = 'LOAD_PROJECT_LIST_SUCCESS'
export const LOAD_PROJECT_LIST_FAILURE = 'LOAD_PROJECT_LIST_FAILURE'
export const LOAD_PROJECT_LIST_IDLE = 'LOAD_PROJECT_LIST_IDLE'

const loadProjectListRequest = () => {
  return {
    type: LOAD_PROJECT_LIST_REQUEST
  }
}

const loadProjectListSuccess = (listProject) => {
  return {
    type: LOAD_PROJECT_LIST_SUCCESS,
    payload: {
      listProject
    }
  }
}

const loadProjectListFailure = (error) => {
  return {
    type: LOAD_PROJECT_LIST_FAILURE,
    payload: {
      error
    }
  }
}

const loadProjectListIdle = () => {
  return {
    type: LOAD_PROJECT_LIST_IDLE
  }
}

export const loadProjectList = () => {
  return async (dispatch) => {
    dispatch(loadProjectListRequest())
    try {
      const listProject = await get({
        url: `/${API_VERSION}/projects`
      })
      dispatch(loadProjectListSuccess(listProject))
    } catch (err) {
      dispatch(loadProjectListFailure(err))
    } finally {
      dispatch(loadProjectListIdle())
    }
  }
}

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE'
export const CREATE_PROJECT_IDLE = 'CREATE_PROJECT_IDLE'

const createProjectRequest = () => {
  return {
    type: CREATE_PROJECT_REQUEST
  }
}

const createProjectSuccess = (projectDetail) => {
  return {
    type: CREATE_PROJECT_SUCCESS,
    payload: {
      projectDetail
    }
  }
}

const createProjectFailure = (error) => {
  return {
    type: CREATE_PROJECT_FAILURE,
    payload: {
      error
    }
  }
}

const createProjectIdle = () => {
  return {
    type: CREATE_PROJECT_IDLE
  }
}

export const createProject = (data) => {
  return async (dispatch) => {
    dispatch(createProjectRequest())
    try {
      const response = await post({
        url: `/${API_VERSION}/projects`,
        payload: data
      })
      dispatch(createProjectSuccess(response))
    } catch (err) {
      dispatch(createProjectFailure(err))
    } finally {
      dispatch(createProjectIdle())
    }
  }
}
