import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import {
  LOAD_PROJECT_USER_LIST_REQUEST,
  LOAD_PROJECT_USER_LIST_SUCCESS,
  LOAD_PROJECT_USER_LIST_FAILURE,
  LOAD_PROJECT_USER_LIST_IDLE,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAILURE,
  CREATE_NEW_USER_IDLE,
  CREATE_NEW_MANAGER_REQUEST,
  CREATE_NEW_MANAGER_SUCCESS,
  CREATE_NEW_MANAGER_FAILURE,
  CREATE_NEW_MANAGER_IDLE,
  CREATE_NEW_ADMIN_REQUEST,
  CREATE_NEW_ADMIN_SUCCESS,
  CREATE_NEW_ADMIN_FAILURE,
  CREATE_NEW_ADMIN_IDLE,
  UPDATE_USER_ACCOUNT_REQUEST,
  UPDATE_USER_ACCOUNT_SUCCESS,
  UPDATE_USER_ACCOUNT_FAILURE,
  UPDATE_USER_ACCOUNT_IDLE,
  DELETE_USER_ACCOUNT_REQUEST,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAILURE,
  DELETE_USER_ACCOUNT_IDLE
} from '../actions'

const initialState = {
  listProject: [],
  listAdmin: [],
  listManager: [],
  listUser: [],
  loadProjectUserList: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  createNewUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    userData: undefined
  },
  createNewAdmin: {
    fetchStatus: FETCH_STATUS_IDLE,
    userData: undefined
  },
  createNewManager: {
    fetchStatus: FETCH_STATUS_IDLE,
    userData: undefined
  },
  updateUserAccount: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  deleteUserAccount: {
    fetchStatus: FETCH_STATUS_IDLE
  }
}

const userManagementPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROJECT_USER_LIST_REQUEST: {
      return Object.assign({}, state, {
        loadProjectUserList: Object.assign({}, state.loadProjectUserList, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_PROJECT_USER_LIST_SUCCESS: {
      const listAdmin = action.payload.listAccount.filter((userData) => userData.admin)
      const listManagerWithControlProject = action.payload.listAccount
        .filter((userData) => userData.manager)
        .map((userData) => {
          const projects = action.payload.listProject.filter((projectData) => {
            const found = projectData.managers.find((managerData) => managerData.id === userData.id)
            return !!found
          })
          return Object.assign({}, userData, { projects })
        })
      const listUser = action.payload.listAccount.filter((userData) => !userData.admin && !userData.manager)
      const listProject = action.payload.listProject.map((projectData) => {
        const managers = projectData.managers.map((obj) => {
          const managerWithProjectData = listManagerWithControlProject.find((managerData) => managerData.id === obj.id)
          return managerWithProjectData
        })
        return Object.assign({}, projectData, { managers })
      })
      return Object.assign({}, state, {
        listProject,
        listAdmin,
        listManager: listManagerWithControlProject,
        listUser,
        loadProjectUserList: Object.assign({}, state.loadProjectUserList, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }

    case LOAD_PROJECT_USER_LIST_FAILURE: {
      return Object.assign({}, state, {
        loadProjectUserList: Object.assign({}, state.loadProjectUserList, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }

    case LOAD_PROJECT_USER_LIST_IDLE: {
      return Object.assign({}, state, {
        loadProjectUserList: Object.assign({}, state.loadProjectUserList, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_NEW_USER_REQUEST: {
      return Object.assign({}, state, {
        createNewUser: Object.assign({}, state.createNewUser, {
          fetchStatus: FETCH_STATUS_REQUEST,
          userData: undefined
        })
      })
    }
    case CREATE_NEW_USER_SUCCESS: {
      return Object.assign({}, state, {
        createNewUser: Object.assign({}, state.createNewUser, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          userData: action.payload.userData
        })
      })
    }

    case CREATE_NEW_USER_FAILURE: {
      return Object.assign({}, state, {
        createNewUser: Object.assign({}, state.createNewUser, {
          error: action.payload.error,
          fetchStatus: FETCH_STATUS_FAILURE
        })
      })
    }

    case CREATE_NEW_USER_IDLE: {
      return Object.assign({}, state, {
        createNewUser: Object.assign({}, state.createNewUser, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_NEW_ADMIN_REQUEST: {
      return Object.assign({}, state, {
        createNewAdmin: Object.assign({}, state.createNewAdmin, {
          fetchStatus: FETCH_STATUS_REQUEST,
          userData: undefined
        })
      })
    }
    case CREATE_NEW_ADMIN_SUCCESS: {
      return Object.assign({}, state, {
        createNewAdmin: Object.assign({}, state.createNewAdmin, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          userData: action.payload.userData
        })
      })
    }
    case CREATE_NEW_ADMIN_FAILURE: {
      return Object.assign({}, state, {
        createNewAdmin: Object.assign({}, state.createNewAdmin, {
          error: action.payload.error,
          fetchStatus: FETCH_STATUS_FAILURE
        })
      })
    }
    case CREATE_NEW_ADMIN_IDLE: {
      return Object.assign({}, state, {
        createNewAdmin: Object.assign({}, state.createNewAdmin, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_NEW_MANAGER_REQUEST: {
      return Object.assign({}, state, {
        createNewManager: Object.assign({}, state.createNewManager, {
          fetchStatus: FETCH_STATUS_REQUEST,
          userData: undefined
        })
      })
    }
    case CREATE_NEW_MANAGER_SUCCESS: {
      return Object.assign({}, state, {
        createNewManager: Object.assign({}, state.createNewManager, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          userData: action.payload.userData
        })
      })
    }
    case CREATE_NEW_MANAGER_FAILURE: {
      return Object.assign({}, state, {
        createNewManager: Object.assign({}, state.createNewManager, {
          error: action.payload.error,
          fetchStatus: FETCH_STATUS_FAILURE
        })
      })
    }
    case CREATE_NEW_MANAGER_IDLE: {
      return Object.assign({}, state, {
        createNewManager: Object.assign({}, state.createNewManager, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_USER_ACCOUNT_REQUEST: {
      return Object.assign({}, state, {
        updateUserAccount: Object.assign({}, state.updateUserAccount, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case UPDATE_USER_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        updateUserAccount: Object.assign({}, state.updateUserAccount, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }

    case UPDATE_USER_ACCOUNT_FAILURE: {
      return Object.assign({}, state, {
        updateUserAccount: Object.assign({}, state.updateUserAccount, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }

    case UPDATE_USER_ACCOUNT_IDLE: {
      return Object.assign({}, state, {
        updateUserAccount: Object.assign({}, state.updateUserAccount, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case DELETE_USER_ACCOUNT_REQUEST: {
      return Object.assign({}, state, {
        deleteUserAccount: Object.assign({}, state.deleteUserAccount, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case DELETE_USER_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        deleteUserAccount: Object.assign({}, state.deleteUserAccount, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }

    case DELETE_USER_ACCOUNT_FAILURE: {
      return Object.assign({}, state, {
        deleteUserAccount: Object.assign({}, state.deleteUserAccount, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }

    case DELETE_USER_ACCOUNT_IDLE: {
      return Object.assign({}, state, {
        deleteUserAccount: Object.assign({}, state.deleteUserAccount, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    default: {
      return state
    }
  }
}

export default userManagementPage
