import React from 'react'
import PropTypes from 'prop-types'
import SkeletonLoading from '../../components/SkeletonLoading/Loadable'
import { fetchImageBase64 } from '../../utils'

const withScreenshotBase64 = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        base64Image: undefined,
        isLoading: false
      }
      this.isUnmounted = false
    }

    componentDidMount() {
      this.prepareImageSrc()
    }

    componentDidUpdate(prevProps) {
      if (prevProps.imgSrc !== this.props.imgSrc) {
        this.prepareImageSrc()
      }
    }

    componentWillUnmount() {
      this.isUnmounted = true
    }

    async prepareImageSrc() {
      try {
        if (this.props.imgSrc) {
          this.setState({ isLoading: true })
          const base64Image = await fetchImageBase64(this.props.imgSrc)
          !this.isUnmounted && this.setState({ base64Image })
        }
      } catch (error) {
        !this.isUnmounted && this.setState({ base64Image: undefined })
        this.props.onLinkImageInvalid && this.props.onLinkImageInvalid()
      } finally {
        !this.isUnmounted && this.setState({ isLoading: false })
      }
    }

    render() {
      if (this.state.isLoading) {
        return <SkeletonLoading />
      } else {
        return <WrappedComponent {...this.props} imgSrc={this.state.base64Image} />
      }
    }
  }
  HOC.propTypes = {
    imgSrc: PropTypes.string,
    onLinkImageInvalid: PropTypes.func
  }
  return HOC
}

export default withScreenshotBase64
