import { get, patch, post, del } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const LOAD_PROJECT_DETAIL_REQUEST = 'LOAD_PROJECT_DETAIL_REQUEST'
export const LOAD_PROJECT_DETAIL_SUCCESS = 'LOAD_PROJECT_DETAIL_SUCCESS'
export const LOAD_PROJECT_DETAIL_FAILURE = 'LOAD_PROJECT_DETAIL_FAILURE'
export const LOAD_PROJECT_DETAIL_IDLE = 'LOAD_PROJECT_DETAIL_IDLE'

const loadProjectDetailRequest = () => {
  return {
    type: LOAD_PROJECT_DETAIL_REQUEST
  }
}

const loadProjectDetailSuccess = (projectDetail) => {
  return {
    type: LOAD_PROJECT_DETAIL_SUCCESS,
    payload: {
      projectDetail
    }
  }
}

const loadProjectDetailFailure = (error) => {
  return {
    type: LOAD_PROJECT_DETAIL_FAILURE,
    payload: {
      error
    }
  }
}

const loadProjectDetailIdle = () => {
  return {
    type: LOAD_PROJECT_DETAIL_IDLE
  }
}

export const loadProjectDetail = (projectID) => {
  return async (dispatch) => {
    dispatch(loadProjectDetailRequest())
    try {
      const projectDetail = await get({
        url: `/${API_VERSION}/projects/${projectID}`
      })
      dispatch(loadProjectDetailSuccess(projectDetail))
    } catch (err) {
      dispatch(loadProjectDetailFailure(err))
    } finally {
      dispatch(loadProjectDetailIdle())
    }
  }
}

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE'
export const UPDATE_PROJECT_IDLE = 'UPDATE_PROJECT_IDLE'

const updateProjectDetailRequest = () => {
  return {
    type: UPDATE_PROJECT_REQUEST
  }
}

const updateProjectDetailSuccess = (projectDetail) => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    payload: {
      projectDetail
    }
  }
}

const updateProjectDetailFailure = (error) => {
  return {
    type: UPDATE_PROJECT_FAILURE,
    payload: {
      error
    }
  }
}

const updateProjectDetailIdle = () => {
  return {
    type: UPDATE_PROJECT_IDLE
  }
}

export const updateProjectDetail = (projectID, data) => {
  return async (dispatch) => {
    dispatch(updateProjectDetailRequest())
    try {
      const response = await patch({
        url: `/${API_VERSION}/projects/${projectID}`,
        payload: data
      })
      dispatch(updateProjectDetailSuccess(response))
    } catch (err) {
      dispatch(updateProjectDetailFailure(err))
    } finally {
      dispatch(updateProjectDetailIdle())
    }
  }
}

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE'
export const DELETE_PROJECT_IDLE = 'DELETE_PROJECT_IDLE'

const deleteProjectDetailRequest = () => {
  return {
    type: DELETE_PROJECT_REQUEST
  }
}

const deleteProjectDetailSuccess = (projectDetail) => {
  return {
    type: DELETE_PROJECT_SUCCESS
  }
}

const deleteProjectDetailFailure = (error) => {
  return {
    type: DELETE_PROJECT_FAILURE,
    payload: {
      error
    }
  }
}

const deleteProjectDetailIdle = () => {
  return {
    type: DELETE_PROJECT_IDLE
  }
}

export const deleteProjectDetail = (projectID) => {
  return async (dispatch) => {
    dispatch(deleteProjectDetailRequest())
    try {
      await del({
        url: `/${API_VERSION}/projects/${projectID}`
      })
      dispatch(deleteProjectDetailSuccess())
    } catch (err) {
      dispatch(deleteProjectDetailFailure(err))
    } finally {
      dispatch(deleteProjectDetailIdle())
    }
  }
}

export const LOAD_LIST_SITE_REQUEST = 'LOAD_LIST_SITE_REQUEST'
export const LOAD_LIST_SITE_SUCCESS = 'LOAD_LIST_SITE_SUCCESS'
export const LOAD_LIST_SITE_FAILURE = 'LOAD_LIST_SITE_FAILURE'
export const LOAD_LIST_SITE_IDLE = 'LOAD_LIST_SITE_IDLE'

const loadListSiteRequest = () => {
  return {
    type: LOAD_LIST_SITE_REQUEST
  }
}

const loadListSiteSuccess = (listSite) => {
  return {
    type: LOAD_LIST_SITE_SUCCESS,
    payload: {
      listSite
    }
  }
}

const loadListSiteFailure = (error) => {
  return {
    type: LOAD_LIST_SITE_FAILURE,
    payload: {
      error
    }
  }
}

const loadListSiteIdle = () => {
  return {
    type: LOAD_LIST_SITE_IDLE
  }
}

export const loadListSite = () => {
  return async (dispatch) => {
    dispatch(loadListSiteRequest())
    try {
      const response = await get({
        url: `/${API_VERSION}/sites?include=all`
      })
      dispatch(loadListSiteSuccess(response))
    } catch (err) {
      dispatch(loadListSiteFailure(err))
    } finally {
      dispatch(loadListSiteIdle())
    }
  }
}

export const CREATE_SITE_REQUEST = 'CREATE_SITE_REQUEST'
export const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS'
export const CREATE_SITE_FAILURE = 'CREATE_SITE_FAILURE'
export const CREATE_SITE_IDLE = 'CREATE_SITE_IDLE'

const createSiteRequest = () => {
  return {
    type: CREATE_SITE_REQUEST
  }
}

const createSiteSuccess = (siteData) => {
  return {
    type: CREATE_SITE_SUCCESS,
    payload: {
      siteData
    }
  }
}

const createSiteFailure = (error) => {
  return {
    type: CREATE_SITE_FAILURE,
    payload: {
      error
    }
  }
}

const createSiteIdle = () => {
  return {
    type: CREATE_SITE_IDLE
  }
}

export const createSite = (data) => {
  return async (dispatch) => {
    dispatch(createSiteRequest())
    try {
      const response = await post({
        url: `/${API_VERSION}/sites`,
        payload: data
      })
      dispatch(createSiteSuccess(response))
    } catch (err) {
      dispatch(createSiteFailure(err))
    } finally {
      dispatch(createSiteIdle())
    }
  }
}

export const UPDATE_SITE_REQUEST = 'UPDATE_SITE_REQUEST'
export const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS'
export const UPDATE_SITE_FAILURE = 'UPDATE_SITE_FAILURE'
export const UPDATE_SITE_IDLE = 'UPDATE_SITE_IDLE'

const updateSiteRequest = () => {
  return {
    type: UPDATE_SITE_REQUEST
  }
}

const updateSiteSuccess = (siteData) => {
  return {
    type: UPDATE_SITE_SUCCESS,
    payload: {
      siteData
    }
  }
}

const updateSiteFailure = (error) => {
  return {
    type: UPDATE_SITE_FAILURE,
    payload: {
      error
    }
  }
}

const updateSiteIdle = () => {
  return {
    type: UPDATE_SITE_IDLE
  }
}

export const updateSite = (data, siteID) => {
  return async (dispatch) => {
    dispatch(updateSiteRequest())
    try {
      const response = await patch({
        url: `/${API_VERSION}/sites/${siteID}`,
        payload: data
      })
      dispatch(updateSiteSuccess(response))
    } catch (err) {
      dispatch(updateSiteFailure(err))
    } finally {
      dispatch(updateSiteIdle())
    }
  }
}

export const DELETE_SITE_REQUEST = 'DELETE_SITE_REQUEST'
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS'
export const DELETE_SITE_FAILURE = 'DELETE_SITE_FAILURE'
export const DELETE_SITE_IDLE = 'DELETE_SITE_IDLE'

const deleteSiteRequest = () => {
  return {
    type: DELETE_SITE_REQUEST
  }
}

const deleteSiteSuccess = (siteID) => {
  return {
    type: DELETE_SITE_SUCCESS,
    payload: {
      siteID
    }
  }
}

const deleteSiteFailure = (error) => {
  return {
    type: DELETE_SITE_FAILURE,
    payload: {
      error
    }
  }
}

const deleteSiteIdle = () => {
  return {
    type: DELETE_SITE_IDLE
  }
}

export const deleteSite = (siteID) => {
  return async (dispatch) => {
    dispatch(deleteSiteRequest())
    try {
      await del({
        url: `/${API_VERSION}/sites/${siteID}`
      })
      dispatch(deleteSiteSuccess(siteID))
    } catch (err) {
      dispatch(deleteSiteFailure(err))
    } finally {
      dispatch(deleteSiteIdle())
    }
  }
}

export const CREATE_AREA_REQUEST = 'CREATE_AREA_REQUEST'
export const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS'
export const CREATE_AREA_FAILURE = 'CREATE_AREA_FAILURE'
export const CREATE_AREA_IDLE = 'CREATE_AREA_IDLE'

const createAreaRequest = () => {
  return {
    type: CREATE_AREA_REQUEST
  }
}

const createAreaSuccess = (areaData, siteID) => {
  return {
    type: CREATE_AREA_SUCCESS,
    payload: {
      areaData,
      siteID
    }
  }
}

const createAreaFailure = (error) => {
  return {
    type: CREATE_AREA_FAILURE,
    payload: {
      error
    }
  }
}

const createAreaIdle = () => {
  return {
    type: CREATE_AREA_IDLE
  }
}

export const createArea = (data, siteID) => {
  return async (dispatch) => {
    dispatch(createAreaRequest())
    try {
      const response = await post({
        url: `/${API_VERSION}/areas`,
        payload: data
      })
      dispatch(createAreaSuccess(response, siteID))
    } catch (err) {
      dispatch(createAreaFailure(err))
    } finally {
      dispatch(createAreaIdle())
    }
  }
}

export const UPDATE_AREA_REQUEST = 'UPDATE_AREA_REQUEST'
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS'
export const UPDATE_AREA_FAILURE = 'UPDATE_AREA_FAILURE'
export const UPDATE_AREA_IDLE = 'UPDATE_AREA_IDLE'

const updateAreaRequest = () => {
  return {
    type: UPDATE_AREA_REQUEST
  }
}

const updateAreaSuccess = (areaData, siteID) => {
  return {
    type: UPDATE_AREA_SUCCESS,
    payload: {
      areaData,
      siteID
    }
  }
}

const updateAreaFailure = (error) => {
  return {
    type: UPDATE_AREA_FAILURE,
    payload: {
      error
    }
  }
}

const updateAreaIdle = () => {
  return {
    type: UPDATE_AREA_IDLE
  }
}

export const updateArea = (data, areaID, siteID) => {
  return async (dispatch) => {
    dispatch(updateAreaRequest())
    try {
      const response = await patch({
        url: `/${API_VERSION}/areas/${areaID}`,
        payload: data
      })
      dispatch(updateAreaSuccess(response, siteID))
    } catch (err) {
      dispatch(updateAreaFailure(err))
    } finally {
      dispatch(updateAreaIdle())
    }
  }
}

export const DELETE_AREA_REQUEST = 'DELETE_AREA_REQUEST'
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS'
export const DELETE_AREA_FAILURE = 'DELETE_AREA_FAILURE'
export const DELETE_AREA_IDLE = 'DELETE_AREA_IDLE'

const deleteAreaRequest = () => {
  return {
    type: DELETE_AREA_REQUEST
  }
}

const deleteAreaSuccess = (areaID, siteID) => {
  return {
    type: DELETE_AREA_SUCCESS,
    payload: {
      siteID,
      areaID
    }
  }
}

const deleteAreaFailure = (error) => {
  return {
    type: DELETE_AREA_FAILURE,
    payload: {
      error
    }
  }
}

const deleteAreaIdle = () => {
  return {
    type: DELETE_AREA_IDLE
  }
}

export const deleteArea = (areaID, siteID) => {
  return async (dispatch) => {
    dispatch(deleteAreaRequest())
    try {
      await del({
        url: `/${API_VERSION}/areas/${areaID}`
      })
      dispatch(deleteAreaSuccess(areaID, siteID))
    } catch (err) {
      dispatch(deleteAreaFailure(err))
    } finally {
      dispatch(deleteAreaIdle())
    }
  }
}

export const CREATE_CAMERA_REQUEST = 'CREATE_CAMERA_REQUEST'
export const CREATE_CAMERA_SUCCESS = 'CREATE_CAMERA_SUCCESS'
export const CREATE_CAMERA_FAILURE = 'CREATE_CAMERA_FAILURE'
export const CREATE_CAMERA_IDLE = 'CREATE_CAMERA_IDLE'

const createCameraRequest = () => {
  return {
    type: CREATE_CAMERA_REQUEST
  }
}

const createCameraSuccess = (cameraData) => {
  return {
    type: CREATE_CAMERA_SUCCESS,
    payload: {
      cameraData
    }
  }
}

const createCameraFailure = (error) => {
  return {
    type: CREATE_CAMERA_FAILURE,
    payload: {
      error
    }
  }
}

const createCameraIdle = () => {
  return {
    type: CREATE_CAMERA_IDLE
  }
}

export const createCamera = (data) => {
  return async (dispatch) => {
    dispatch(createCameraRequest())
    try {
      const response = await post({
        url: `/${API_VERSION}/cameras`,
        payload: data
      })
      dispatch(createCameraSuccess(response))
    } catch (err) {
      dispatch(createCameraFailure(err))
    } finally {
      dispatch(createCameraIdle())
    }
  }
}

export const UPDATE_CAMERA_REQUEST = 'UPDATE_CAMERA_REQUEST'
export const UPDATE_CAMERA_SUCCESS = 'UPDATE_CAMERA_SUCCESS'
export const UPDATE_CAMERA_FAILURE = 'UPDATE_CAMERA_FAILURE'
export const UPDATE_CAMERA_IDLE = 'UPDATE_CAMERA_IDLE'

const updateCameraRequest = () => {
  return {
    type: UPDATE_CAMERA_REQUEST
  }
}

const updateCameraSuccess = (cameraData) => {
  return {
    type: UPDATE_CAMERA_SUCCESS,
    payload: {
      cameraData
    }
  }
}

const updateCameraFailure = (error) => {
  return {
    type: UPDATE_CAMERA_FAILURE,
    payload: {
      error
    }
  }
}

const updateCameraIdle = () => {
  return {
    type: UPDATE_CAMERA_IDLE
  }
}

export const updateCamera = (data, cameraID) => {
  return async (dispatch) => {
    dispatch(updateCameraRequest())
    try {
      const response = await patch({
        url: `/${API_VERSION}/cameras/${cameraID}`,
        payload: data
      })
      dispatch(updateCameraSuccess(response))
    } catch (err) {
      dispatch(updateCameraFailure(err))
    } finally {
      dispatch(updateCameraIdle())
    }
  }
}

export const DELETE_CAMERA_REQUEST = 'DELETE_CAMERA_REQUEST'
export const DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS'
export const DELETE_CAMERA_FAILURE = 'DELETE_CAMERA_FAILURE'
export const DELETE_CAMERA_IDLE = 'DELETE_CAMERA_IDLE'

const deleteCameraRequest = () => {
  return {
    type: DELETE_CAMERA_REQUEST
  }
}

const deleteCameraSuccess = (cameraID, areaID, siteID) => {
  return {
    type: DELETE_CAMERA_SUCCESS,
    payload: {
      cameraID,
      areaID,
      siteID
    }
  }
}

const deleteCameraFailure = (error) => {
  return {
    type: DELETE_CAMERA_FAILURE,
    payload: {
      error
    }
  }
}

const deleteCameraIdle = () => {
  return {
    type: DELETE_CAMERA_IDLE
  }
}

export const deleteCamera = (cameraID, areaID, siteID) => {
  return async (dispatch) => {
    dispatch(deleteCameraRequest())
    try {
      await del({
        url: `/${API_VERSION}/cameras/${cameraID}`
      })
      dispatch(deleteCameraSuccess(cameraID, areaID, siteID))
    } catch (err) {
      dispatch(deleteCameraFailure(err))
    } finally {
      dispatch(deleteCameraIdle())
    }
  }
}

export const MOVE_CAMERA_BETWEEN_AREA_REQUEST = 'MOVE_CAMERA_BETWEEN_AREA_REQUEST'
export const MOVE_CAMERA_BETWEEN_AREA_SUCCESS = 'MOVE_CAMERA_BETWEEN_AREA_SUCCESS'
export const MOVE_CAMERA_BETWEEN_AREA_FAILURE = 'MOVE_CAMERA_BETWEEN_AREA_FAILURE'
export const MOVE_CAMERA_BETWEEN_AREA_IDLE = 'MOVE_CAMERA_BETWEEN_AREA_IDLE'

const moveCameraBetweenAreaRequest = () => {
  return {
    type: MOVE_CAMERA_BETWEEN_AREA_REQUEST
  }
}

const moveCameraBetweenAreaSuccess = (cameraData, sourceAreaID) => {
  return {
    type: MOVE_CAMERA_BETWEEN_AREA_SUCCESS,
    payload: {
      cameraData,
      sourceAreaID
    }
  }
}

const moveCameraBetweenAreaFailure = (error) => {
  return {
    type: MOVE_CAMERA_BETWEEN_AREA_FAILURE,
    payload: {
      error
    }
  }
}

const moveCameraBetweenAreaIdle = () => {
  return {
    type: MOVE_CAMERA_BETWEEN_AREA_IDLE
  }
}

export const moveCameraBetweenArea = (data, sourceCameraID, sourceAreaID) => {
  return async (dispatch) => {
    dispatch(moveCameraBetweenAreaRequest())
    try {
      const response = await patch({
        url: `/${API_VERSION}/cameras/${sourceCameraID}`,
        payload: data
      })
      dispatch(moveCameraBetweenAreaSuccess(response, sourceAreaID))
    } catch (err) {
      dispatch(moveCameraBetweenAreaFailure(err))
    } finally {
      dispatch(moveCameraBetweenAreaIdle())
    }
  }
}

export const SET_CURRENT_PROJECT_COOKIE_REQUEST = 'SET_CURRENT_PROJECT_COOKIE_REQUEST'
export const SET_CURRENT_PROJECT_COOKIE_SUCCESS = 'SET_CURRENT_PROJECT_COOKIE_SUCCESS'
export const SET_CURRENT_PROJECT_COOKIE_FAILURE = 'SET_CURRENT_PROJECT_COOKIE_FAILURE'
export const SET_CURRENT_PROJECT_COOKIE_IDLE = 'SET_CURRENT_PROJECT_COOKIE_IDLE'

const setCurrentProjectCookieRequest = () => {
  return {
    type: SET_CURRENT_PROJECT_COOKIE_REQUEST
  }
}

const setCurrentProjectCookieSuccess = () => {
  return {
    type: SET_CURRENT_PROJECT_COOKIE_SUCCESS
  }
}

const setCurrentProjectCookieFailure = (error) => {
  return {
    type: SET_CURRENT_PROJECT_COOKIE_FAILURE,
    payload: {
      error
    }
  }
}

const setCurrentProjectCookieIdle = () => {
  return {
    type: SET_CURRENT_PROJECT_COOKIE_IDLE
  }
}

export const setCurrentProjectCookie = (projectID) => {
  return async (dispatch) => {
    dispatch(setCurrentProjectCookieRequest())
    try {
      await post({
        url: `/${API_VERSION}/projects/${projectID}/current`
      })
      dispatch(setCurrentProjectCookieSuccess())
    } catch (err) {
      dispatch(setCurrentProjectCookieFailure(err))
    } finally {
      dispatch(setCurrentProjectCookieIdle())
    }
  }
}

export const CREATE_SPEAKER_REQUEST = 'CREATE_SPEAKER_REQUEST'
export const CREATE_SPEAKER_SUCCESS = 'CREATE_SPEAKER_SUCCESS'
export const CREATE_SPEAKER_FAILURE = 'CREATE_SPEAKER_FAILURE'
export const CREATE_SPEAKER_IDLE = 'CREATE_SPEAKER_IDLE'

const createSpeakerRequest = () => {
  return {
    type: CREATE_SPEAKER_REQUEST
  }
}

const createSpeakerSuccess = (speakerData, cameraData, areaID, siteID) => {
  return {
    type: CREATE_SPEAKER_SUCCESS,
    payload: {
      speakerData,
      cameraData,
      areaID,
      siteID
    }
  }
}

const createSpeakerFailure = (error) => {
  return {
    type: CREATE_SPEAKER_FAILURE,
    payload: {
      error
    }
  }
}

const createSpeakerIdle = () => {
  return {
    type: CREATE_SPEAKER_IDLE
  }
}

export const createSpeaker = (speakerData, cameraData, areaID, siteID) => {
  return async (dispatch) => {
    dispatch(createSpeakerRequest())
    try {
      const response = await post({
        url: `/${API_VERSION}/speakers`,
        payload: speakerData
      })
      dispatch(createSpeakerSuccess(response, cameraData, areaID, siteID))
    } catch (err) {
      dispatch(createSpeakerFailure(err))
    } finally {
      dispatch(createSpeakerIdle())
    }
  }
}

export const UPDATE_SPEAKER_REQUEST = 'UPDATE_SPEAKER_REQUEST'
export const UPDATE_SPEAKER_SUCCESS = 'UPDATE_SPEAKER_SUCCESS'
export const UPDATE_SPEAKER_FAILURE = 'UPDATE_SPEAKER_FAILURE'
export const UPDATE_SPEAKER_IDLE = 'UPDATE_SPEAKER_IDLE'

const updateSpeakerRequest = () => {
  return {
    type: UPDATE_SPEAKER_REQUEST
  }
}

const updateSpeakerSuccess = (speakerData, cameraData, areaID, siteID) => {
  return {
    type: UPDATE_SPEAKER_SUCCESS,
    payload: {
      speakerData,
      cameraData,
      areaID,
      siteID
    }
  }
}

const updateSpeakerFailure = (error) => {
  return {
    type: UPDATE_SPEAKER_FAILURE,
    payload: {
      error
    }
  }
}

const updateSpeakerIdle = () => {
  return {
    type: UPDATE_SPEAKER_IDLE
  }
}

export const updateSpeaker = (speakerData, speakerID, cameraData, areaID, siteID) => {
  return async (dispatch) => {
    dispatch(updateSpeakerRequest())
    try {
      const response = await patch({
        url: `/${API_VERSION}/speakers/${speakerID}`,
        payload: speakerData
      })
      dispatch(updateSpeakerSuccess(response, cameraData, areaID, siteID))
    } catch (err) {
      dispatch(updateSpeakerFailure(err))
    } finally {
      dispatch(updateSpeakerIdle())
    }
  }
}

export const DELETE_SPEAKER_REQUEST = 'DELETE_SPEAKER_REQUEST'
export const DELETE_SPEAKER_SUCCESS = 'DELETE_SPEAKER_SUCCESS'
export const DELETE_SPEAKER_FAILURE = 'DELETE_SPEAKER_FAILURE'
export const DELETE_SPEAKER_IDLE = 'DELETE_SPEAKER_IDLE'

const deleteSpeakerRequest = () => {
  return {
    type: DELETE_SPEAKER_REQUEST
  }
}

const deleteSpeakerSuccess = (speakerData, cameraData, areaID, siteID) => {
  return {
    type: DELETE_SPEAKER_SUCCESS,
    payload: {
      speakerData,
      cameraData,
      areaID,
      siteID
    }
  }
}

const deleteSpeakerFailure = (error) => {
  return {
    type: DELETE_SPEAKER_FAILURE,
    payload: {
      error
    }
  }
}

const deleteSpeakerIdle = () => {
  return {
    type: DELETE_SPEAKER_IDLE
  }
}

export const deleteSpeaker = (speakerData, cameraData, areaID, siteID) => {
  return async (dispatch) => {
    dispatch(deleteSpeakerRequest())
    try {
      await del({
        url: `/${API_VERSION}/speakers/${speakerData.id}`
      })
      dispatch(deleteSpeakerSuccess(speakerData, cameraData, areaID, siteID))
    } catch (err) {
      dispatch(deleteSpeakerFailure(err))
    } finally {
      dispatch(deleteSpeakerIdle())
    }
  }
}
