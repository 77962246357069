/* eslint-disable no-case-declarations */
import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'
import {
  LOAD_CAMERA_ZONE_LIST_REQUEST,
  LOAD_CAMERA_ZONE_LIST_SUCCESS,
  LOAD_CAMERA_ZONE_LIST_FAILURE,
  LOAD_CAMERA_ZONE_LIST_IDLE,
  SYNC_CAMERA_ZONE_LIST_REQUEST,
  SYNC_CAMERA_ZONE_LIST_SUCCESS,
  SYNC_CAMERA_ZONE_LIST_FAILURE,
  SYNC_CAMERA_ZONE_LIST_IDLE
} from '../actions'

import { UPDATE_CAMERA_SOCKET } from '../../NotificationSidebar/actions'

const initialState = {
  cameraZoneList: [],
  loadListZone: {
    fetchStatus: FETCH_STATUS_IDLE,
    syncStatus: FETCH_STATUS_IDLE
  }
}

const allCameraZonePage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMERA_ZONE_LIST_REQUEST:
      return Object.assign({}, state, {
        loadListZone: Object.assign({}, state.loadListZone, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    case LOAD_CAMERA_ZONE_LIST_SUCCESS:
      return Object.assign({}, state, {
        cameraZoneList: action.payload.cameraZoneList,
        loadListZone: Object.assign({}, state.loadListZone, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    case LOAD_CAMERA_ZONE_LIST_FAILURE:
      return Object.assign({}, state, {
        loadListZone: Object.assign({}, state.loadListZone, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    case LOAD_CAMERA_ZONE_LIST_IDLE:
      return Object.assign({}, state, {
        loadListZone: Object.assign({}, state.loadListZone, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    case SYNC_CAMERA_ZONE_LIST_REQUEST:
      return Object.assign({}, state, {
        loadListZone: Object.assign({}, state.loadListZone, {
          syncStatus: FETCH_STATUS_REQUEST
        })
      })
    case SYNC_CAMERA_ZONE_LIST_SUCCESS:
      return Object.assign({}, state, {
        cameraZoneList: action.payload.cameraZoneList,
        loadListZone: Object.assign({}, state.loadListZone, {
          syncStatus: FETCH_STATUS_SUCCESS
        })
      })
    case SYNC_CAMERA_ZONE_LIST_FAILURE:
      return Object.assign({}, state, {
        loadListZone: Object.assign({}, state.loadListZone, {
          syncStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    case SYNC_CAMERA_ZONE_LIST_IDLE:
      return Object.assign({}, state, {
        loadListZone: Object.assign({}, state.loadListZone, {
          syncStatus: FETCH_STATUS_IDLE
        })
      })
    case UPDATE_CAMERA_SOCKET:
      const { upComingCameraData } = action.payload
      const updateCameraZoneList = state.cameraZoneList.map((camera) => {
        if (camera.camera_id === upComingCameraData.notification_object.camera_id) {
          return Object.assign({}, camera, {
            camera_status: upComingCameraData.notification_object.camera_status,
            image: upComingCameraData.notification_object.camera_image ? upComingCameraData.notification_object.camera_image : camera.image
          })
        }
        return camera
      })
      return Object.assign({}, state, {
        cameraZoneList: updateCameraZoneList
      })
    default:
      return state
  }
}

export default allCameraZonePage
