import React from 'react'
import PropTypes from 'prop-types'

import SkeletonLoadingStyled from './styledComponent'

class SkeletonLoading extends React.PureComponent {
  render() {
    return <SkeletonLoadingStyled className={this.props.className} />
  }
}

SkeletonLoading.propTypes = {
  className: PropTypes.string
}

export default SkeletonLoading
