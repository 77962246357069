import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import {
  GET_USER_BY_TOKEN_REQUEST,
  GET_USER_BY_TOKEN_SUCCESS,
  GET_USER_BY_TOKEN_FAILURE,
  GET_USER_BY_TOKEN_IDLE,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  SET_PASSWORD_IDLE
} from '../actions'

const initialState = {
  userEmail: '',
  getUserEmailStatus: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  setPasswordStatus: {
    fetchStatus: FETCH_STATUS_IDLE
  }
}

const setPasswordPage = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BY_TOKEN_REQUEST: {
      return Object.assign({}, state, {
        getUserEmailStatus: Object.assign({}, state.getUserEmailStatus, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case GET_USER_BY_TOKEN_SUCCESS: {
      return Object.assign({}, state, {
        getUserEmailStatus: Object.assign({}, state.getUserEmailStatus, {
          fetchStatus: FETCH_STATUS_SUCCESS
        }),
        userEmail: action.payload.userEmail
      })
    }
    case GET_USER_BY_TOKEN_FAILURE: {
      return Object.assign({}, state, {
        getUserEmailStatus: Object.assign({}, state.getUserEmailStatus, {
          fetchStatus: FETCH_STATUS_FAILURE
        }),
        errorCode: action.payload.errorCode
      })
    }
    case GET_USER_BY_TOKEN_IDLE: {
      return Object.assign({}, state, {
        getUserEmailStatus: Object.assign({}, state.getUserEmailStatus, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case SET_PASSWORD_REQUEST: {
      return Object.assign({}, state, {
        setPasswordStatus: Object.assign({}, state.setPasswordStatus, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case SET_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        setPasswordStatus: Object.assign({}, state.setPasswordStatus, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case SET_PASSWORD_FAILURE: {
      return Object.assign({}, state, {
        setPasswordStatus: Object.assign({}, state.setPasswordStatus, {
          fetchStatus: FETCH_STATUS_FAILURE
        })
      })
    }
    case SET_PASSWORD_IDLE: {
      return Object.assign({}, state, {
        setPasswordStatus: Object.assign({}, state.setPasswordStatus, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    default: {
      return state
    }
  }
}

export default setPasswordPage
