/* eslint-disable indent */
import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import { GET_LIST_SITE_REQUEST, GET_LIST_SITE_SUCCESS, GET_LIST_SITE_FAILURE, GET_LIST_SITE_IDLE } from '../actions'

const initialState = {
  listProject: [],
  loadSiteStatus: {
    fetchStatus: FETCH_STATUS_IDLE
  }
}

const selectSitePage = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_SITE_REQUEST: {
      return Object.assign({}, state, {
        loadSiteStatus: Object.assign({}, state.loadSiteStatus, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case GET_LIST_SITE_SUCCESS: {
      return Object.assign({}, state, {
        listProject: action.payload.listProject,
        loadSiteStatus: Object.assign({}, state.loadSiteStatus, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case GET_LIST_SITE_FAILURE: {
      return Object.assign({}, state, {
        loadSiteStatus: Object.assign({}, state.loadSiteStatus, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case GET_LIST_SITE_IDLE: {
      return Object.assign({}, state, {
        loadSiteStatus: Object.assign({}, state.loadSiteStatus, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    default: {
      return state
    }
  }
}

export default selectSitePage
