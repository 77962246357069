import queryString from 'query-string'
import { get, post, patch, del } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const LOAD_PROJECT_USER_LIST_REQUEST = 'LOAD_PROJECT_USER_LIST_REQUEST'
export const LOAD_PROJECT_USER_LIST_SUCCESS = 'LOAD_PROJECT_USER_LIST_SUCCESS'
export const LOAD_PROJECT_USER_LIST_FAILURE = 'LOAD_PROJECT_USER_LIST_FAILURE'
export const LOAD_PROJECT_USER_LIST_IDLE = 'LOAD_PROJECT_USER_LIST_IDLE'

const loadProjectUserListRequest = () => {
  return {
    type: LOAD_PROJECT_USER_LIST_REQUEST
  }
}

const loadProjectUserListSuccess = (listProject, listAccount) => {
  return {
    type: LOAD_PROJECT_USER_LIST_SUCCESS,
    payload: {
      listProject,
      listAccount
    }
  }
}

const loadProjectUserListFailure = (error) => {
  return {
    type: LOAD_PROJECT_USER_LIST_FAILURE,
    payload: {
      error
    }
  }
}

const loadProjectUserListIdle = () => {
  return {
    type: LOAD_PROJECT_USER_LIST_IDLE
  }
}

export const loadProjectUserList = () => {
  return async (dispatch) => {
    const query = queryString.stringify({
      include: 'users'
    })
    dispatch(loadProjectUserListRequest())
    try {
      const listProject = await get({
        url: `/${API_VERSION}/projects?${query}`
      })
      const listAccount = await get({
        url: `/${API_VERSION}/accounts`
      })
      dispatch(loadProjectUserListSuccess(listProject, listAccount))
    } catch (err) {
      dispatch(loadProjectUserListFailure(err))
    } finally {
      dispatch(loadProjectUserListIdle())
    }
  }
}

export const CREATE_NEW_USER_REQUEST = 'CREATE_NEW_USER_REQUEST'
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS'
export const CREATE_NEW_USER_FAILURE = 'CREATE_NEW_USER_FAILURE'
export const CREATE_NEW_USER_IDLE = 'CREATE_NEW_USER_IDLE'

const createNewUserRequest = () => {
  return {
    type: CREATE_NEW_USER_REQUEST
  }
}

const createNewUserSuccess = (userData) => {
  return {
    type: CREATE_NEW_USER_SUCCESS,
    payload: {
      userData
    }
  }
}

const createNewUserFailure = (error) => {
  return {
    type: CREATE_NEW_USER_FAILURE,
    payload: {
      error
    }
  }
}

const createNewUserIdle = () => {
  return {
    type: CREATE_NEW_USER_IDLE
  }
}

export const createNewUser = (data) => {
  return async (dispatch) => {
    dispatch(createNewUserRequest())
    try {
      const userData = await post({
        url: `/${API_VERSION}/accounts`,
        payload: data
      })
      dispatch(createNewUserSuccess(userData))
    } catch (err) {
      dispatch(createNewUserFailure(err))
    } finally {
      dispatch(createNewUserIdle())
    }
  }
}

export const CREATE_NEW_MANAGER_REQUEST = 'CREATE_NEW_MANAGER_REQUEST'
export const CREATE_NEW_MANAGER_SUCCESS = 'CREATE_NEW_MANAGER_SUCCESS'
export const CREATE_NEW_MANAGER_FAILURE = 'CREATE_NEW_MANAGER_FAILURE'
export const CREATE_NEW_MANAGER_IDLE = 'CREATE_NEW_MANAGER_IDLE'

const createNewManagerRequest = () => {
  return {
    type: CREATE_NEW_MANAGER_REQUEST
  }
}

const createNewManagerSuccess = (userData) => {
  return {
    type: CREATE_NEW_MANAGER_SUCCESS,
    payload: {
      userData
    }
  }
}

const createNewManagerFailure = (error) => {
  return {
    type: CREATE_NEW_MANAGER_FAILURE,
    payload: {
      error
    }
  }
}

const createNewManagerIdle = () => {
  return {
    type: CREATE_NEW_MANAGER_IDLE
  }
}

export const createNewManager = (data) => {
  return async (dispatch) => {
    const query = queryString.stringify({
      manager: true
    })
    dispatch(createNewManagerRequest())
    try {
      const userData = await post({
        url: `/${API_VERSION}/accounts?${query}`,
        payload: data
      })
      dispatch(createNewManagerSuccess(userData))
    } catch (err) {
      dispatch(createNewManagerFailure(err))
    } finally {
      dispatch(createNewManagerIdle())
    }
  }
}

export const CREATE_NEW_ADMIN_REQUEST = 'CREATE_NEW_ADMIN_REQUEST'
export const CREATE_NEW_ADMIN_SUCCESS = 'CREATE_NEW_ADMIN_SUCCESS'
export const CREATE_NEW_ADMIN_FAILURE = 'CREATE_NEW_ADMIN_FAILURE'
export const CREATE_NEW_ADMIN_IDLE = 'CREATE_NEW_ADMIN_IDLE'

const createNewAdminRequest = () => {
  return {
    type: CREATE_NEW_ADMIN_REQUEST
  }
}

const createNewAdminSuccess = (userData) => {
  return {
    type: CREATE_NEW_ADMIN_SUCCESS,
    payload: {
      userData
    }
  }
}

const createNewAdminFailure = (error) => {
  return {
    type: CREATE_NEW_ADMIN_FAILURE,
    payload: {
      error
    }
  }
}

const createNewAdminIdle = () => {
  return {
    type: CREATE_NEW_ADMIN_IDLE
  }
}

export const createNewAdmin = (data) => {
  return async (dispatch) => {
    dispatch(createNewAdminRequest())
    try {
      const userData = await post({
        url: `/${API_VERSION}/admins`,
        payload: data
      })
      dispatch(createNewAdminSuccess(userData))
    } catch (err) {
      dispatch(createNewAdminFailure(err))
    } finally {
      dispatch(createNewAdminIdle())
    }
  }
}

export const UPDATE_USER_ACCOUNT_REQUEST = 'UPDATE_USER_ACCOUNT_REQUEST'
export const UPDATE_USER_ACCOUNT_SUCCESS = 'UPDATE_USER_ACCOUNT_SUCCESS'
export const UPDATE_USER_ACCOUNT_FAILURE = 'UPDATE_USER_ACCOUNT_FAILURE'
export const UPDATE_USER_ACCOUNT_IDLE = 'UPDATE_USER_ACCOUNT_IDLE'

const updateUserAccountRequest = () => {
  return {
    type: UPDATE_USER_ACCOUNT_REQUEST
  }
}

const updateUserAccountSuccess = (userData) => {
  return {
    type: UPDATE_USER_ACCOUNT_SUCCESS,
    payload: {
      userData
    }
  }
}

const updateUserAccountFailure = (error) => {
  return {
    type: UPDATE_USER_ACCOUNT_FAILURE,
    payload: {
      error
    }
  }
}

const updateUserAccountIdle = () => {
  return {
    type: UPDATE_USER_ACCOUNT_IDLE
  }
}

export const updateUserAccount = (data, userID) => {
  return async (dispatch) => {
    dispatch(updateUserAccountRequest())
    try {
      const updatedUserData = await patch({
        url: `/${API_VERSION}/accounts/${userID}`,
        payload: data
      })
      dispatch(updateUserAccountSuccess(updatedUserData))
    } catch (err) {
      dispatch(updateUserAccountFailure(err))
    } finally {
      dispatch(updateUserAccountIdle())
    }
  }
}

export const DELETE_USER_ACCOUNT_REQUEST = 'DELETE_USER_ACCOUNT_REQUEST'
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS'
export const DELETE_USER_ACCOUNT_FAILURE = 'DELETE_USER_ACCOUNT_FAILURE'
export const DELETE_USER_ACCOUNT_IDLE = 'DELETE_USER_ACCOUNT_IDLE'

const deleteUserAccountRequest = () => {
  return {
    type: DELETE_USER_ACCOUNT_REQUEST
  }
}

const deleteUserAccountSuccess = (userID) => {
  return {
    type: DELETE_USER_ACCOUNT_SUCCESS,
    payload: {
      userID
    }
  }
}

const deleteUserAccountFailure = (error) => {
  return {
    type: DELETE_USER_ACCOUNT_FAILURE,
    payload: {
      error
    }
  }
}

const deleteUserAccountIdle = () => {
  return {
    type: DELETE_USER_ACCOUNT_IDLE
  }
}

export const deleteUserAccount = (userID) => {
  return async (dispatch) => {
    dispatch(deleteUserAccountRequest())
    try {
      await del({
        url: `/${API_VERSION}/accounts/${userID}`
      })
      dispatch(deleteUserAccountSuccess(userID))
    } catch (err) {
      dispatch(deleteUserAccountFailure(err))
    } finally {
      dispatch(deleteUserAccountIdle())
    }
  }
}
