const MESSAGE = {
  EN: {
    back_btn: '< Back',
    zone_header: 'Zone',
    zone_subheader: 'ZONE',
    action_subheader: 'ACTION',
    target_subheader: 'TARGET',
    createzone_title_modal: 'Add New Zone',
    createzone_message_modal: 'You haven’t saved your changes, are you sure you want to add a new zone?',
    addzone_btn: 'Add',
    dontaddzone_btn: "Don't Add",
    editzone_title_modal: 'Unsaved Changes',
    editzone_message_modal: 'You haven’t saved your changes, do you want to discard it?',
    editzone_btn: "Don't Discard",
    donteditzone_btn: 'Discard',
    goback_title_modal: 'Confirm Going Back',
    goback_message_modal: 'You haven’t saved your changes, are you sure you want to go back?',
    goback_btn: 'Go Back',
    dontgoback_btn: "Dont't Go Back",
    deletezone_title_modal: 'Delete zone ',
    deletezone_message_modal: 'Are you sure you want to delete this? This action cannot be undone.',
    deletezone_btn: 'Delete',
    dontdeletezone_btn: 'Cancel',
    no_image_title: 'No Image',
    no_image_message: 'There is no image. Please check camera connection.',
    select_target_dropdown: 'Select Target',
    select_condition_dropdown: 'Select Condition',
    select_event_dropdown: 'Select Event',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again',
    delete_modal_title: 'Delete color range',
    delete_modal_message_1: 'Are you sure you want to delete',
    delete_modal_message_2: 'color range ?',
    Unauthorized: 'Unauthorized',
    'Color Violation': 'Color Violation',
    person: 'Person',
    truck: 'Truck'
  },
  TH: {
    back_btn: '< กลับ',
    zone_header: 'โซน',
    zone_subheader: 'ZONE',
    action_subheader: 'ชื่อการละเมิด',
    target_subheader: 'เป้าหมาย',
    scheduling_title: 'กำหนดการครั้งต่อไป',
    createzone_title_modal: 'เพิ่มโซนใหม่?',
    createzone_message_modal: 'ยังไมไ่ด้บันทึกการเปลี่ยนแปลง ต้องการที่จะเพิ่มโซนใหม่อีกหรือไม่',
    addzone_btn: 'เพิ่ม',
    dontaddzone_btn: 'ไม่เพิ่ม',
    editzone_title_modal: 'มีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก',
    editzone_message_modal: 'ยังไม่ได้บันทึกการเปลี่ยนแปลง ต้องการจะยกเลิกหรือไม่',
    editzone_btn: 'ไม่ยกเลิก',
    donteditzone_btn: 'ยกเลิก',
    goback_title_modal: 'ยืนยันการกลับไปหน้าก่อนหน้านี้',
    goback_message_modal: 'ยังไม่ได้บันทึกการเปลี่ยนแปลง ต้องการที่จะกลับไปหน้าก่อนหน้าหรือไม่',
    goback_btn: 'กลับไป',
    dontgoback_btn: 'ไม่กลับไป',
    deletezone_title_modal: 'ลบโซน ',
    deletezone_message_modal: 'คุณต้องการที่จะลบโซนนี้หรือไม่ การกระทำนี้ไม่สามารถย้อนกลับได้นะ',
    deletezone_btn: 'ลบ',
    dontdeletezone_btn: 'ยกเลิก',
    no_image_title: 'ไม่มีภาพ',
    no_image_message: 'ไม่มีภาพ กรุณาตรวจสอบการเชื่อมต่อของกล้อง',
    select_target_dropdown: 'เลือกเป้าหมาย',
    select_condition_dropdown: 'เลือกเงื่อนไข',
    select_event_dropdown: 'เลือกกฎ',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง',
    delete_modal_title: 'ลบช่วงสี',
    delete_modal_message_1: 'คุณต้องการลบ',
    delete_modal_message_2: 'ช่วงสีนี้ใช่หรือไม่ ?',
    Unauthorized: 'ไม่อนุญาตให้เข้า',
    'Color Violation': 'สี',
    person: 'บุคคล',
    truck: 'รถบรรทุก'
  }
}

export default MESSAGE
