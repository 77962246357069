import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../utils'

import {
  GET_DAILY_NOTIFICATION_REQUEST,
  GET_DAILY_NOTIFICATION_SUCCESS,
  GET_DAILY_NOTIFICATION_FAILURE,
  GET_DAILY_NOTIFICATION_IDLE,
  GET_NEW_NOTIFICATION_IDLE,
  UPDATE_NEW_NOTIFICATION_SOCKET,
  READ_NOTIFICATION_REQUEST,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
  READ_NOTIFICATION_IDLE
} from './actions'

const initialState = {
  feeds: [],
  isUpdatingNewNotification: false,
  loadListNotification: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  loadReadNotification: {
    fetchStatus: FETCH_STATUS_IDLE
  }
}

const notification = (state = initialState, action) => {
  switch (action.type) {
    case GET_DAILY_NOTIFICATION_REQUEST: {
      return Object.assign({}, state, {
        feeds: [],
        loadListNotification: Object.assign({}, state.loadListNotification, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }

    case GET_DAILY_NOTIFICATION_SUCCESS: {
      return Object.assign({}, state, {
        feeds: action.payload.listNotification,
        loadListNotification: Object.assign({}, state.loadListNotification, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }

    case GET_DAILY_NOTIFICATION_FAILURE: {
      return Object.assign({}, state, {
        error: action.payload.error,
        loadListNotification: Object.assign({}, state.loadListNotification, {
          fetchStatus: FETCH_STATUS_FAILURE
        })
      })
    }

    case GET_DAILY_NOTIFICATION_IDLE: {
      return Object.assign({}, state, {
        loadListNotification: Object.assign({}, state.loadListNotification, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }

    case UPDATE_NEW_NOTIFICATION_SOCKET: {
      return Object.assign({}, state, {
        isUpdatingNewNotification: true,
        feeds: [action.payload.notificationObj, ...state.feeds]
      })
    }
    case GET_NEW_NOTIFICATION_IDLE: {
      return Object.assign({}, state, {
        isUpdatingNewNotification: false
      })
    }

    case READ_NOTIFICATION_REQUEST: {
      return Object.assign({}, state, {
        loadReadNotification: Object.assign({}, state.loadReadNotification, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }

    case READ_NOTIFICATION_SUCCESS: {
      const notiId = action.payload.notificationId
      let tempFeeds = []
      state.feeds.forEach((notification) => {
        if (notification.id === notiId) {
          let tempNotification = Object.assign({}, notification, {
            ...notification,
            read_status: true
          })
          tempFeeds.push(tempNotification)
        } else {
          tempFeeds.push(notification)
        }
      })
      return Object.assign({}, state, {
        feeds: tempFeeds,
        loadReadNotification: Object.assign({}, state.loadReadNotification, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }

    case READ_NOTIFICATION_FAILURE: {
      return Object.assign({}, state, {
        readError: action.payload.error,
        loadReadNotification: Object.assign({}, state.loadReadNotification, {
          fetchStatus: FETCH_STATUS_FAILURE
        })
      })
    }

    case READ_NOTIFICATION_IDLE: {
      return Object.assign({}, state, {
        loadReadNotification: Object.assign({}, state.loadReadNotification, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }

    default: {
      return state
    }
  }
}

export default notification
