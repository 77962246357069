import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import { Provider } from 'react-redux'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from 'styled-components'
//import * as Sentry from '@sentry/browser'

import browserHistory from './routes/history'
import './global-style.scss'
import Routes from './routes'
import colors from './colors'
import configureStore from './store/configureStore'
import * as serviceWorker from './serviceWorker'

//Sentry.init({
//	dsn: 'https://e4b074d2bfc6441a88f23a85d045cb05@o259466.ingest.sentry.io/1762583',
//	environment: process.env.SENTRY_ENVIRONMENT,
//	release: `FarmlabFrontend@${process.env.APP_VERSION}`
//})

document.body.oncontextmenu = (e) => {
  e.preventDefault()
}

ReactModal.setAppElement('#root')
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root')

let store = configureStore()

const history = browserHistory

const onRedirectCallback = (appState) => {
  history.push(appState?.returnTo || window.location.pathname)
}

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <ThemeProvider theme={colors}>
      <Provider store={store}>
        <Auth0Provider
          audience={process.env.AUTH0_AUDIENCE}
          domain={process.env.AUTH0_DOMAIN}
          clientId={process.env.AUTH0_CLIENT_ID}
          redirectUri={process.env.AUTH0_REDIRECT_URI}
          onRedirectCallback={onRedirectCallback}
          authorizeTimeoutInSeconds={300}
          scope={process.env.AUTH0_SCOPE}
          maxAge={36000}
          useRefreshTokens={true}
        >
          <Routes />
        </Auth0Provider>
      </Provider>
    </ThemeProvider>
  </DndProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
