import styled, { keyframes } from 'styled-components'

const shineLine = keyframes`
to {
      background-position: right -40px top 0;
    }
`

export default styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  background-color: rgba(216, 216, 216);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 100px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: ${shineLine} 1s ease infinite;
`
