import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import {
  LOAD_CAMERA_LIST_REQUEST,
  LOAD_CAMERA_LIST_SUCCESS,
  LOAD_CAMERA_LIST_FAILURE,
  LOAD_CAMERA_LIST_IDLE,
  SYNC_CAMERA_LIST_REQUEST,
  SYNC_CAMERA_LIST_SUCCESS,
  SYNC_CAMERA_LIST_FAILURE,
  SYNC_CAMERA_LIST_IDLE
} from '../actions'

import { UPDATE_CAMERA_SOCKET } from '../../NotificationSidebar/actions'

const initialState = {
  cameraList: [],
  loadCameraListStatus: {
    fetchStatus: FETCH_STATUS_IDLE,
    syncStatus: FETCH_STATUS_IDLE
  }
}

const violationDetailPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMERA_LIST_REQUEST: {
      return Object.assign({}, state, {
        loadCameraListStatus: Object.assign({}, state.loadCameraListStatus, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_CAMERA_LIST_SUCCESS: {
      return Object.assign({}, state, {
        cameraList: action.payload.cameraList,
        loadCameraListStatus: Object.assign({}, state.loadCameraListStatus, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }

    case LOAD_CAMERA_LIST_FAILURE: {
      return Object.assign({}, state, {
        loadCameraListStatus: Object.assign({}, state.loadCameraListStatus, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }

    case LOAD_CAMERA_LIST_IDLE: {
      return Object.assign({}, state, {
        loadCameraListStatus: Object.assign({}, state.loadCameraListStatus, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case SYNC_CAMERA_LIST_REQUEST: {
      return Object.assign({}, state, {
        loadCameraListStatus: Object.assign({}, state.loadCameraListStatus, {
          syncStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case SYNC_CAMERA_LIST_SUCCESS: {
      return Object.assign({}, state, {
        cameraList: action.payload.cameraList,
        loadCameraListStatus: Object.assign({}, state.loadCameraListStatus, {
          syncStatus: FETCH_STATUS_SUCCESS
        })
      })
    }

    case SYNC_CAMERA_LIST_FAILURE: {
      return Object.assign({}, state, {
        loadCameraListStatus: Object.assign({}, state.loadCameraListStatus, {
          syncStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }

    case SYNC_CAMERA_LIST_IDLE: {
      return Object.assign({}, state, {
        loadCameraListStatus: Object.assign({}, state.loadCameraListStatus, {
          syncStatus: FETCH_STATUS_IDLE
        })
      })
    }

    case UPDATE_CAMERA_SOCKET: {
      const { upComingCameraData } = action.payload
      /* istanbul ignore next */
      const updatedCameraList = state.cameraList.map((camera) => {
        /* istanbul ignore next */
        if (camera.camera_id === upComingCameraData.notification_object.camera_id) {
          return Object.assign({}, camera, {
            camera_status: upComingCameraData.notification_object.camera_status,
            image: upComingCameraData.notification_object.camera_image ? upComingCameraData.notification_object.camera_image : camera.image
          })
        }
        return camera
      })
      return Object.assign({}, state, {
        cameraList: updatedCameraList
      })
    }

    default: {
      return state
    }
  }
}

export default violationDetailPage
