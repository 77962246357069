import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import {
  LOAD_LIST_API_KEY_REQUEST,
  LOAD_LIST_API_KEY_SUCCESS,
  LOAD_LIST_API_KEY_FAILURE,
  LOAD_LIST_API_KEY_IDLE,
  CREATE_NEW_API_KEY_REQUEST,
  CREATE_NEW_API_KEY_SUCCESS,
  CREATE_NEW_API_KEY_FAILURE,
  CREATE_NEW_API_KEY_IDLE,
  UPDATE_API_KEY_REQUEST,
  UPDATE_API_KEY_SUCCESS,
  UPDATE_API_KEY_FAILURE,
  UPDATE_API_KEY_IDLE,
  DELETE_API_KEY_REQUEST,
  DELETE_API_KEY_SUCCESS,
  DELETE_API_KEY_FAILURE,
  DELETE_API_KEY_IDLE,
  SIGN_API_KEY_REQUEST,
  SIGN_API_KEY_SUCCESS,
  SIGN_API_KEY_FAILURE,
  SIGN_API_KEY_IDLE
} from '../actions'

const initialState = {
  listAPIKey: [],
  loadListAPIKey: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  createNewAPIKey: {
    fetchStatus: FETCH_STATUS_IDLE,
    apiKeyData: undefined
  },
  updateAPIKey: {
    fetchStatus: FETCH_STATUS_IDLE,
    apiKeyData: undefined
  },
  deleteAPIKey: {
    fetchStatus: FETCH_STATUS_IDLE
  },
  signAPIKey: {
    fetchStatus: FETCH_STATUS_IDLE,
    apiKeyData: undefined
  }
}

const apiKeyPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_API_KEY_REQUEST: {
      return Object.assign({}, state, {
        loadListAPIKey: Object.assign({}, state.loadListAPIKey, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_LIST_API_KEY_SUCCESS: {
      const preparedListAPIKey = action.payload.listAPIKey.sort((a, b) => a.project_id - b.project_id)
      return Object.assign({}, state, {
        listAPIKey: preparedListAPIKey,
        loadListAPIKey: Object.assign({}, state.loadListAPIKey, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case LOAD_LIST_API_KEY_FAILURE: {
      return Object.assign({}, state, {
        loadListAPIKey: Object.assign({}, state.loadListAPIKey, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case LOAD_LIST_API_KEY_IDLE: {
      return Object.assign({}, state, {
        loadListAPIKey: Object.assign({}, state.loadListAPIKey, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case CREATE_NEW_API_KEY_REQUEST: {
      return Object.assign({}, state, {
        createNewAPIKey: Object.assign({}, state.createNewAPIKey, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case CREATE_NEW_API_KEY_SUCCESS: {
      const updatedListAPIKey = [...state.listAPIKey, action.payload.apiKeyData]
      return Object.assign({}, state, {
        listAPIKey: updatedListAPIKey,
        createNewAPIKey: Object.assign({}, state.createNewAPIKey, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          apiKeyData: action.payload.apiKeyData
        })
      })
    }
    case CREATE_NEW_API_KEY_FAILURE: {
      return Object.assign({}, state, {
        createNewAPIKey: Object.assign({}, state.createNewAPIKey, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case CREATE_NEW_API_KEY_IDLE: {
      return Object.assign({}, state, {
        createNewAPIKey: Object.assign({}, state.createNewAPIKey, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_API_KEY_REQUEST: {
      return Object.assign({}, state, {
        updateAPIKey: Object.assign({}, state.updateAPIKey, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case UPDATE_API_KEY_SUCCESS: {
      const updatedListAPIKey = state.listAPIKey.map((data) => {
        if (data.id === action.payload.apiKeyID) {
          return action.payload.apiKeyData
        }
        return data
      })
      return Object.assign({}, state, {
        listAPIKey: updatedListAPIKey,
        updateAPIKey: Object.assign({}, state.updateAPIKey, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          apiKeyData: action.payload.apiKeyData
        })
      })
    }
    case UPDATE_API_KEY_FAILURE: {
      return Object.assign({}, state, {
        updateAPIKey: Object.assign({}, state.updateAPIKey, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case UPDATE_API_KEY_IDLE: {
      return Object.assign({}, state, {
        updateAPIKey: Object.assign({}, state.updateAPIKey, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case DELETE_API_KEY_REQUEST: {
      return Object.assign({}, state, {
        deleteAPIKey: Object.assign({}, state.deleteAPIKey, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case DELETE_API_KEY_SUCCESS: {
      const deletedListAPIKey = state.listAPIKey.filter((data) => data.id !== action.payload.apiKeyData.id)
      return Object.assign({}, state, {
        listAPIKey: deletedListAPIKey,
        deleteAPIKey: Object.assign({}, state.deleteAPIKey, {
          fetchStatus: FETCH_STATUS_SUCCESS
        })
      })
    }
    case DELETE_API_KEY_FAILURE: {
      return Object.assign({}, state, {
        deleteAPIKey: Object.assign({}, state.deleteAPIKey, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case DELETE_API_KEY_IDLE: {
      return Object.assign({}, state, {
        deleteAPIKey: Object.assign({}, state.deleteAPIKey, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case SIGN_API_KEY_REQUEST: {
      return Object.assign({}, state, {
        signAPIKey: Object.assign({}, state.signAPIKey, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case SIGN_API_KEY_SUCCESS: {
      const updatedListAPIKey = state.listAPIKey.map((data) => {
        if (data.id === action.payload.apiKeyID) {
          return Object.assign({}, data, {
            api_key: action.payload.token
          })
        }
        return data
      })
      const apiKeyData = updatedListAPIKey.find((data) => data.id === action.payload.apiKeyID)
      return Object.assign({}, state, {
        listAPIKey: updatedListAPIKey,
        signAPIKey: Object.assign({}, state.signAPIKey, {
          fetchStatus: FETCH_STATUS_SUCCESS,
          apiKeyData
        })
      })
    }
    case SIGN_API_KEY_FAILURE: {
      return Object.assign({}, state, {
        signAPIKey: Object.assign({}, state.signAPIKey, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case SIGN_API_KEY_IDLE: {
      return Object.assign({}, state, {
        signAPIKey: Object.assign({}, state.signAPIKey, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }
    default: {
      return state
    }
  }
}

export default apiKeyPage
