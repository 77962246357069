/* eslint-disable no-sequences */
/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import * as EachCameraZoneActions from './actions'

import DashboardTop from '../../components/DashboardTop/Loadable'
import ListBoardWrapper from '../../components/ListBoardWrapper/Loadable'
import DrawZoneComponent from '../../components/DrawZoneComponent'
import ZoneEditingCard from '../../components/ZoneEditingCard/Loadable'
import SkeletonLoading from '../../components/SkeletonLoading'
import ModalOverlay from '../../components/ModalOverlay/Loadable'
import Button from '../../components/Button/Loadable'
import Spinner from '../../components/Spinner/Loadable'
import RuleEditor from '../../components/RuleEditer/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

import EachCameraZoneStyled from './styledComponent'

import MESSAGE from './message'
import {
  FETCH_STATUS_IDLE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FRONTEND_ROUTES,
  THAI_LANGUAGE,
  LIST_ZONE_COLORS,
  UNAUTHORIZED_EVENT,
  ANY_CONDITION_KEY,
  LIST_ALL_CLASS,
  makeFirstLetterCapital,
  LINE_CROSSING_EVENT,
  TRUCK_TARGET,
  NEW_ZONE_ID
} from '../../utils'

export const DRAWING_ZONE_CONTAINER_WIDTH = 610
export const DRAWING_ZONE_CONTAINER_HEIGHT = 418

export class EachCameraZonePage extends React.Component {
  state = {
    cameraData: undefined,
    activeZone: undefined,
    isDrawing: false,
    isChangeSetting: false,
    validZone: false,
    invalidPolygon: false,
    isAddNewZone: false,
    showModal: false,
    showDelColorRangeModal: false,
    modalData: {
      title: '',
      message: '',
      onSecondaryButtonClick: null,
      onPrimaryButtonClick: null,
      onOverLayClose: null,
      secondaryButtonText: 'Cancel',
      primaryButtonText: 'Confirm'
    },
    shouldDisabledAddZoneBtn: false,
    showLoading: false,
    failureModalData: {
      show: false,
      title: '',
      message: '',
      onPrimaryButtonClick: undefined,
      primaryButtonText: ''
    },
    shouldDisableEditor: false
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language || THAI_LANGUAGE]
    return msg[property]
  }

  componentDidMount() {
    this._loadCameraZoneData()
  }

  componentDidUpdate(prevProps, prevState) {
    this._handleFetchLoadCameraZoneData(prevProps, prevState)
    this._handleFetchEditCameraZoneData(prevProps, prevState)
    this._handleFetchDeleteCameraZoneData(prevProps)
    this._checkNoImageAndOfflineCamera(prevProps)
    this._checkPermission()
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }
  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  _checkPermission() {
    const isAdmin = this.props.currentUser ? this.props.currentUser.admin : false
    const isManager = this.props.currentUser ? this.props.currentUser.manager : false
    const isUser = !(isAdmin || isManager)
    if (isUser) {
      const { projectID, siteId } = this.props.match.params
      this.props.history.push(`${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${siteId}/zone`)
    }
  }

  forceLogin() {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  _loadCameraZoneData() {
    const cameraId = this.props.match.params.id
    this.props.loadCameraZone(cameraId)
  }

  _checkNoImageAndOfflineCamera(prevProps) {
    if (prevProps.loadCameraZoneStatus === FETCH_STATUS_REQUEST && this.props.loadCameraZoneStatus === FETCH_STATUS_SUCCESS) {
      this._checkCanEditZone()
    }
  }

  _checkCanEditZone() {
    const { cameraZoneData } = this.props
    if (!cameraZoneData.image) {
      this.showNoImageModal()
    }
  }

  showNoImageModal() {
    this.setState({
      showModal: true,
      modalData: {
        title: this.getMessage('no_image_title'),
        message: this.getMessage('no_image_message'),
        onSecondaryButtonClick: null,
        onPrimaryButtonClick: () => this.handleConfirmGoBackClick(),
        onOverLayClose: null,
        primaryButtonText: this.getMessage('goback_btn')
      }
    })
  }

  _handleFetchLoadCameraZoneData(prevProps, prevState) {
    if (prevProps.loadCameraZoneStatus === FETCH_STATUS_IDLE && this.props.loadCameraZoneStatus === FETCH_STATUS_REQUEST) {
      this.setShowLoading(true)
    }
    if (prevProps.loadCameraZoneStatus === FETCH_STATUS_REQUEST && this.props.loadCameraZoneStatus === FETCH_STATUS_SUCCESS) {
      this.handleLoadCameraZoneDataSuccess()
      this.setShowLoading(false)
    }
    if (prevProps.loadCameraZoneStatus === FETCH_STATUS_REQUEST && this.props.loadCameraZoneStatus === FETCH_STATUS_FAILURE) {
      this.setShowLoading(false)
      this.handleLoadCameraZoneFailure()
    }
    if (prevProps.syncCameraZoneListStatus === FETCH_STATUS_REQUEST && this.props.syncCameraZoneListStatus === FETCH_STATUS_SUCCESS) {
      this.handleLoadCameraZoneDataSuccess()
      this.setShowLoading(false)
    }
  }

  _handleFetchEditCameraZoneData(prevProps, prevState) {
    if (prevProps.editZoneStatus === FETCH_STATUS_IDLE && this.props.editZoneStatus === FETCH_STATUS_REQUEST) {
      this.setShowLoading(true)
    }
    if (prevProps.editZoneStatus === FETCH_STATUS_REQUEST && this.props.editZoneStatus === FETCH_STATUS_SUCCESS) {
      this.handleEditCameraZoneSuccess(prevState)
      this.setShowLoading(false)
    }
    if (prevProps.editZoneStatus === FETCH_STATUS_REQUEST && this.props.editZoneStatus === FETCH_STATUS_FAILURE) {
      this.setShowLoading(false)
      this.handleEditZoneFailure()
    }
  }

  handleEditCameraZoneSuccess(prevState) {
    this.setState({
      cameraData: _.cloneDeep(this.props.cameraZoneData)
    })
    const recentlyEditedZone = this.props.cameraZoneData.zones.find((zone) => zone.zone_id === prevState.activeZone.zone_id)
    //set activeZone to be the latest zone the is edited
    this.setActiveZone(recentlyEditedZone)
  }

  _handleFetchDeleteCameraZoneData(prevProps) {
    if (prevProps.deleteZoneStatus === FETCH_STATUS_IDLE && this.props.deleteZoneStatus === FETCH_STATUS_REQUEST) {
      this.setShowLoading(true)
    }
    if (prevProps.deleteZoneStatus === FETCH_STATUS_REQUEST && this.props.deleteZoneStatus === FETCH_STATUS_SUCCESS) {
      this.handleDeleteCameraZoneDataSuccess()
      this.setShowLoading(false)
    }
    if (prevProps.deleteZoneStatus === FETCH_STATUS_REQUEST && this.props.deleteZoneStatus === FETCH_STATUS_FAILURE) {
      this.setShowLoading(false)
      this.handleDeleteZoneFailure()
    }
  }

  handleDeleteCameraZoneDataSuccess() {
    this.setState({
      cameraData: _.cloneDeep(this.props.cameraZoneData),
      shouldDisabledAddZoneBtn: this.props.cameraZoneData.zones.length >= 10
    })
    const tempZones = this.props.cameraZoneData.zones
    this.setActiveZone(this.props.cameraZoneData.zones[tempZones.length - 1])
  }

  handleLoadCameraZoneDataSuccess() {
    this.setState({
      cameraData: _.cloneDeep(this.props.cameraZoneData),
      shouldDisabledAddZoneBtn: this.props.cameraZoneData.zones.length >= 10
    })
    //Find max zone ID and set it to be activeZone
    const max = Math.max.apply(
      Math,
      this.props.cameraZoneData.zones.map((zone) => zone.zone_id)
    )
    const isActiveZone = this.props.cameraZoneData.zones.find((zone) => zone.zone_id === max)
    this.setActiveZone(isActiveZone)
  }

  handleLoadCameraZoneFailure() {
    const statusCode = this.props.loadCameraZoneError.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  handleEditZoneFailure() {
    const statusCode = this.props.editZoneError.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  handleDeleteZoneFailure() {
    const statusCode = this.props.deleteZoneError.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  setShowLoading(status) {
    this.setState({
      showLoading: status
    })
  }

  _resetModalData() {
    this.setState({
      modalData: {
        title: '',
        message: '',
        onSecondaryButtonClick: null,
        onPrimaryButtonClick: null,
        onOverLayClose: null,
        secondaryButtonText: 'Cancel',
        primaryButtonText: 'Confirm'
      }
    })
  }

  handleChangeZoneWithoutSave(zone) {
    const tempZones = [...this.state.cameraData.zones]
    this._resetModalData()
    this.setState({
      cameraData: _.cloneDeep(this.props.cameraZoneData),
      showModal: false,
      activeZone: zone,
      isChangeSetting: false,
      isAddNewZone: false,
      isDrawing: false,
      shouldDisabledAddZoneBtn: tempZones.length >= 10
    })
  }

  forceAddNewZone(zone) {
    this.hideModal()
    const tempZones = this.state.cameraData.zones.filter((zone) => zone.zone_id !== NEW_ZONE_ID)
    this.setState({
      cameraData: _.cloneDeep(this.props.cameraZoneData),
      isDrawing: false,
      isChangeSetting: false,
      isAddNewZone: false,
      activeZone: zone.zone_id === NEW_ZONE_ID ? tempZones[tempZones.length - 1] : zone,
      shouldDisableEditor: false,
      shouldDisabledAddZoneBtn: tempZones.length >= 10
    })
  }

  _showConfirmChangeZoneAfterEditModal(zone) {
    this.setState({
      showModal: true,
      modalData: {
        title: this.getMessage('editzone_title_modal'),
        message: this.getMessage('editzone_message_modal'),
        onSecondaryButtonClick: () => this.handleChangeZoneWithoutSave(zone),
        onPrimaryButtonClick: () => this.hideModal(),
        onOverLayClose: () => this.hideModal(),
        secondaryButtonText: this.getMessage('donteditzone_btn'),
        primaryButtonText: this.getMessage('editzone_btn')
      }
    })
  }

  _showConfirmChangeZoneAfterAddModal(zone) {
    this.setState({
      showModal: true,
      modalData: {
        title: this.getMessage('createzone_title_modal'),
        message: this.getMessage('createzone_message_modal'),
        onSecondaryButtonClick: () => this.hideModal(),
        onPrimaryButtonClick: () => this.forceAddNewZone(zone),
        onOverLayClose: () => this.hideModal(),
        secondaryButtonText: this.getMessage('addzone_btn'),
        primaryButtonText: this.getMessage('dontaddzone_btn')
      }
    })
  }

  setActiveZone(zone) {
    if (this.state.isChangeSetting) {
      // Edit a zone, doesn't save and change zone
      this._showConfirmChangeZoneAfterEditModal(zone)
    } else if (this.state.isAddNewZone) {
      //Add a zone, doesn't save and change zone
      this._showConfirmChangeZoneAfterAddModal(zone)
    } else {
      this.setState({
        activeZone: zone,
        isChangeSetting: false
      })
    }
  }

  handleConfirmGoBackClick() {
    const { projectID, siteId } = this.props.match.params
    this.props.history.replace(`${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${siteId}/zone`)
  }

  showWarningModal() {
    this.setState({
      showModal: true,
      modalData: {
        title: this.getMessage('goback_title_modal'),
        message: this.getMessage('goback_message_modal'),
        onSecondaryButtonClick: () => this.handleConfirmGoBackClick(),
        onPrimaryButtonClick: () => this.hideModal(),
        onOverLayClose: () => this.hideModal(),
        secondaryButtonText: this.getMessage('goback_btn'),
        primaryButtonText: this.getMessage('dontgoback_btn')
      }
    })
  }

  onGoBackClick() {
    if (this.state.isChangeSetting || this.state.isAddNewZone) {
      this.showWarningModal()
    } else {
      this.props.history.goBack()
    }
  }

  getBackButton() {
    return (
      <div className="back-btn-wrapper">
        <div className="text-style" onClick={() => this.onGoBackClick()}>
          {this.getMessage('back_btn')}
        </div>
      </div>
    )
  }

  confirmDeleteZone(zoneId) {
    if (zoneId === NEW_ZONE_ID) {
      const tempZones = this.state.cameraData.zones.filter((zone) => zone.zone_id !== NEW_ZONE_ID)
      this.setState({
        cameraData: _.cloneDeep(this.props.cameraZoneData),
        isAddNewZone: false,
        isChangeSetting: false,
        activeZone: _.cloneDeep(this.props.cameraZoneData.zones[this.props.cameraZoneData.zones.length - 1]),
        isDrawing: false,
        shouldDisabledAddZoneBtn: tempZones.length >= 10
      })
    } else {
      this.setState({
        isAddNewZone: false,
        isChangeSetting: false,
        isDrawing: false
      })
      this.props.deleteZone(zoneId)
    }
    this.hideModal()
  }

  hideModal() {
    this.setState({
      showModal: false,
      showDelColorRangeModal: false
    })
    this._resetModalData()
  }

  deleteZone(zoneId) {
    this.showDeleteZoneModal(zoneId)
  }

  showDeleteZoneModal(zoneId) {
    this.setState({
      showModal: true,
      modalData: {
        title: `${this.getMessage('deletezone_title_modal')}${zoneId}`,
        message: `${this.getMessage('deletezone_message_modal')}`,
        onSecondaryButtonClick: () => this.hideModal(),
        onPrimaryButtonClick: () => this.confirmDeleteZone(zoneId),
        onOverLayClose: () => this.hideModal(),
        secondaryButtonText: this.getMessage('dontdeletezone_btn'),
        primaryButtonText: this.getMessage('deletezone_btn')
      }
    })
  }

  deleteEachColorRange(rangeId, range_name) {
    this.showDeleteColorRangeModal(rangeId, range_name)
  }

  confirmDeleteColorRange(rangeId) {
    const tempColorRange = [...this.state.activeZone.color_range].filter((color) => color.cid !== rangeId)
    const tempActiveZone = Object.assign({}, this.state.activeZone, {
      color_range: tempColorRange
    })
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.color_range = tempActiveZone.color_range
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: tempActiveZone,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
    this.hideModal()
  }

  showDeleteColorRangeModal(rangeId, range_name) {
    this.setState({
      showDelColorRangeModal: true,
      willBeDelColorRangeName: range_name,
      modalData: {
        title: this.getMessage('delete_modal_title'),
        onSecondaryButtonClick: () => this.hideModal(),
        onPrimaryButtonClick: () => this.confirmDeleteColorRange(rangeId),
        onOverLayClose: () => this.hideModal(),
        secondaryButtonText: this.getMessage('dontdeletezone_btn'),
        primaryButtonText: this.getMessage('deletezone_btn')
      }
    })
  }

  getDeleteColorRangeModal() {
    let output = null
    if (this.state.showDelColorRangeModal) {
      const { modalData, willBeDelColorRangeName } = this.state
      output = (
        <ModalOverlay onClose={modalData.onOverLayClose}>
          <div className="modal-delete-color-container" onClick={(e) => e.stopPropagation()}>
            <div className="title-container">{modalData.title}</div>
            <div className="message-container">
              {this.getMessage('delete_modal_message_1')}
              <p>
                &#8220;<b>{willBeDelColorRangeName}</b>&#8221; {this.getMessage('delete_modal_message_2')}
              </p>
            </div>
            <div className="btn-group-container">
              <Button id="del-color-cancel-btn" onClick={modalData.onSecondaryButtonClick} text={modalData.secondaryButtonText} invert />
              <Button id="del-color-delete-btn" onClick={modalData.onPrimaryButtonClick} text={modalData.primaryButtonText} />
            </div>
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  showModal() {
    let output = null
    if (this.state.showModal) {
      output = (
        <ModalNotification
          title={this.state.modalData.title}
          message={this.state.modalData.message}
          onSecondaryButtonClick={this.state.modalData.onSecondaryButtonClick}
          onPrimaryButtonClick={this.state.modalData.onPrimaryButtonClick}
          onOverLayClose={this.state.modalData.onOverLayClose}
          secondaryButtonText={this.state.modalData.secondaryButtonText}
          primaryButtonText={this.state.modalData.primaryButtonText}
          language={this.props.currentUser ? this.props.currentUser.language : 'TH'}
        />
      )
    }
    return output
  }

  _showConfirmAddAnotherNewZone() {
    this.setState({
      showModal: true,
      modalData: {
        title: this.getMessage('createzone_title_modal'),
        message: this.getMessage('createzone_message_modal'),
        onSecondaryButtonClick: () => this.confirmAddNewZone(),
        onPrimaryButtonClick: () => this.hideModal(),
        onOverLayClose: () => this.hideModal(),
        secondaryButtonText: this.getMessage('addzone_btn'),
        primaryButtonText: this.getMessage('dontaddzone_btn')
      }
    })
  }

  _revertZoneThenAddZone() {
    this.hideModal()
    this.setState(
      {
        isChangeSetting: false,
        cameraData: _.cloneDeep(this.props.cameraZoneData)
      },
      () => this.handleAddZone()
    )
  }
  _showConfirmAddZoneAfterEditAnotherZone() {
    this.setState({
      showModal: true,
      modalData: {
        title: this.getMessage('editzone_title_modal'),
        message: this.getMessage('editzone_message_modal'),
        onSecondaryButtonClick: () => this._revertZoneThenAddZone(),
        onPrimaryButtonClick: () => this.hideModal(),
        onOverLayClose: () => this.hideModal(),
        secondaryButtonText: this.getMessage('donteditzone_btn'),
        primaryButtonText: this.getMessage('editzone_btn')
      }
    })
  }

  handleAddZone() {
    // There is currently newly added zone that has not yet applied
    if (this.state.isAddNewZone) {
      this._showConfirmAddAnotherNewZone()
    }
    // Change a zone and then add new zone
    else if (this.state.isChangeSetting) {
      this._showConfirmAddZoneAfterEditAnotherZone()
    } else {
      this._initializeStateBeforeAddZone()
    }
  }

  _initializeObjectMinHeightList = () => {
    return LIST_ALL_CLASS.map((classObj) => {
      return {
        class_type: classObj,
        min_value: this.state.cameraData.resolution_height / 2,
        position: { x: DRAWING_ZONE_CONTAINER_WIDTH / 2, y: DRAWING_ZONE_CONTAINER_HEIGHT / 2 }
      }
    })
  }

  _initializeStateBeforeAddZone() {
    const initialZoneData = {
      allowed: false,
      zone_id: NEW_ZONE_ID,
      camera_id: this.state.cameraData.camera_id,
      points: [],
      conditions: null,
      features: [
        {
          class_confidence: 0.6,
          class_type: '',
          color_confidence: 0.6,
          color_type: 'blue'
        }
      ],
      grid_color: LIST_ZONE_COLORS[Math.floor(Math.random() * 10)],
      time_range: {},
      violation_id: 0,
      violation_name: '',
      object_size: this._initializeObjectMinHeightList(),
      color_range: []
    }
    const tempZones = this.state.cameraData.zones.filter((zone) => zone.zone_id !== NEW_ZONE_ID)
    tempZones.push(initialZoneData)
    this.setState({
      cameraData: Object.assign({}, this.props.cameraZoneData, {
        zones: tempZones
      }),
      activeZone: initialZoneData,
      isDrawing: true,
      isAddNewZone: true,
      shouldDisabledAddZoneBtn: tempZones.length >= 10,
      shouldDisableEditor: (this.state.isDrawing && this.state.isAddNewZone) || initialZoneData.points.length < 3
    })
  }

  confirmAddNewZone() {
    this.hideModal()
    this.setState(
      {
        isAddNewZone: false,
        isChangeSetting: false
      },
      () => this.handleAddZone()
    )
  }

  handleFinishDrawingNewZone() {
    const numOfActiveZonePoints = this.state.activeZone.points.length
    this.setState({
      isDrawing: false,
      invalidPolygon: numOfActiveZonePoints.length <= 3,
      shouldDisableEditor: this.state.isAddNewZone && numOfActiveZonePoints.length <= 3
    })
  }

  handleDrawingZone(drawingZoneData, localCoordinate) {
    const tempZones = [...this.state.cameraData.zones]
    const drawingZone = tempZones.find((zoneData) => zoneData.zone_id === drawingZoneData.zone_id)
    drawingZone.points = [...drawingZone.points, [Math.round(localCoordinate.x), Math.round(localCoordinate.y)]]
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: drawingZone,
      isChangeSetting: true,
      validZone: this.isValidZone(drawingZone),
      invalidPolygon: drawingZone.points.length <= 3 && this.state.isDrawing
    })
  }

  handleAddSpot(drawingZoneData, coordinateIndex, localPoint) {
    const tempZones = [...this.state.cameraData.zones]
    const drawingZone = tempZones.find((zoneData) => zoneData.zone_id === drawingZoneData.zone_id)
    const theFirstSegment = drawingZone.points.slice(0, (coordinateIndex % drawingZone.points.length) + 1)
    const theRestSegment = drawingZone.points.slice((coordinateIndex % drawingZone.points.length) + 1)
    drawingZone.points = [...theFirstSegment, [Math.round(localPoint.x), Math.round(localPoint.y)], ...theRestSegment]
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      isChangeSetting: true,
      activeZone: drawingZone,
      validZone: this.isValidZone(drawingZone),
      invalidPolygon: drawingZone.points.length <= 3 && this.state.isDrawing,
      shouldDisableEditor: drawingZone.points.length < 3 || (this.state.isDrawing && this.state.isAddNewZone)
    })
  }

  handleDeleteSpot(drawingZoneData, coordinateIndex, localPoint) {
    const tempZones = [...this.state.cameraData.zones]
    const drawingZone = tempZones.find((zoneData) => zoneData.zone_id === drawingZoneData.zone_id)
    const theFirstSegment = drawingZone.points.slice(0, coordinateIndex % drawingZone.points.length)
    const theRestSegment = drawingZone.points.slice((coordinateIndex % drawingZone.points.length) + 1)
    drawingZone.points = [...theFirstSegment, ...theRestSegment]
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: drawingZone,
      isDrawing: drawingZone.points.length === 0,
      isChangeSetting: true,
      validZone: this.isValidZone(drawingZone),
      invalidPolygon: drawingZone.points.length <= 3 && this.state.isDrawing,
      shouldDisableEditor: drawingZone.points.length < 3 || (this.state.isDrawing && this.state.isAddNewZone)
    })
  }

  handleCoordinateChanged(drawingZoneData, coordinateIndex, localPoint) {
    const tempZones = [...this.state.cameraData.zones]
    const drawingZone = tempZones.find((zoneData) => drawingZoneData.zone_id === zoneData.zone_id)
    drawingZone.points[coordinateIndex] = [Math.round(localPoint.x), Math.round(localPoint.y)]
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: drawingZone,
      isChangeSetting: true,
      validZone: this.isValidZone(drawingZone),
      invalidPolygon: drawingZone.points.length <= 3 && this.state.isDrawing,
      shouldDisableEditor: drawingZone.points.length < 3 || (this.state.isDrawing && this.state.isAddNewZone)
    })
  }

  handleLinkImageInvalid = () => {
    this.showNoImageModal()
  }

  updateIconPosition(position, classObj) {
    const tempObjectSize = [...this.state.activeZone.object_size].map((objectSize) => {
      if (objectSize.class_type === classObj.class_type) {
        return Object.assign({}, objectSize, { position })
      }
      return objectSize
    })
    const tempZones = [...this.state.cameraData.zones]
    const draggingIconZone = tempZones.find((zoneData) => zoneData.zone_id === this.state.activeZone.zone_id)
    draggingIconZone.object_size = tempObjectSize

    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: Object.assign({}, this.state.activeZone, {
        object_size: tempObjectSize
      }),
      isChangeSetting: draggingIconZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: draggingIconZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(draggingIconZone),
      invalidPolygon: draggingIconZone.points.length <= 3 && this.state.isDrawing
    })
  }

  getDrawComponent() {
    let output = null
    if (this.props.loadCameraZoneStatus !== FETCH_STATUS_REQUEST && this.state.cameraData) {
      output = (
        <div className="src-zone-wrapper">
          <DrawZoneComponent
            cameraId={this.state.cameraData.camera_id}
            allZone={this.state.cameraData.zones}
            imgResolutionWidth={this.state.cameraData.resolution_width}
            imgResolutionHeight={this.state.cameraData.resolution_height}
            imgSrc={this.state.cameraData.image}
            containerWidth={DRAWING_ZONE_CONTAINER_WIDTH}
            containerHeight={DRAWING_ZONE_CONTAINER_HEIGHT}
            activeZone={this.state.activeZone}
            isDrawing={this.state.isDrawing}
            onCoordinateChanged={(zoneData, coordinateIndex, localPoint) => this.handleCoordinateChanged(zoneData, coordinateIndex, localPoint)}
            onDrawing={(drawingZone, localCoordinate) => this.handleDrawingZone(drawingZone, localCoordinate)}
            onFinishDrawing={() => this.handleFinishDrawingNewZone()}
            onAddSpot={(zoneData, coordinateIndex, localPoint) => this.handleAddSpot(zoneData, coordinateIndex, localPoint)}
            onDeleteSpot={(shapeIndex, coordinateIndex, localPoint) => this.handleDeleteSpot(shapeIndex, coordinateIndex, localPoint)}
            onLinkImageInvalid={this.handleLinkImageInvalid}
            shouldDisableEditor={this.state.shouldDisableEditor}
            updateIconPosition={(position, classObj) => this.updateIconPosition(position, classObj)}
          />
          <ZoneEditingCard
            data={this.state.cameraData}
            language={this.props.currentUser?.language || THAI_LANGUAGE}
            setActiveZone={(zone) => this.setActiveZone(zone)}
            activeZone={this.state.activeZone}
            deleteZoneClick={(zoneId) => this.deleteZone(zoneId)}
            onAddZone={() => this.handleAddZone()}
            shouldDisabledAddZoneBtn={this.state.shouldDisabledAddZoneBtn}
          />
        </div>
      )
    }
    return output
  }

  handleUpdateZone() {
    this.setState({
      isChangeSetting: false
    })
    const tempZone = this._scaleDownSmallObject(this.state.activeZone)
    this.props.editZone(tempZone)
  }

  findViolationId(violationName) {
    const violation = this.props.listViolations.find((vio) => vio.name === violationName)
    if (violation) {
      return violation.violation_id
    }
    return 0
  }

  isValidZone(zone) {
    const hasViolationName = zone.violation_name !== ''
    const isUnauthorizedZone = zone.violation_name === 'Unauthorized' || this.findViolationId(zone.violation_name) === 1
    const targetIsVehicle = zone.features.length > 0 && zone.features[0].class_type === TRUCK_TARGET
    const hasViolationId = zone.violation_id !== 0
    const has3Points = zone.points.length >= 3
    const hasFeature = zone.features.length > 0
    const hasFeatureClassName = hasFeature && zone.features[0].class_type !== ''
    const hasColorRange = zone.color_range.length > 0
    const hasGridColor = zone.grid_color !== ''

    if (has3Points && hasViolationName && hasViolationId && hasFeature && hasColorRange && hasFeatureClassName && hasGridColor) {
      return true
    } else if (has3Points && isUnauthorizedZone && hasViolationId && hasFeature && hasFeatureClassName && hasGridColor) {
      return true
    } else if (has3Points && hasFeature && targetIsVehicle && hasViolationName && hasViolationId && hasGridColor) {
      return true
    } else {
      return false
    }
  }

  _scaleDownSmallObject = (zone) => {
    const tempObjectSize = [...zone.object_size]
    return Object.assign({}, zone, {
      object_size: tempObjectSize.map((eachObj) => {
        return { ...eachObj, min_value: parseFloat((eachObj.min_value * 0.8).toFixed(2)) }
      })
    })
  }

  handleOnAddZone() {
    if (this.isValidZone(this.state.activeZone)) {
      const tempZone = this._scaleDownSmallObject(this.state.activeZone)
      this.props.addZone(tempZone)
      this.setState({
        isChangeSetting: false,
        isAddNewZone: false
      })
    }
  }

  handleViolationChange(violation_name, violation_id) {
    let tempActiveZone = Object.assign({}, this.state.activeZone, { ...this.state.activeZone, violation_name, violation_id })
    if (violation_name === UNAUTHORIZED_EVENT) {
      tempActiveZone = Object.assign({}, this.state.activeZone, { ...this.state.activeZone, violation_name, violation_id, allowed: false })
    }
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.violation_name = violation_name
    isActiveZone.violation_id = violation_id
    isActiveZone.allowed = tempActiveZone.allowed
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: tempActiveZone,
      invalidPolygon: tempActiveZone.points.length <= 3 && this.state.isDrawing,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(tempActiveZone)
    })
  }

  handleToggleAllow(isAllow) {
    const tempActiveZone = Object.assign({}, this.state.activeZone, { ...this.state.activeZone, allowed: isAllow })
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.allowed = isAllow
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: tempActiveZone,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
  }

  handleTargetConfidenceChange(e, class_confidence) {
    const tempFeatures = [...this.state.activeZone.features]
    const tempActiveZone = Object.assign({}, this.state.activeZone, {
      features: tempFeatures.map((feature) => {
        return Object.assign({}, feature, { ...feature, class_confidence })
      })
    })
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.features = tempActiveZone.features
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: tempActiveZone,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
  }

  handleEachTargetMinimumHeightChange(objectSizeList) {
    const tempActiveZone = Object.assign({}, this.state.activeZone, {
      object_size: objectSizeList
    })
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.object_size = objectSizeList
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: tempActiveZone,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
  }

  handleEachColorTargetChange(updatedFeatures) {
    const tempActiveZone = Object.assign({}, this.state.activeZone, {
      features: updatedFeatures
    })
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.features = updatedFeatures
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: tempActiveZone,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
  }

  revertBackColorRange = (cid) => {
    // Find color range from data fecthed
    const propsActiveZone = [...this.props.cameraZoneData.zones].find((zone) => zone.zone_id === this.state.activeZone.zone_id)

    let willBeRevertBackColor
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === this.state.activeZone.zone_id)
    const tempActiveZone = { ...this.state.activeZone }
    if (propsActiveZone) {
      willBeRevertBackColor = propsActiveZone.color_range.find((color) => color.cid === cid)
      //if found just push it back to state
      if (willBeRevertBackColor) {
        const tempColorRangeList = isActiveZone.color_range.reduce((listColor, color) => {
          if (color.cid !== willBeRevertBackColor.cid) {
            listColor.push(color)
          } else {
            listColor.push(willBeRevertBackColor)
          }
          return listColor
        }, [])
        isActiveZone.color_range = tempColorRangeList
        tempActiveZone.color_range = tempColorRangeList
      } else {
        const tempList = isActiveZone.color_range.filter((color) => color.range_name !== '')
        isActiveZone.color_range = tempList
        tempActiveZone.color_range = tempList
      }
    } else {
      const tempColorRanges = isActiveZone.color_range.filter((color) => {
        return color.range_name !== ''
      })
      isActiveZone.color_range = tempColorRanges
      tempActiveZone.color_range = tempColorRanges
    }

    this.setState({
      activeZone: tempActiveZone,
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
  }

  handleColorRangeListChange(newListColorRanges) {
    const tempActiveZone = Object.assign({}, this.state.activeZone, {
      color_range: newListColorRanges
    })
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.color_range = newListColorRanges
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: tempActiveZone,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
  }

  handleConditionChange(conditionData, conditionObject) {
    let tempActiveZone
    if (conditionData.checked) {
      tempActiveZone = Object.assign({}, this.state.activeZone, {
        conditions: { [ANY_CONDITION_KEY]: [conditionObject] }
      })
    } else {
      tempActiveZone = Object.assign({}, this.state.activeZone, {
        conditions: null
      })
    }

    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.conditions = tempActiveZone.conditions
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: isActiveZone,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
  }

  handleGridColorChange(color) {
    const tempActiveZone = Object.assign({}, this.state.activeZone, {
      grid_color: color
    })
    const tempZones = [...this.state.cameraData.zones]
    const isActiveZone = tempZones.find((zone) => zone.zone_id === tempActiveZone.zone_id)
    isActiveZone.grid_color = tempActiveZone.grid_color
    this.setState({
      cameraData: Object.assign({}, this.state.cameraData, {
        zones: tempZones
      }),
      activeZone: tempActiveZone,
      isChangeSetting: tempActiveZone.zone_id !== NEW_ZONE_ID,
      isAddNewZone: tempActiveZone.zone_id === NEW_ZONE_ID,
      validZone: this.isValidZone(isActiveZone),
      invalidPolygon: isActiveZone.points.length <= 3 && this.state.isDrawing
    })
  }

  createViolationOptions() {
    const tempListViolations = this.props.listViolations
      .filter((violation) => violation.name !== LINE_CROSSING_EVENT)
      .map((violation) => {
        return { text: this.getMessage(violation.name), value: violation.name, violation_id: violation.violation_id }
      })
    tempListViolations.splice(0, 0, { text: `-- ${this.getMessage('select_event_dropdown')} --`, value: '', violation_id: 0 })
    return tempListViolations
  }

  createTargetOptions() {
    const tempListTarget = this.props.listClasses.map((listClass) => ({
      text: makeFirstLetterCapital(this.getMessage(listClass.type)),
      value: listClass.type
    }))

    tempListTarget.splice(0, 0, { text: `-- ${this.getMessage('select_target_dropdown')} --`, value: '' })
    return tempListTarget
  }

  _setChangeSetting = (status) => {
    this.setState({
      isChangeSetting: status
    })
  }

  getRuleSetting() {
    let output = null
    const violationOptions = this.createViolationOptions()
    const targetOptions = this.createTargetOptions()

    if (this.props.loadCameraZoneStatus !== FETCH_STATUS_REQUEST && this.state.activeZone) {
      output = (
        <RuleEditor
          language={this.props.currentUser?.language || THAI_LANGUAGE}
          listViolations={this.props.listViolations}
          listColorRanges={this.props.listColorRanges}
          activeZone={this.state.activeZone}
          violationOptions={violationOptions}
          targetOptions={targetOptions}
          onUpdateZone={() => this.handleUpdateZone()}
          handleOnAddZone={() => this.handleOnAddZone()}
          handleViolationChange={(violationName, violationId) => this.handleViolationChange(violationName, violationId)}
          handleToggleAllow={(isAllow) => this.handleToggleAllow(isAllow)}
          handleTargetConfidenceChange={(e, confidence) => this.handleTargetConfidenceChange(e, confidence)}
          handleEachTargetMinimumHeightChange={(objectSizeList) => this.handleEachTargetMinimumHeightChange(objectSizeList)}
          handleEachColorTargetChange={(newFeatures) => this.handleEachColorTargetChange(newFeatures)}
          handleColorRangeListChange={(newListColorRanges) => this.handleColorRangeListChange(newListColorRanges)}
          revertBackColorRange={this.revertBackColorRange}
          deleteEachColorRange={(id, range_name) => this.deleteEachColorRange(id, range_name)}
          handleConditionChange={(conditionData, conditionObject) => this.handleConditionChange(conditionData, conditionObject)}
          handleGridColorChange={(color) => this.handleGridColorChange(color)}
          isChangeSetting={this.state.isChangeSetting}
          setChangeSetting={(isSetting) => this._setChangeSetting(isSetting)}
          invalidPolygon={this.state.invalidPolygon}
          isAddNewZone={this.state.isAddNewZone}
          validZone={this.state.validZone}
          isDrawing={this.state.isDrawing}
          shouldDisableEditor={this.state.shouldDisableEditor}
          cameraResolutionHeight={this.props.cameraZoneData.resolution_height}
          isRecalculatingZone={this.props.isRecalculatingZone}
        />
      )
    }
    return output
  }

  getContent() {
    let output = (
      <>
        {this.getDrawComponent()}
        {this.getRuleSetting()}
      </>
    )
    return output
  }

  getLoadingModal() {
    let output = null
    if (this.state.showLoading) {
      output = (
        <ModalOverlay>
          <Spinner />
        </ModalOverlay>
      )
    }
    return output
  }
  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <EachCameraZoneStyled>
        <ListBoardWrapper title={this.state.cameraData?.name || ''}>
          {this.getBackButton()}
          <DashboardTop alignMent="horizontal">{this.getContent()}</DashboardTop>
        </ListBoardWrapper>
        {this.showModal()}
        {this.getLoadingModal()}
        {this.getDeleteColorRangeModal()}
        {this.getFailureModal()}
      </EachCameraZoneStyled>
    )
  }
}

EachCameraZonePage.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    language: PropTypes.string
  }),
  cameraZoneData: PropTypes.shape({
    area_id: PropTypes.number,
    area_name: PropTypes.string,
    camera_id: PropTypes.number,
    camera_status: PropTypes.string,
    health_check_time: PropTypes.string,
    image: PropTypes.string,
    is_active: PropTypes.bool,
    name: PropTypes.string,
    project_id: PropTypes.number,
    resolution_height: PropTypes.number,
    resolution_width: PropTypes.number,
    site_id: PropTypes.number,
    zones: PropTypes.arrayOf(
      PropTypes.shape({
        allowed: PropTypes.bool,
        camera_id: PropTypes.number,
        grid_color: PropTypes.string,
        features: PropTypes.arrayOf(
          PropTypes.shape({
            class_confidence: PropTypes.number,
            class_type: PropTypes.string,
            color_confidence: PropTypes.number,
            color_type: PropTypes.string
          })
        ),
        points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        violation_id: PropTypes.number,
        violation_name: PropTypes.string,
        zone_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }).isRequired,
  listViolations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      class_id: PropTypes.number
    })
  ).isRequired,
  listClasses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    })
  ),
  listColorRanges: PropTypes.arrayOf(
    PropTypes.shape({
      min_hue: PropTypes.number,
      max_hue: PropTypes.number,
      name: PropTypes.string,
      range_name: PropTypes.string
    })
  ),
  loadCameraZoneStatus: PropTypes.string,
  deleteZoneStatus: PropTypes.string,
  loadZoneStatus: PropTypes.string
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    currentUser: state.loginPage.loginAPI.userData,
    cameraZoneData: state.eachCameraZonePage.cameraZoneData,
    listViolations: state.eachCameraZonePage.listViolations,
    listClasses: state.eachCameraZonePage.listClasses,
    listColorRanges: state.eachCameraZonePage.listColorRanges,
    loadCameraZoneStatus: state.eachCameraZonePage.loadCameraZoneData.fetchStatus,
    loadCameraZoneError: state.eachCameraZonePage.loadCameraZoneData.error,
    editZoneStatus: state.eachCameraZonePage.editZoneStatus.fetchStatus,
    editZoneError: state.eachCameraZonePage.editZoneStatus.error,
    deleteZoneStatus: state.eachCameraZonePage.deleteCameraZoneData.fetchStatus,
    deleteZoneError: state.eachCameraZonePage.deleteCameraZoneData.error,
    loadZoneStatus: state.eachCameraZonePage.loadZoneData.fetchStatus,
    syncCameraZoneListStatus: state.allCameraZonePage.loadListZone.syncStatus,
    isRecalculatingZone: state.eachCameraZonePage.isRecalculatingZone
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, EachCameraZoneActions), dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EachCameraZonePage)
