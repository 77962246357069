const colors = {
  jadeGreen: '#27a448',
  jadeGreenHover: '#249a43',
  darkBackground: '#1f2d39',
  greyDisabled: '#d8d8d8',
  cardTitle: '#eef7ff',
  greenFont: '#73b500',
  onlineCamera: '#7ed321',
  inactiveCamera: '#f5a623',
  offlineCamera: '#d0021b',
  redFont: '#d0021b',
  flagRedFont: '#ff072d',
  orangeFont: '#f16745',
  greyNotificationRead: '#354654',
  scrollbarBackground: '#f5f5f5',
  scrollbarThumb: '#b5b5b5',
  placeholderColor: '#979797',
  scrollbarThumbDark: '#34495d',
  borderDropdown: '#d9d9d9',
  initialBackgroundTag: '#00b5ad',
  wipBackgroundTag: '#e47400',
  cameraAssessBackgroundTag: '#e400bd',
  imgBackground: '#ddd',
  dotBadgeColor: '#ff364f',
  barChartFontColor: '#4a4a4a',
  optionalText: '#969696'
}

export default colors
