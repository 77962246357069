import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../../utils'

import {
  LOAD_VIOLATION_REQUEST,
  LOAD_VIOLATION_SUCCESS,
  LOAD_VIOLATION_IDLE,
  LOAD_VIOLATION_FAILURE,
  SYNC_VIOLATION_REQUEST,
  SYNC_VIOLATION_SUCCESS,
  SYNC_VIOLATION_FAILURE,
  SYNC_VIOLATION_IDLE
} from '../actions'

import { UPDATE_CAMERA_SOCKET } from '../../NotificationSidebar/actions'

const initialState = {
  allCameraDetails: [],
  loadAllCameraDetail: {
    fetchStatus: FETCH_STATUS_IDLE,
    syncStatus: FETCH_STATUS_IDLE
  }
}

const preparedCameraDetailsGraph = (listCameraDetail) => {
  const preparedListCameraDetail = listCameraDetail.map((cameraDetail) => {
    const sortedGraph = cameraDetail.activity_graph.sort((a, b) => {
      if (a.label < b.label) {
        return -1
      } else {
        return 1
      }
    })
    return Object.assign({}, cameraDetail, {
      activity_graph: sortedGraph
    })
  })
  return preparedListCameraDetail
}

const allViolationDetailPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VIOLATION_REQUEST: {
      return Object.assign({}, state, {
        loadAllCameraDetail: Object.assign({}, state.loadAllCameraDetail, {
          fetchStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case LOAD_VIOLATION_SUCCESS: {
      const preparedAllCameraDetails = preparedCameraDetailsGraph(action.payload.allCameraData)
      return Object.assign({}, state, {
        allCameraDetails: preparedAllCameraDetails,
        loadAllCameraDetail: Object.assign({}, state.loadAllCameraDetail, {
          fetchStatus: FETCH_STATUS_SUCCESS
        }),
        areaCount: action.payload.areaCount
      })
    }
    case LOAD_VIOLATION_FAILURE: {
      return Object.assign({}, state, {
        loadAllCameraDetail: Object.assign({}, state.loadAllCameraDetail, {
          fetchStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case LOAD_VIOLATION_IDLE: {
      return Object.assign({}, state, {
        loadAllCameraDetail: Object.assign({}, state.loadAllCameraDetail, {
          fetchStatus: FETCH_STATUS_IDLE
        })
      })
    }

    case SYNC_VIOLATION_REQUEST: {
      return Object.assign({}, state, {
        loadAllCameraDetail: Object.assign({}, state.loadAllCameraDetail, {
          syncStatus: FETCH_STATUS_REQUEST
        })
      })
    }
    case SYNC_VIOLATION_SUCCESS: {
      return Object.assign({}, state, {
        allCameraDetails: preparedCameraDetailsGraph(action.payload.allCameraData),
        loadAllCameraDetail: Object.assign({}, state.loadAllCameraDetail, {
          syncStatus: FETCH_STATUS_SUCCESS
        }),
        areaCount: action.payload.areaCount
      })
    }
    case SYNC_VIOLATION_FAILURE: {
      return Object.assign({}, state, {
        loadAllCameraDetail: Object.assign({}, state.loadAllCameraDetail, {
          syncStatus: FETCH_STATUS_FAILURE,
          error: action.payload.error
        })
      })
    }
    case SYNC_VIOLATION_IDLE: {
      return Object.assign({}, state, {
        loadAllCameraDetail: Object.assign({}, state.loadAllCameraDetail, {
          syncStatus: FETCH_STATUS_IDLE
        })
      })
    }
    case UPDATE_CAMERA_SOCKET: {
      const { upComingCameraData } = action.payload
      const updatedCameraDetailList = state.allCameraDetails.map((detail) => {
        if (detail.camera.camera_id === upComingCameraData.notification_object.camera_id) {
          return Object.assign({}, detail, {
            camera: Object.assign({}, detail.camera, {
              camera_status: upComingCameraData.notification_object.camera_status,
              image: upComingCameraData.notification_object.camera_image ? upComingCameraData.notification_object.camera_image : detail.camera.image
            })
          })
        }
        return detail
      })
      return Object.assign({}, state, {
        allCameraDetails: updatedCameraDetailList
      })
    }

    default: {
      return state
    }
  }
}

export default allViolationDetailPage
