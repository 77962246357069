import { get } from '../../../utils'

const API_VERSION = process.env.API_VERSION

export const LOAD_CAMERA_ZONE_LIST_REQUEST = 'LOAD_CAMERA_ZONE_LIST_REQUEST'
export const LOAD_CAMERA_ZONE_LIST_SUCCESS = 'LOAD_CAMERA_ZONE_LIST_SUCCESS'
export const LOAD_CAMERA_ZONE_LIST_FAILURE = 'LOAD_CAMERA_ZONE_LIST_FAILURE'
export const LOAD_CAMERA_ZONE_LIST_IDLE = 'LOAD_CAMERA_ZONE_LIST_IDLE'

const loadCameraZoneListRequest = () => {
  return {
    type: LOAD_CAMERA_ZONE_LIST_REQUEST
  }
}

const loadCameraZoneListSuccess = (cameraZoneList) => {
  return {
    type: LOAD_CAMERA_ZONE_LIST_SUCCESS,
    payload: {
      cameraZoneList
    }
  }
}

const loadCameraZoneListFailure = (error) => {
  return {
    type: LOAD_CAMERA_ZONE_LIST_FAILURE,
    payload: {
      error
    }
  }
}

const loadCameraZoneListIdle = () => {
  return {
    type: LOAD_CAMERA_ZONE_LIST_IDLE
  }
}

export const loadCameraZoneList = (siteId) => {
  return async (dispatch) => {
    try {
      dispatch(loadCameraZoneListRequest())
      const cameraZoneListResponse = await get({
        url: `/${API_VERSION}/sites/${siteId}/cameras`
      })
      dispatch(loadCameraZoneListSuccess(cameraZoneListResponse))
    } catch (err) {
      dispatch(loadCameraZoneListFailure(err))
    } finally {
      dispatch(loadCameraZoneListIdle())
    }
  }
}

export const SYNC_CAMERA_ZONE_LIST_REQUEST = 'SYNC_CAMERA_ZONE_LIST_REQUEST'
export const SYNC_CAMERA_ZONE_LIST_SUCCESS = 'SYNC_CAMERA_ZONE_LIST_SUCCESS'
export const SYNC_CAMERA_ZONE_LIST_FAILURE = 'SYNC_CAMERA_ZONE_LIST_FAILURE'
export const SYNC_CAMERA_ZONE_LIST_IDLE = 'SYNC_CAMERA_ZONE_LIST_IDLE'

const syncCameraZoneListRequest = () => {
  return {
    type: SYNC_CAMERA_ZONE_LIST_REQUEST
  }
}

const syncCameraZoneListSuccess = (cameraZoneList) => {
  return {
    type: SYNC_CAMERA_ZONE_LIST_SUCCESS,
    payload: {
      cameraZoneList
    }
  }
}

const syncCameraZoneListFailure = (error) => {
  return {
    type: SYNC_CAMERA_ZONE_LIST_FAILURE,
    payload: {
      error
    }
  }
}

const syncCameraZoneListIdle = () => {
  return {
    type: SYNC_CAMERA_ZONE_LIST_IDLE
  }
}

export const syncCameraZoneList = (siteId) => {
  return async (dispatch) => {
    try {
      dispatch(syncCameraZoneListRequest())
      const cameraZoneListResponse = await get({
        url: `/${API_VERSION}/sites/${siteId}/cameras`
      })
      dispatch(syncCameraZoneListSuccess(cameraZoneListResponse))
    } catch (err) {
      dispatch(syncCameraZoneListFailure(err))
    } finally {
      dispatch(syncCameraZoneListIdle())
    }
  }
}
