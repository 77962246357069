export const UPDATE_DATE_PICKER = 'UPDATE_DATE_PICKER'

const updateDatePickerSuccess = (date) => {
  return {
    type: UPDATE_DATE_PICKER,
    payload: {
      date
    }
  }
}

export const updateDatePicker = (date) => {
  return async (dispatch) => {
    dispatch(updateDatePickerSuccess(date))
  }
}

export const UPDATE_DATE_FILTER = 'UPDATE_DATE_FILTER'

const updateDateFilterSuccess = (dateFilterType) => {
  return {
    type: UPDATE_DATE_FILTER,
    payload: {
      dateFilterType
    }
  }
}

export const updateDateFilter = (dateFilterType) => {
  return async (dispatch) => {
    dispatch(updateDateFilterSuccess(dateFilterType))
  }
}

export const USE_CURRENT_DATE_PICKER_AND_FILTER_SUCCESS = 'USE_CURRENT_DATE_PICKER_AND_FILTER_SUCCESS'
const setUseCurrentDateFilterSuccess = (shouldUseCurrent) => {
  return {
    type: USE_CURRENT_DATE_PICKER_AND_FILTER_SUCCESS,
    payload: {
      useCurrentDate: shouldUseCurrent
    }
  }
}

export const setUseCurrentDateFilter = (shouldUseCurrent) => {
  return async (dispatch) => {
    dispatch(setUseCurrentDateFilterSuccess(shouldUseCurrent))
  }
}
