/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Item, MenuProvider, contextMenu, animation } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.min.css'

import DrawZoneStyled from './styledComponent'
import * as PIXI from 'pixi.js'
import _ from 'lodash'
import testImage from '../../asset/images/default.png'
import PencilImage from '../../asset/images/draw-zone/pencil.svg'
import HumanIcon from '../../asset/images/draw-zone/man.svg'
import TruckIcon from '../../asset/images/draw-zone/truck.svg'

import { LIST_ZONE_COLORS, PERSON_TARGET, TRUCK_TARGET, ANY_CONDITION_KEY } from '../../utils'
import withScreenshotBase64 from '../../utils/hoc/withScreenshotBase64'

const LEFT_CLICK = 1
const RIGHT_CLICK = 3
const CONTEXT_MENU = 'CONTEXT_MENU'

class DrawZoneComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isDragging: false,
      draggingZonePolygon: undefined,
      draggingIndexCircle: undefined,
      showDrawZoneInstruction: false
    }
    let applicationStage = undefined
    let containerImage = undefined
    let imageSprite = undefined
    let containerShape = undefined
    let iconContainerShape = undefined
    let screenshotWidth = undefined
    let screenshotHeight = undefined
  }
  componentDidMount() {
    this.createApplicationStage()
    this.createImageContainer()
    this.createShapeContainer()
    this.createIconContainerShape()
    this.removePIXIConsole()
    const preparedAllZoneData = this.prepareDataGenerateShape(this.props.allZone)
    this.generateShape(preparedAllZoneData)
    this.setupKeys()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeZone !== this.props.activeZone || prevProps.allZone !== this.props.allZone) {
      this.updateCoordinates()
    }
    this.handleDrawingChanged(prevProps)
  }

  componentWillUnmount() {
    this.applicationStage.destroy()
  }

  handleDrawingChanged(prevProps) {
    if (prevProps.isDrawing && !this.props.isDrawing) {
      this.setShowDrawZoneInstruction(false)
      this.imageSprite.off('pointerdown', this.handleCreatePolygonCoordinate)
      this.containerShape.off('pointerdown', this.handleCreatePolygonCoordinate)
      this.updateCoordinates()
    }
    if (!prevProps.isDrawing && this.props.isDrawing) {
      this.setShowDrawZoneInstruction(true)
      this.imageSprite.on('pointerdown', this.handleCreatePolygonCoordinate)
      this.containerShape.on('pointerdown', this.handleCreatePolygonCoordinate)
      this.updateCoordinates()
      this.iconContainerShape.removeChildren()
    }
    const prevPropsMinHeightObj = prevProps.activeZone?.object_size
    const propsMinHeightObj = this.props.activeZone?.object_size
    if (prevPropsMinHeightObj !== propsMinHeightObj && this._shouldRenderObjectMinHeight()) {
      this.drawIconObjectHeight()
    }
    if (typeof this.props.activeZone === 'undefined' || this.props.activeZone?.features.length === 0) {
      this._clearAllIcon()
    }
  }

  removePIXIConsole() {
    PIXI.utils.skipHello()
  }

  prepareDataGenerateShape(allZoneData) {
    return allZoneData.map((zoneData) => {
      const coordinates = zoneData.points.map(([x, y]) => new PIXI.Point(x, y))
      if (zoneData.points.length > 0) {
        const [x, y] = zoneData.points[0]
        coordinates.push(new PIXI.Point(x, y))
      }
      return Object.assign({}, zoneData, {
        coordinates
      })
    })
  }

  updateCoordinates() {
    const preparedAllZoneData = this.prepareDataGenerateShape(this.props.allZone)
    this.generateShape(preparedAllZoneData)
  }

  _clearAllIcon() {
    this.iconContainerShape.removeChildren()
  }

  drawIconObjectHeight() {
    this._clearAllIcon()
    this._renderObjectDetectionIcon()
  }

  setShowDrawZoneInstruction(value) {
    this.setState({
      showDrawZoneInstruction: value
    })
  }

  setupKeys() {
    document.body.onkeydown = (e) => {
      if (e.code === 'Escape' && this.props.isDrawing) {
        this.props.onFinishDrawing()
      }
    }
  }

  createApplicationStage() {
    const canvas = document.getElementById(`canvas-${this.props.cameraId}`)
    this.applicationStage = new PIXI.Application(this.props.containerWidth, this.props.containerHeight, {
      autoResize: true,
      backgroundColor: 0x1f2d39,
      resolution: 1,
      antialias: true
    })
    canvas.appendChild(this.applicationStage.view)
  }

  createImageContainer() {
    this.containerImage = new PIXI.Container()
    let texture
    const imageSrc = this.props.imgSrc ? this.props.imgSrc : testImage
    texture = PIXI.Texture.from(imageSrc)
    this.imageSprite = new PIXI.Sprite(texture)
    if (texture.baseTexture.hasLoaded) {
      this.screenshotWidth = texture.width
      this.screenshotHeight = texture.height
      if (this.containerShape) {
        this.updateCoordinates()
      }
    } else {
      texture.on('update', () => {
        this.screenshotWidth = texture.width
        this.screenshotHeight = texture.height
        this.updateCoordinates()
      })
    }
    this.imageSprite.width = this.props.containerWidth
    this.imageSprite.height = this.props.containerHeight
    this.imageSprite.interactive = true
    this.containerImage.addChild(this.imageSprite)
    this.applicationStage.stage.addChild(this.containerImage)
  }

  handleCreatePolygonCoordinate = (e) => {
    if (e.data.originalEvent.which === LEFT_CLICK) {
      const localCoordinate = this.imageSprite.toLocal(e.data.global)
      const preparedPoint = {
        x: localCoordinate.x * (this.props.imgResolutionWidth / this.screenshotWidth),
        y: localCoordinate.y * (this.props.imgResolutionHeight / this.screenshotHeight)
      }
      const drawingZone = this.props.allZone.find((zoneData) => zoneData.zone_id === this.props.activeZone.zone_id)
      this.setShowDrawZoneInstruction(false)
      this.props.onDrawing(drawingZone, preparedPoint)
    }
  }

  createShapeContainer() {
    this.containerShape = new PIXI.Container()
    this.containerShape.interactive = true
    this.applicationStage.stage.addChild(this.containerShape)
  }

  createIconContainerShape() {
    this.iconContainerShape = new PIXI.Container()
    this.iconContainerShape.interactive = true
    this.applicationStage.stage.addChild(this.iconContainerShape)
  }

  _shouldRenderObjectMinHeight() {
    const hasTarget = this.props.activeZone?.features.filter((feature) => feature.class_type !== '').length > 0
    return hasTarget && !this.props.shouldDisableEditor
  }

  generateShape(allZoneData) {
    this.containerShape.removeChildren()
    this.generatePolygon(allZoneData)
    this.generateDot(allZoneData)
    if (this._shouldRenderObjectMinHeight()) {
      this.iconContainerShape.removeChildren()
      this._renderObjectDetectionIcon()
    }
  }

  onIconDraggedStart(iconIndex) {
    this.setState({
      isIconDragging: true,
      isDragging: false,
      iconIndex
    })
  }

  handleClickIcon(e, iconIndex) {
    if (e.data.originalEvent.which === LEFT_CLICK) {
      this.onIconDraggedStart(iconIndex)
    }
  }

  onIconDraggedEnd() {
    this.setState({
      isIconDragging: false,
      isDragging: false,
      iconIndex: undefined
    })
  }

  onIconDraggedMove(e, personClassObject, iconIndex) {
    if (this.state.isIconDragging && iconIndex === this.state.iconIndex) {
      const { width, height } = this.imageSprite
      const { x, y } = e.data.global
      if (0 <= x && x <= width && 0 <= y && y <= height) {
        const preparedPosition = {
          x,
          y
        }
        this.props.updateIconPosition(preparedPosition, personClassObject)
      }
    }
  }

  _returnActiveTarget() {
    const listActiveTarget = this.props.activeZone.features.map((feature) => feature.class_type)
    let listCondition = []
    if (this.props.activeZone.conditions !== null && _.isEmpty(this.props.activeZone.conditions) === false) {
      listCondition = this.props.activeZone.conditions[ANY_CONDITION_KEY].map((condition) => condition.class_type)
    }
    return [...new Set([...listActiveTarget, ...listCondition])]
  }

  _renderIcon(targetName, iconPropotion, iconSrc, index) {
    let personClassObject = this.props.activeZone.object_size.find((detectionObj) => detectionObj.class_type === targetName)
    const propsMinHeightObj = personClassObject.min_value
    const calculatedHeight = (propsMinHeightObj / this.props.imgResolutionHeight) * this.props.containerHeight
    const calculatedWidth = iconPropotion * calculatedHeight
    const iconTexture = new PIXI.Texture.fromImage(iconSrc, true, PIXI.SCALE_MODES.LINEAR, 5 * (propsMinHeightObj / this.props.imgResolutionHeight))
    let objectIcon = new PIXI.Sprite(iconTexture)

    objectIcon.buttonMode = true
    objectIcon.interactive = true

    objectIcon.width = calculatedWidth
    objectIcon.height = calculatedHeight

    objectIcon.anchor.set(0.5)
    objectIcon.position.x = personClassObject.position.x
    objectIcon.position.y = personClassObject.position.y

    objectIcon.on('pointerdown', (e) => this.handleClickIcon(e, index)).on('pointermove', (e) => this.onIconDraggedMove(e, personClassObject, index))
    this.iconContainerShape.on('pointerup', () => this.onIconDraggedEnd())
    this.iconContainerShape.on('pointerupoutside', () => this.onIconDraggedEnd())

    this.iconContainerShape.addChild(objectIcon)
  }

  _renderObjectDetectionIcon() {
    const activeClassList = this._returnActiveTarget()
    activeClassList.forEach((activeClass, index) => {
      switch (activeClass) {
        case PERSON_TARGET:
          this._renderIcon(activeClass, 0.411, HumanIcon, index)
          break
        case TRUCK_TARGET:
          this._renderIcon(activeClass, 1.412, TruckIcon, index)
          break
      }
    })
  }

  generatePolygon(allZoneData) {
    allZoneData.forEach((zoneData, zoneDataIndex) => {
      const polygonGraphic = new PIXI.Graphics()
      const polygonColorOpacity = zoneData.zone_id === this.props.activeZone.zone_id ? 0.5 : 0.2
      const polygonColorLineOpacity = zoneData.zone_id === this.props.activeZone.zone_id ? 1 : 0.5
      const isActiveZone = zoneData.zone_id === this.props.activeZone.zone_id
      let color = ''
      if (isActiveZone && this.props.activeZone.grid_color) {
        color = this.props.activeZone.grid_color
      } else if (zoneData.grid_color) {
        color = zoneData.grid_color
      } else {
        color = LIST_ZONE_COLORS[zoneDataIndex]
      }
      const lineSize = 2
      polygonGraphic.beginFill(color, polygonColorOpacity)
      polygonGraphic.lineStyle(lineSize, color, polygonColorLineOpacity)
      polygonGraphic.interactive = true
      const polygonGlobalCoordinates = zoneData.coordinates.map((coordinatePoint) => {
        // screenshotWidth, screenshotHeight are width and height of image
        // imageSprite.width, imageSprite.height are width and height of frame

        const tempX = coordinatePoint.x * (this.imageSprite.width / this.props.imgResolutionWidth)
        const tempY = coordinatePoint.y * (this.imageSprite.height / this.props.imgResolutionHeight)
        return new PIXI.Point(tempX, tempY)
      })
      polygonGraphic.drawPolygon(polygonGlobalCoordinates)
      polygonGraphic.endFill()
      this.containerShape.addChild(polygonGraphic)
    })
  }

  generateDot(allZoneData) {
    allZoneData.forEach((zoneData, zoneDataIndex) => {
      if (zoneData.zone_id === this.props.activeZone.zone_id) {
        const preparedListDotCoordinates = this.props.isDrawing
          ? zoneData.coordinates.slice(0, zoneData.coordinates.length - 1)
          : zoneData.coordinates
        preparedListDotCoordinates.forEach((positionXY, coordinateIndex) => {
          const circleGraphic = new PIXI.Graphics()
          const opacity = 1
          const isActiveZone = zoneData.zone_id === this.props.activeZone.zone_id

          let color = ''
          if (isActiveZone && this.props.activeZone.grid_color) {
            color = this.props.activeZone.grid_color
          } else if (zoneData.grid_color) {
            color = zoneData.grid_color
          } else {
            color = LIST_ZONE_COLORS[zoneDataIndex]
          }

          const lineSize = 2
          circleGraphic.beginFill(color, opacity)
          circleGraphic.lineStyle(lineSize, color, opacity)
          circleGraphic.interactive = true
          circleGraphic.buttonMode = true

          const tempX = positionXY.x * (this.imageSprite.width / this.props.imgResolutionWidth)
          const tempY = positionXY.y * (this.imageSprite.height / this.props.imgResolutionHeight)

          const globalSpot = new PIXI.Point(tempX, tempY)
          const isFirstSpot = coordinateIndex === 0
          if (isFirstSpot) {
            circleGraphic.lineStyle(1)
            circleGraphic.beginFill(0xffff0b, 0.5)
            circleGraphic.on('pointerdown', (e) => this.handleClickFistDot(e, zoneData, coordinateIndex))
          } else {
            circleGraphic
              .on('pointerdown', (e) => this.handleClickDot(e, zoneData, coordinateIndex))
              .on('pointerupoutside', (e) => this.onDragEnd(e))
              .on('pointerup', (e) => this.onDragEnd(e))
              .on('pointermove', (e) => this.onDragMove(e, zoneData, coordinateIndex))
          }
          const dotSize = 4
          circleGraphic.drawCircle(globalSpot.x, globalSpot.y, dotSize)
          circleGraphic.endFill()

          this.containerShape.addChild(circleGraphic)
        })
      }
    })
  }

  handleClickFistDot(e, zoneData, coordinateIndex) {
    if (e.data.originalEvent.which === LEFT_CLICK) {
      this.handleStopDrawing()
    }
    if (e.data.originalEvent.which === RIGHT_CLICK) {
      contextMenu.show({
        id: CONTEXT_MENU,
        event: e.data.originalEvent,
        props: {
          zoneData,
          coordinateIndex
        }
      })
    }
  }

  handleStopDrawing() {
    this.props.onFinishDrawing()
  }

  handleClickDot(e, zoneData, coordinateIndex) {
    if (e.data.originalEvent.which === LEFT_CLICK) {
      this.onDragStart(zoneData, coordinateIndex)
    }
    if (e.data.originalEvent.which === RIGHT_CLICK) {
      contextMenu.show({
        id: CONTEXT_MENU,
        event: e.data.originalEvent,
        props: {
          zoneData,
          coordinateIndex
        }
      })
    }
  }

  onDragStart(zoneData, coordinateIndex) {
    this.setState({
      isDragging: true,
      isIconDragging: false,
      draggingZonePolygon: zoneData,
      draggingIndexCircle: coordinateIndex
    })
  }

  onDragEnd(e) {
    this.setState({
      isDragging: false,
      draggingIndexCircle: undefined,
      draggingZonePolygon: undefined
    })
  }

  onDragMove(e, zoneData, coordinateIndex) {
    if (this.state.isDragging && zoneData.zone_id === this.state.draggingZonePolygon.zone_id && coordinateIndex === this.state.draggingIndexCircle) {
      const localPoint = this.imageSprite.toLocal(e.data.global)
      const { width, height } = this.imageSprite
      const { x, y } = e.data.global
      if (0 <= x && x <= width && 0 <= y && y <= height) {
        const preparedPoint = {
          x: localPoint.x * (this.props.imgResolutionWidth / this.screenshotWidth),
          y: localPoint.y * (this.props.imgResolutionHeight / this.screenshotHeight)
        }
        this.props.onCoordinateChanged(zoneData, coordinateIndex % zoneData.points.length, preparedPoint)
      }
    }
  }

  getDrawZoneInstruction() {
    let output = null
    if (this.state.showDrawZoneInstruction) {
      output = (
        <div className="draw-zone-instruction-container" onClick={() => this.setShowDrawZoneInstruction(false)}>
          <div className="draw-zone-instruction-image-container">
            <img src={PencilImage} alt="" />
          </div>
          <div className="draw-zone-instruction-message-container">Start Drawing Zone</div>
        </div>
      )
    }
    return output
  }

  handleAddSpotClicked(data) {
    const { zoneData, coordinateIndex } = data
    const centerCoordinate = this.imageSprite.toLocal({
      x: this.applicationStage.screen.width / 2,
      y: this.applicationStage.screen.height / 2
    })
    this.props.onAddSpot(zoneData, coordinateIndex, centerCoordinate)
  }

  handleDeleteSpotClicked(data) {
    const { zoneData, coordinateIndex } = data
    const centerCoordinate = this.imageSprite.toLocal({
      x: this.applicationStage.screen.width / 2,
      y: this.applicationStage.screen.height / 2
    })
    this.props.onDeleteSpot(zoneData, coordinateIndex, centerCoordinate)
  }

  render() {
    return (
      <DrawZoneStyled>
        <div className="screenshot-wrapper">
          <MenuProvider id="mock-menu-provider">
            <div className="canvas-na" id={`canvas-${this.props.cameraId}`} ref={(cv) => (this.canvas = cv)} />
          </MenuProvider>
          {this.getDrawZoneInstruction()}
        </div>
        <Menu id={CONTEXT_MENU} animation={animation.fade}>
          <Item onClick={({ event, props }) => this.handleAddSpotClicked(props)}>Add spot</Item>
          <Item onClick={({ event, props }) => this.handleDeleteSpotClicked(props)}>Delete spot</Item>
        </Menu>
      </DrawZoneStyled>
    )
  }
}

DrawZoneComponent.propTypes = {
  cameraId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  coordinates: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
      })
    )
  ),
  imageSrc: PropTypes.string,
  imgResolutionWidth: PropTypes.number,
  imgResolutionHeight: PropTypes.number,
  shouldDisableEditor: PropTypes.bool
}

export default withScreenshotBase64(DrawZoneComponent)
